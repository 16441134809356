
export const isDev = false;

export const cluesResponseSize = 18;
export const videoPageSize = 18;
export const albumPageSize = 18;
export const commentPageSize = 18;
export const subscriberPageSize = 18;
export const userPageSize = 30;
export const reportPageSize = 30;
export const recVideoPageSize = 20;

/**
 * 贴士类型
 * @type {{ANCHOR: number, SCREENSHOT: number, ADD: number, IMAGE: number, VIDEO: number, CANVAS: number, LINK: number}}
 */
export const TIPS_TYPE={
    IMAGE: 0,   //外部图片
    VIDEO: 1,   //外部视频
    LINK: 2,    //外部链接
    SCREENSHOT: 3,  //视频截图
    CANVAS: 4,  //CANVAS画画
    ANCHOR: 5,   //空降点
    PIE: 6, //饼状图
    LINEAR: 7,   //线形图
    PILLAR: 8,  //柱状图
    ADD: 9  //添加
};

/**
 * 贴士类型
 * @type {*[]}
 */
export const TIPS_TYPE_STR=['图片', '视频', '链接', '截图', '手绘', '空降点', '饼状图', '线形图', '柱状图'];

export const colorType = {
    primary: "#1c8efe",
    default: "#cccccc"
};

/**
 * 用户状态
 * @type {{TEENAGER: number, BLOCKED_TEENAGER_ADMIN: number, TEENAGER_PRIVATE: number, BLOCKED_NORMAL_ADMIN: number, NORMAL_PRIVATE: number, NORMAL: number}}
 */
export const USER_STATUS = {
    TEENAGER: 0,    //青少年正常模式
    NORMAL: 1,  //成人正常模式
    BLOCKED_TEENAGER_ADMIN: 2,  //青少年被管理员封号
    BLOCKED_NORMAL_ADMIN: 3,    //成人被管理员封号
    TEENAGER_PRIVATE: 4,    //青少年自己私人帐号
    NORMAL_PRIVATE: 5,   //成人自己私人帐号
    BLOCKED_TEENAGER_PRIVATE_ADMIN: 6,
    BLOCKED_NORMAL_PRIVATE_ADMIN: 7
};

export const USER_STATUS_COLOR = ["success", "success", "error", "error", "success", "success", "error", "error",];

/**
 * 举报的处理进度
 * @type {{COMPLETE: number, REJECT: number, PROCESSING: number, CANCEL: number}}
 */
export const REPORT_PROGRESS = {
    PROCESSING: 0,      //进行中
    COMPLETE: 1,        //处理通过
    CANCEL: 2,          //取消
    REJECT: 3           //处理不通过
};

export const REPORT_PROGRESS_COLOR = ["processing", "success", "warning", "error"];

/**
 * 举报类型
 */
export const REPORT_TYPE={
    PORN: 0,  //色情
    POLITICAL: 1,   //时政不实信息
    ILLEGAL_CRIME: 2,   //违法犯罪
    SPAM: 3,    //垃圾广告
    RUMOR: 4,   //造谣传谣
    FRAUD: 5,   //涉嫌欺诈
    ABUSE: 6,   //侮辱谩骂
    NON_ORIGINAL: 7,    //非原创内容
    DANGER: 8,  //危险行为
    INFRINGEMENT: 9,    //侵犯名誉、隐私、肖像、著作权
    NOT_SUITABLE_FOR_MINORS: 10,    //不适合未成年观看
    UNCOMFORTABLE: 11,  //引入不适
    SELF_HARM: 12,  //自残
    ILLEGAL_INDUCE: 13, //诱导点赞、分享、关注
    OTHER: 14,  //其他
};

/**
 * 视频状态
 * @type {{BLOCKED_BY_ADMIN: number, PROTECTED_FRIEND: number, IN_REVIEW: number, DRAFT: number, PUBLIC: number, PROTECTED_TEAM: number, UN_APPROVED: number, PRIVATE: number, PROTECTED_FANS: number}}
 */
export const VIDEO_STATUS={
    DRAFT: 0,   //草稿，未发布
    UN_APPROVED: 1, //审核未通过
    BLOCKED_PRIVATE: 2,    //封禁-解封私人可见
    BLOCKED_FRIEND: 3,      //封禁-解封朋友可见
    BLOCKED_FANS: 4,        //封禁-解封粉丝可见
    BLOCKED_PUBLIC: 5,      //封禁-解封公开可见
    BLOCKED_TEAM: 6,        //封禁-解封团队可见
    IN_REVIEW_PRIVATE: 7,   //审核中，通过后为自己可见
    IN_REVIEW_FRIEND: 8,    //审核中，通过后为朋友可见
    IN_REVIEW_FANS: 9,  //审核中，通过后为粉丝可见
    IN_REVIEW_PUBLIC: 10,    //审核中，通过后为公开可见
    IN_REVIEW_TEAM: 11,  //审核中，通过后为团队可见
    PRIVATE: 12, //自己可见
    PROTECTED_FRIEND: 13,    //互关朋友可见
    PROTECTED_FANS: 14,  //粉丝可见
    PUBLIC: 15,  //公开
    PROTECTED_TEAM: 16,  //团队可见

};

/**
 * 推荐视频权重
 * @type {{LR_VIDEO: number, OP_VIDEO: number, HL_VIDEO: number, HOT_VIDEO: number}}
 */
export const REC_VIDEO_WEIGHT = {
    HOT_VIDEO: 0,   //热门视频
    LR_VIDEO: 1,    //最新发布视频
    HL_VIDEO: 2,    //头条视频
    OP_VIDEO: 3,     //运营推荐视频
    OPEN_VIDEO: 4   //开头视频
};

export const OPEN_VIDEO_STATUS = {
    BLOCK: -1,  //下架
    DEFAULT: 0, //默认
    INTRO: 1,   //介绍
}

/**
 * 推荐专辑权重
 * @type {{}}
 */
export const REC_ALBUM_WEIGHT = {
    HOT_ALBUM: 0,   //热门专辑
    LR_ALBUM: 1,    //最新发布专辑
    HL_ALBUM: 2,    //头条专辑
    OP_ALBUM: 3,    //运营推荐专辑
    BANNER_ALBUM: 4     //首页横幅专辑
};

/**
 * 专辑类型状态
 * @type {{INVISIBLE: number, VISIBLE: number}}
 */
export const ALBUM_TYPE_STATUS = {
    VISIBLE: 0,     //专辑类型可见
    INVISIBLE: 1,    //专辑类型不可见,
    RECOMMEND: 2    //上推荐页的专辑类型
};

/**
 * 被举报对象类型
 * @type {{VIDEO: number, USER: number}}
 */
export const REPORT_TARGET_TYPE={
    VIDEO: 0,
    USER: 1,
};


export const endPoint = isDev ? "oss-cn-shenzhen.aliyuncs.com" : "oss-us-west-1.aliyuncs.com";
export const bucket = isDev ? "vrclose" : "vrclose-us";

export const bulletinDir = "bulletin";
export const bulletinFile = "bulletin.json";
