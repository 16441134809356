import React from 'react';
import {Button, Row, Col, Typography, Tag, Menu, Image} from 'antd';
import './NavBar.css';
import Animated from 'animated/lib/targets/react-dom';
import {
    MenuOutlined
} from '@ant-design/icons'

import {observer, inject} from "mobx-react";
import {action, observable} from "mobx";
import * as Cookie from "../../util/Cookie";
import * as LoginUtil from "../../util/LoginUtil";
import Global from "../../Global";
import LogoutOutlined from "@ant-design/icons/lib/icons/LogoutOutlined";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

@inject("windowStore", "navBarStore", "userStore")
@observer
class NavBar extends React.Component {

    @observable
    col;

    constructor(props) {
        super(props);

        this.windowStore = this.props.windowStore;
        this.navBarStore = this.props.navBarStore;
        this.userStore = this.props.userStore;

        this.state = {
            currentTab: 'Login',
            direction: 'horizontal',
            openMenu: false,
            tabs: [
                {name: "主页", icon: "home"},
            ],
            menuScaleY: new Animated.Value(0)
        };


        let colXS = window.innerWidth < 768;
        let colS = !colXS && window.innerWidth < 992;
        let colM = !colS && window.innerWidth < 1600;
        let colL = !colM && window.innerWidth < 2000;

        this.col = colXS ? "XS" : colS ? "S" : colM ? "M" : colL ? "L" : "XL";

        this.screenChange = this.screenChange.bind(this);
    }


    componentWillMount() {
        const url = this.props.history.location.pathname;

        if(url.indexOf("/login") > -1 || url.indexOf("/accountRegister") > -1){
            Cookie.docCookies.removeItem("token");
        }else if(!Global.isLogin){
            const token = Cookie.docCookies.getItem("token");
            if(token !== null) {
                LoginUtil.autoLogin(Cookie.docCookies.getItem("token")).then(user => {
                    if (user != null && user.id !== null) {
                        let date = new Date();
                        let usefulLife = 180;
                        date.setTime(date.getTime() + usefulLife * 24 * 3600 * 1000);
                        Global.user = user;
                        Global.isLogin = true;

                        this.userStore.setUser(user);
                        this.navBarStore.addTab({name: "控制台", icon: "control"});
                        this.navBarStore.changeTab({name: "退出", icon: "logout"});
                        setTimeout(() => {
                            this.props.history.replace(url);
                        }, 200);
                    }
                });
            }
        }


        const col = this.resize();
        this.screenChange();
        const cols = ["XS", "S", "M", "L", "XL"];
        this.setState({
            direction: cols.indexOf(col) === 0 ? "vertical" : "horizontal"
        });

        setTimeout(() => {
            let elHeight = document.getElementById('navBar').clientHeight;
            this.navBarStore.setNavBarHeight(elHeight);
        }, 100);
    }

    render() {
        const user = this.userStore.user;
        const url = this.props.history.location.pathname;
        const {currentTab, direction, openMenu, menuScaleY} = this.state;
        const {tabs} = this.navBarStore;
        const {height, width} = this.windowStore;
        const isXs = ["XS", "S", "M", "L", "XL"].indexOf(this.col) === 0;
        const showMenu = direction === 'horizontal' || openMenu;
        const isFullScreen = height / width > 1.8;
        const paddingTop = isFullScreen ? 10 : 12;
        const isMobile = height / width > 1;

        let scaleY = menuScaleY.interpolate({
            inputRange: [0, 1],
            outputRange: [0, isMobile ? (tabs.length + 1) * 47 : tabs.length * 47]
        });
        let paddingBottom = menuScaleY.interpolate({
            inputRange: [0, 0.2, 1],
            outputRange: [paddingTop, 0, 0]
        });

        return (
            <Animated.div
                id={"navBar"}
                className={"body"}
                style={{
                    display: 'flex',
                    flexDirection: isXs ? 'column' : 'row',
                    alignItems: isXs ? 'flex-start' : 'center',
                    paddingTop: paddingTop,
                    paddingBottom: isXs ? paddingBottom : paddingTop,
                    borderBottom: '1px solid #ccc'
                }}
            >
                <div className={"left-menu"} onClick={() => this.openMenu(!openMenu)}>
                    <MenuOutlined />
                </div>
                <Animated.div className={"menu-tabs"} style={{
                    display: 'flex',
                    marginLeft: isXs ? 0 : 10,
                    backgroundColor: '#fff',
                    width: isXs ? "100%" : "100%",
                    boxShadow: isXs ? "1px 1px 5px #e1e1e1" : null,
                    height: isXs ? scaleY : 'auto',
                    overflow: 'hidden',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                    <Menu
                        onClick={this.handleClick}
                        selectedKeys={[currentTab]}
                        mode={direction}
                        theme={"light"}
                        style={{
                            width: isXs ? "100%" : "auto",
                        }}
                    >
                        {
                            tabs.map((tab, index) => {
                                return (
                                    <Menu.Item
                                        key={index}
                                        className={"menu-item"}
                                        style={{width: isXs ? "100%" : "auto"}}
                                        onClick={() => this._goTo(tab.name)}
                                    >
                                        {tab.name}
                                    </Menu.Item>
                                )
                            })
                        }
                        {
                            isMobile ?
                                <Menu.Item
                                    className={"menu-item"}
                                    style={{width: isXs ? "100%" : "auto"}}
                                    onClick={() => this._goTo(user.id == null ? null : '退出')}
                                >
                                    {/*<Typography.Text*/}
                                    {/*    style={{color: '#000', left: 2}}>*/}
                                    {/*    {user.id == null ? '' : '退出'}*/}
                                    {/*</Typography.Text>*/}
                                    {
                                        user.id == null ? null :
                                            <LogoutOutlined style={{width: 10, height: 10}} />
                                    }
                                </Menu.Item>
                                :
                                null
                        }
                    </Menu>
                    {
                        isMobile ? null :
                            <div style={{paddingRight: '50pt', display: 'flex',  flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                                {
                                    user.nickname == null ? null :
                                        <Typography.Text style={{fontWeight: 'bold', fontSize: '15pt', color: '#000', marginRight: '10pt'}}>{user.nickname}</Typography.Text>
                                }
                                {
                                    user.profile == null ? null :
                                        <Image
                                            src={user.profile}
                                            style={{width: '30pt', height: '30pt', borderRadius: '1000pt', marginRight: '10pt'}}
                                        />
                                }
                                {/*<Typography.Text*/}
                                {/*    onClick={() => {this._goTo(user.id == null ? null : '退出')}}*/}
                                {/*    style={{color: '#000', left: 2}}>*/}
                                {/*    {user.id == null ? '' : '退出'}*/}
                                {/*</Typography.Text>*/}
                                {
                                    user.id == null ? null :
                                        <div style={{width: '30pt', height: '30pt', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                            <LogoutOutlined style={{fontSize: 25, color: '#e6423c'}}/>
                                        </div>
                                }
                            </div>
                    }

                </Animated.div>
            </Animated.div>
        )
    }


    /**
     * 监听窗口改变
     */
    screenChange = () => {
        window.addEventListener('resize', this.resize.bind(this));
    };

    @action
    resize = () => {
        const bodyWidth = window.innerWidth;
        const bodyHeight = document.body.clientHeight;

        let colXS = bodyWidth < 768;
        let colS = !colXS && bodyWidth < 992;
        let colM = !colS && bodyWidth < 1600;
        let colL = !colM && bodyWidth < 2000;

        let col = colXS ? "XS" : colS ? "S" : colM ? "M" : colL ? "L" : "XL";

        this.windowStore.setWidth(bodyWidth);
        this.windowStore.setHeight(bodyHeight);
        this.windowStore.setCol(col);
        this.col = col;
        return col;
    };

    openMenu = (isOpen) => {
        const openDuration = isOpen ? 0 : 300;
        const that = this;
        setTimeout(() => {
            that.setState({
                openMenu: isOpen
            });
        }, openDuration);



        Animated.timing(
            this.state.menuScaleY,
            {
                toValue: isOpen ? 1 : 0,
                duration: 250
            }).start();

    };

    /**
     * 跳转页面
     * @param name
     * @private
     */
    _goTo = (name) => {
        switch (name) {
            case "Home":
            case "主页":
                this.props.history.replace("/");
                break;
            case "Privacy Policy":
                this.props.history.replace("/privacy");
                break;
            case "User Protocol":
                this.props.history.replace("/protocol");
                break;
            case "退出":
                this._logout();
                this.props.history.replace("/login");
                break;
            case "登录":
                this.props.history.replace("/login");
                break;
            case "控制台":
                this.props.history.push("/console/index");
                break;
            default:
                this.props.history.replace("/");
                break;
        }
        this.openMenu(false);
    };

    /**
     * 退出登录
     * @private
     */
    _logout = () => {
        this.navBarStore.deleteTab("控制台");
        this.navBarStore.changeTab({name: "登录", icon: "login"});
        Cookie.docCookies.removeItem("token");
        Cookie.docCookies.removeItem("userType");

        Global.user = {
            id: null,
            username: null,
            type: null,
            tel: null,
            sex: null
        };
        this.userStore.logout();
    };

    handleClick = e => {
        this.setState({
            currentTab: e.key,
        });
    };
}

export default NavBar;
