import {inject, observer} from "mobx-react";
import React from "react";
import {Button, Checkbox, Image, List, Modal, Typography, Tag, Radio, message, Empty, Descriptions} from "antd";
import {Player} from "video-react";
import CheckCircleOutlined from "@ant-design/icons/lib/icons/CheckCircleOutlined";
import StopOutlined from "@ant-design/icons/lib/icons/StopOutlined";
import ReloadOutlined from "@ant-design/icons/lib/icons/ReloadOutlined";
import {
    REPORT_PROGRESS,
    REPORT_PROGRESS_COLOR, REPORT_TARGET_TYPE,
    REPORT_TYPE,
    reportPageSize, TIPS_TYPE_STR,
    videoPageSize
} from "../../constants/Constants";
import {PlayCircleFilled, PlayCircleOutlined} from "@ant-design/icons";
import {formatDuration} from "../../util/DateUtil";


@inject("windowStore", "userStore", "navBarStore")
@observer
class ReportListView extends React.Component {

    constructor(props) {
        super(props);
        this.windowStore = this.props.windowStore;
        this.userStore = this.props.userStore;
        this.navBarStore = this.props.navBarStore;
        this.state={
            reportList: [],
            columns: [
                {
                    flex: 0.5,
                    title: '选择',
                    dataIndex: 'checkbox',
                    key: 'checkbox',
                },
                {
                    flex: 0.5,
                    title: 'ID',
                    dataIndex: 'id',
                    key: 'id',
                },
                {
                    flex: 0.5,
                    title: '处理进度',
                    dataIndex: 'progress',
                    key: 'progress',
                },
                {
                    flex: 1,
                    title: '举报类型',
                    dataIndex: 'type',
                    key: 'type',
                },
                {
                    flex: 1,
                    title: '举报对象',
                    dataIndex: 'targetId',
                    key: 'targetId',
                },
                {
                    flex: 1,
                    title: '所属者ID',
                    dataIndex: 'targetUpper',
                    key: 'targetUpper'
                },
                {
                    flex: 2,
                    title: '举报内容',
                    dataIndex: 'content',
                    key: 'content',
                },
                {
                    flex: 1,
                    title: '举报时间',
                    dataIndex: 'createTime',
                    key: 'createTime',
                },
                {
                    flex: 1,
                    title: '附件（举证）',
                    dataIndex: 'attachmentUrl',
                    key: 'attachmentUrl',
                },
                {
                    flex: 1.5,
                    title: '举报者',
                    dataIndex: 'reporter',
                    key: 'reporter',
                }
            ],

            processing: false,
            loading: false,
            isLastPage: false,
            emptyList: false,
            checkIds: [],
            checkReports: [],
            progress: REPORT_PROGRESS.PROCESSING,

            locationPath: null,

            showImage: false,
            imageSrc: null,

            showVideo: false,
            showVideoUri: null,

            showTips: false,
            tips: null,

            showVideoObj: false,
            reportVideoId: null,
            reportVideo: null,
            loadingVideo: false,

            showUserObj: false,
            reportUserId: null,
            reportUser: null,
            loadingUser: false,

            isReportVideo: true
        }
    }

    componentDidMount() {
        const url = this.props.history.location.pathname;
        const isVideo = url.indexOf("video") > -1;
        this.setState({isReportVideo: isVideo}, () => {
            this._fetchReports('refresh', this.state.progress);
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const url = this.props.history.location.pathname;
        const isVideo = url.indexOf("video") > -1;
        if(isVideo !== this.state.isReportVideo){
            this.setState({isReportVideo: isVideo}, () => {
                this._fetchReports('refresh', this.state.progress);
            });
        }
    }

    render(){
        const {
            reportList, processing, isLastPage, loading, progress, columns, checkIds, showImage, imageSrc,
            showVideoObj, reportVideo, loadingVideo, showVideo, showVideoUri,
            showUserObj, reportUser, loadingUser,
            isReportVideo, showTips, tips
        } = this.state;
        const locationPath = window.location.pathname;
        const navBarHeight = this.navBarStore.height;
        const {width, height, col} = this.windowStore;
        const cols = ["XS", "S", "M", "L", "XL"];
        const colIndex = cols.indexOf(col);
        const smallThenL = colIndex < 4;
        const hasSelected = checkIds.length > 0;

        const loadMore = !loading ? (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    marginBottom: 12,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button disabled={isLastPage} onClick={() => {this._fetchReports("loadMore", progress)}}>
                    {isLastPage ? "已无更多" : "加载更多"}
                </Button>
            </div>
        ) : null;

        return (
            <div style={{width: "100%", display: 'flex', flexDirection: 'row'}}>
                <div style={{width: smallThenL ? 60 : 290, height: 100}}/>
                <div style={{width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                    <Modal
                        title="视频"
                        centered
                        visible={showVideo}
                        width={500}
                        cancelText={'关闭'}
                        onOk={() => this._hideVideo()}
                        onCancel={() => this._hideVideo()}
                    >
                        <Player
                            ref={(ref) => this.videoModal = ref}
                            src={showVideoUri}>

                        </Player>
                    </Modal>
                    <Modal
                        title="Tips"
                        centered
                        visible={showTips}
                        width={1000}
                        cancelText={'关闭'}
                        onOk={() => this._hideTips()}
                        onCancel={() => this._hideTips()}
                    >
                        {
                            showTips ? this._renderTips(tips) : null
                        }
                    </Modal>
                    <Modal
                        title="图片"
                        centered
                        visible={showImage}
                        width={548}
                        cancelText={'关闭'}
                        onOk={() => this._hideImage()}
                        onCancel={() => this._hideImage()}
                    >
                        {
                            showImage ? <Image src={imageSrc} style={{width: 500}}/> : null
                        }
                    </Modal>
                    <Modal
                        title={"举报对象"}
                        centered
                        visible={showVideoObj || showUserObj}
                        width={548}
                        cancelText={'关闭'}
                        onOk={() => this._hideReport()}
                        onCancel={() => this._hideReport()}
                    >
                        <div style={{width: 500, minHeight: 500, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            {
                                loadingVideo || loadingUser ?
                                    <Typography.Text>
                                        加载中...
                                    </Typography.Text>
                                    :
                                    <div style={{width: 500, minHeight: 500}}>
                                        {
                                            isReportVideo ?
                                                reportVideo == null ? <Empty/> :
                                                    <div style={{width: 500}}>
                                                        <Descriptions bordered>
                                                            <Descriptions.Item label="ID" span={3}>
                                                                {reportVideo.id}
                                                            </Descriptions.Item>
                                                            <Descriptions.Item label="标题" span={3}>
                                                                {reportVideo.title}
                                                            </Descriptions.Item>
                                                            <Descriptions.Item label="视频链接" span={3}>
                                                                <Button
                                                                    icon={<PlayCircleOutlined/>}
                                                                    onClick={() => {this._openVideo(reportVideo.uri)}}
                                                                >
                                                                    播放
                                                                </Button>
                                                            </Descriptions.Item>
                                                            <Descriptions.Item label="视频封面" span={3}>
                                                                <Image
                                                                    src={reportVideo.thumbnailUri}
                                                                    style={{
                                                                        width: 25,
                                                                        height: 40,
                                                                    }}
                                                                />
                                                            </Descriptions.Item>
                                                            <Descriptions.Item label="时长" span={3}>
                                                                {formatDuration(reportVideo.duration)}
                                                            </Descriptions.Item>
                                                            <Descriptions.Item label="贴士" span={3}>
                                                                {this._formatTips(reportVideo.tips)}
                                                            </Descriptions.Item>
                                                            <Descriptions.Item label="提案" span={3}>
                                                                {}
                                                            </Descriptions.Item>
                                                            <Descriptions.Item label="上传时间" span={3}>
                                                                {reportVideo.createTime}
                                                            </Descriptions.Item>
                                                            <Descriptions.Item label="上传者" span={3}>
                                                                {this._formatUpper(reportVideo.upper)}
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                    </div>
                                                :
                                                reportUser == null ? <Empty/> :
                                                    <div style={{width: 500}}>
                                                        <Descriptions bordered>
                                                            <Descriptions.Item label="ID" span={3}>
                                                                {reportUser.id}
                                                            </Descriptions.Item>
                                                            <Descriptions.Item label="昵称" span={3}>
                                                                {reportUser.nickname}
                                                            </Descriptions.Item>
                                                            <Descriptions.Item label="用户名" span={3}>
                                                                {reportUser.username}
                                                            </Descriptions.Item>
                                                            <Descriptions.Item label="头像" span={3}>
                                                                <Image
                                                                    src={reportUser.profile}
                                                                    style={{
                                                                        width: 25,
                                                                        height: 25,
                                                                    }}
                                                                />
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                    </div>
                                        }
                                    </div>
                            }
                        </div>
                    </Modal>
                    <div style={{width: "80%", marginTop: 16, marginBottom: 16, marginLeft: 16, display: 'flex', flexDirection: 'row'}}>
                        <div style={{display: 'flex', flexDirection: 'row', marginTop: 5}}>
                            <Typography.Text>处理进度：</Typography.Text>
                            <Radio.Group
                                onChange={(e) => {this._switchProgress(e.target.value)}}
                                value={progress}
                                style={{}}
                            >
                                <Radio value={0}>待处理</Radio>
                                <Radio value={1}>{isReportVideo ? '已下架' : '已封禁'}</Radio>
                                <Radio value={2}>已取消</Radio>
                                <Radio value={3}>已驳回</Radio>
                            </Radio.Group>
                        </div>
                        {
                            progress === REPORT_PROGRESS.PROCESSING ?
                                <Button
                                    type="default"
                                    onClick={() => {this._updateReport(true)}}
                                    disabled={!hasSelected}
                                    loading={processing}
                                    icon={<CheckCircleOutlined />}
                                    style={{marginLeft: 50}}
                                >
                                    {isReportVideo ? '下架视频' : '封禁帐号'}
                                </Button>
                                :
                                null
                        }
                        {
                            progress === REPORT_PROGRESS.PROCESSING ?
                                <Button
                                    type="default"
                                    danger
                                    onClick={() => this._updateReport(false)}
                                    style={{marginLeft: 20}}
                                    disabled={!hasSelected}
                                    loading={processing}
                                    icon={<StopOutlined />}
                                >
                                    驳回举报
                                </Button>
                                :
                                null
                        }
                        <Button
                            type="primary"
                            onClick={() => this._fetchReports('refresh', progress)}
                            style={{marginLeft: 20}}
                            loading={loading}
                            icon={<ReloadOutlined />}
                        >
                            刷新
                        </Button>
                    </div>
                    <div
                        style={{
                            width: "100%",
                            height: 55,
                            backgroundColor: '#dedede',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            borderBottom: '1px solid #f0f0f0'
                        }}
                    >
                        {
                            columns.map((columnItem, index) => {
                                return (
                                    <div style={{flex: columnItem.flex, height: 55, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30}}>
                                        <Typography.Paragraph strong={true}>{columnItem.title}</Typography.Paragraph>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <List
                        className="reportList"
                        loading={false}
                        itemLayout="horizontal"
                        style={{width: "100%", height: height - navBarHeight - 120, overflowY: 'scroll'}}
                        loadMore={loadMore}
                        dataSource={reportList}
                        renderItem={(report, index) => (
                            <div
                                key={index}
                                style={{
                                    width: "100%",
                                    height: 50,
                                    backgroundColor: '#fff',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-start',
                                    borderBottom: '1px solid #f0f0f0'
                                }}
                            >
                                {
                                    columns.map((columnItem, cIndex) => {
                                        const isCheckbox = columnItem.dataIndex === "checkbox";
                                        const isReporter = columnItem.dataIndex === "reporter";
                                        const isAttachment = columnItem.dataIndex === "attachmentUrl";
                                        const isType = columnItem.dataIndex === "type";
                                        const isProgress = columnItem.dataIndex === "progress";
                                        const isObject = columnItem.dataIndex === "targetId";
                                        const isTargetUpper = columnItem.dataIndex === "targetUpper";

                                        let info = report[columnItem.dataIndex];

                                        if(isCheckbox){
                                            info = (
                                                <Checkbox
                                                    checked={checkIds.indexOf(parseInt(report.id)) > -1}
                                                    disabled={loading}
                                                    onChange={(e) => this._onCheck(e.target.checked, report)}
                                                >

                                                </Checkbox>
                                            )
                                        }else if(isReporter){
                                            const nickname = report[columnItem.dataIndex].nickname;
                                            const profile = report[columnItem.dataIndex].profile;
                                            const crossOrigin = profile == null ? false : profile.indexOf("vrclose") > -1;

                                            info = (
                                                <div style={{display: 'flex', flexDirection: 'row', alignSelf: 'center', justifyContent: 'center', alignItems: 'flex-start'}}>
                                                    {
                                                        profile == null ? null :
                                                            <Image
                                                                crossOrigin={crossOrigin ? null : "anonymous"}
                                                                src={profile}
                                                                style={{
                                                                    width: 40,
                                                                    height: 40,
                                                                    borderRadius: 50,
                                                                    marginRight: 10
                                                                }}
                                                            />
                                                    }
                                                    <span
                                                        style={{
                                                            width: "100%",
                                                            overflowX: 'hidden',
                                                            fontWeight: 'bold',
                                                            fontSize: 20,
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignSelf: 'flex-start'
                                                        }}
                                                    >
                                                        {nickname}
                                                    </span>
                                                </div>
                                            )
                                        }else if(isAttachment){
                                            if(info != null){
                                                info = (
                                                    <Image
                                                        src={info}
                                                        style={{
                                                            width: 25,
                                                            height: 40,
                                                        }}
                                                    />
                                                )
                                            }else {
                                                info = "无";
                                            }
                                        }else if(isType){
                                            info = <Tag color={"default"}>{this._formatReportType(info)}</Tag>;
                                        }else if(isProgress){
                                            info = <Tag color={REPORT_PROGRESS_COLOR[info]}>{this._formatProgress(info, isReportVideo)}</Tag>;
                                        }else if(isObject){
                                            info = (
                                                <Button
                                                    onClick={() => this._showReport(report.targetId, report.targetType)}
                                                    type={"primary"}
                                                >
                                                    {isReportVideo ? '查看视频' : '查看用户'}
                                                </Button>
                                            )
                                        }else if(isTargetUpper){
                                            info = report.targetUpperId;
                                        }

                                        return (
                                            <div
                                                key={cIndex}
                                                style={{flex: columnItem.flex, height:  50, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30}}>
                                                <span>{info}</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )}
                    />

                </div>
            </div>
        )
    }

    /**
     * 渲染Tips表单
     * @param tips
     * @returns {*}
     * @private
     */
    _renderTips = (tips) => {
        return (
            <div style={{flex: 1, maxHeight: 500}}>
                <div style={{width: "100%", height: 30, display: 'flex', flexDirection: 'row', backgroundColor: 'rgba(250,250,250,1)'}}>
                    <Typography.Paragraph style={{flex: 1}} strong={true}>类型</Typography.Paragraph>
                    <Typography.Paragraph style={{flex: 6}} strong={true}>链接</Typography.Paragraph>
                    <Typography.Paragraph style={{flex: 2}} strong={true}>标题</Typography.Paragraph>
                    <Typography.Paragraph style={{flex: 1}} strong={true}>单位</Typography.Paragraph>
                </div>
                <List
                    className="tipList"
                    loading={false}
                    itemLayout="horizontal"
                    style={{width: "100%", maxHeight: 470, overflowY: 'auto', display: 'flex', justifyContent: 'flex-start', }}
                    dataSource={tips}
                    renderItem={(tip, tipIndex) => {
                        const tipType = TIPS_TYPE_STR[tip.type];
                        const tipSrc = tip.src;
                        const tipTitle = tip.title;
                        const tipUnit = tip.unit;

                        return (
                            <div
                                key={tipIndex}
                                style={{width: "100%", display: 'flex', flexDirection: 'row'}}
                            >
                                <div style={{width: "10%", height: 30}}>
                                    <Typography.Paragraph style={{width: "100%"}}>
                                        {tipType}
                                    </Typography.Paragraph>
                                </div>
                                <div style={{width: "60%", height: 30, }}>
                                    <Typography.Paragraph
                                        style={{width: "100%", overflow: 'hidden'}}
                                        ellipsis={true}
                                        underline={tipSrc !== null}
                                        onClick={() => {this._showSrc(tipSrc)}}
                                    >
                                        {tipSrc == null ? '无' : tipSrc}
                                    </Typography.Paragraph>
                                </div>
                                <div style={{width: "20%", height: 30}}>
                                    <Typography.Paragraph style={{flex: 1}} ellipsis={true}>
                                        {tipTitle == null ? '无' : tipTitle}
                                    </Typography.Paragraph>
                                </div>
                                <div style={{width: "10%", height: 30}}>
                                    <Typography.Paragraph style={{flex: 1}} ellipsis={true}>
                                        {tipUnit == null ? '无' : tipUnit}
                                    </Typography.Paragraph>
                                </div>
                            </div>
                        )
                    }}
                />
            </div>
        )
    };

    /**
     * 打开链接
     * @param src
     * @private
     */
    _showSrc = (src) => {
        if(src != null && src.indexOf("http") > -1){
            if(src.endsWith(".png") || src.endsWith(".jpg")){
                this._showImage(src);
            }else {
                window.open(src);
            }
        }
    };

    /**
     * 关闭图片
     * @private
     */
    _hideImage = () => {
        this.setState({
            showImage: false,
            imageSrc: null,
        })
    };

    /**
     * 预览图片
     * @param src
     * @private
     */
    _showImage = (src) => {
        this.setState({
            showImage: true,
            imageSrc: src,
        })
    };

    /**
     * 关闭视频
     * @private
     */
    _hideVideo = () => {
        this.videoModal.pause();
        this.setState({
            showVideo: false,
            showVideoUri: null
        })
    };

    /**
     * 打开视频
     * @param uri
     * @private
     */
    _openVideo = (uri) => {
        this.setState({
            showVideo: true,
            showVideoUri: uri,
        }, () => {
            this.videoModal.play();
        })
    };

    /**
     * 关闭Tips
     * @private
     */
    _hideTips = () => {
        this.setState({
            showTips: false,
            tips: null,
        })
    };

    /**
     * 打开Tips
     * @param tips
     * @private
     */
    _showTips = (tips) => {
        this.setState({
            showTips: true,
            tips: tips
        });
    };

    /**
     * 格式化Tips
     * @param tips
     * @returns {*}
     * @private
     */
    _formatTips = (tips) => {
        let info = null;
        const hasTips = tips != null && tips.length > 0;
        if(hasTips){
            info = (
                <Button
                    onClick={() => this._showTips(tips)}
                    type={"primary"}>
                    查看
                </Button>
            );
        }else {
            info = (<Button disabled={true} type={"default"}>没有</Button>);
        }

        return info;
    };

    /**
     * 格式化upper
     * @param upper
     * @private
     */
    _formatUpper = (upper) => {
        const nickname = upper.nickname;
        const profile = upper.profile;
        const crossOrigin = profile == null ? false : profile.indexOf("vrclose") > -1;

        return (
            <div style={{display: 'flex', flexDirection: 'row', alignSelf: 'center', justifyContent: 'center', alignItems: 'flex-start'}}>
                {
                    profile == null ? null :
                        <Image
                            crossOrigin={crossOrigin ? null : "anonymous"}
                            src={profile}
                            style={{
                                width: 40,
                                height: 40,
                                borderRadius: 50,
                                marginRight: 10
                            }}
                        />
                }
                <span
                    style={{
                        width: "100%",
                        overflowX: 'hidden',
                        fontWeight: 'bold',
                        fontSize: 20,
                        display: 'flex',
                        flexDirection: 'row',
                        alignSelf: 'flex-start'
                    }}
                >
                    {nickname}
                </span>
            </div>
        )
    };

    /**
     * 拉取指定视频
     * @param videoId
     * @private
     */
    _fetchVideo = (videoId) => {
        if(!this.state.loadingVideo) {
            this.setState({loadingVideo: true});
            const url = `/apiB/video/${videoId}`;
            const token = this.userStore.user.token;

            let isSuccess = false;
            fetch(url, {
                method: "GET",
                headers: {
                    'Authorization': token,
                    'Accept': 'application/json'
                },
            })
                .then((response) => {
                    isSuccess = response.ok;
                    return response.json();
                })
                .then((json) => {
                    if(isSuccess){
                        this.setState({reportVideo: json});
                    }
                    this.setState({loadingVideo: false});
                })
                .catch((err) => {
                    this.setState({loadingVideo: false});
                });
        }
    };

    /**
     * 拉取指定用户
     * TODO 未来版本加上查用户发布过的视频、专辑，以便审核员了解用户是否违规
     * @param userId
     * @private
     */
    _fetchUser = (userId) => {
        if(!this.state.loadingUser) {
            this.setState({loadingUser: true});
            const url = `/apiB/user/info/${userId}`;
            const token = this.userStore.user.token;

            let isSuccess = false;
            fetch(url, {
                method: "GET",
                headers: {
                    'Authorization': token,
                    'Accept': 'application/json'
                },
            })
                .then((response) => {
                    isSuccess = response.ok;
                    return response.json();
                })
                .then((json) => {
                    if(isSuccess){
                        this.setState({reportUser: json});
                    }
                    this.setState({loadingUser: false});
                })
                .catch((err) => {
                    this.setState({loadingUser: false});
                });
        }
    };

    /**
     * 展示被举报对象模态框
     * @param targetType
     * @param targetId
     * @private
     */
    _showReport = (targetId, targetType) => {
        if(targetType === REPORT_TARGET_TYPE.VIDEO){
            this.setState({
                showVideoObj: true,
                reportVideoId: targetId,
                reportVideo: null
            }, () => {
                this._fetchVideo(targetId);
            });
        }else {
            this.setState({
                showUserObj: true,
                reportUserId: targetId,
                reportUser: null
            }, () => {
                this._fetchUser(targetId);
            });
        }
    };

    /**
     * 关闭被举报对象模态框
     * @private
     */
    _hideReport = () => {
        this.setState({
            showVideoObj: false,
            reportVideoId: null,
            reportVideo: null,
            showUserObj: false,
            reportUserId: null,
            reportUser: null
        });
    };

    /**
     * 切换处理进度
     * @param progress
     * @private
     */
    _switchProgress = (progress) => {
        if(this.state.progress !== progress){
            this.setState({
                progress: progress,
                checkIds: [],
                checkReports: [],
            }, () => {
                this._fetchReports('refresh', progress);
            });
        }
    };

    /**
     * 选择举报Item
     * @param checked
     * @param report
     * @private
     */
    _onCheck = (checked, report) => {
        let {checkIds, checkReports} = this.state;
        if(checked){
            if(checkIds.indexOf(parseInt(report.id)) === -1){
                const notEmpty = checkIds.length > 0;
                let newArr = notEmpty ? [].concat(checkIds) : [parseInt(report.id)];
                let newReportArr = notEmpty ? [].concat(checkReports) : [report];

                if(notEmpty){
                    newArr.push(parseInt(report.id));
                    newReportArr.push(report);
                }
                this.setState({
                    checkIds: newArr,
                    checkReports: newReportArr
                });
            }
        }else {
            const arrIndex = checkIds.indexOf(parseInt(report.id));
            if(arrIndex > -1){
                let newArr = [].concat(checkIds);
                let newReportArr = [].concat(checkReports);

                newArr.splice(arrIndex, 1);
                newReportArr.splice(arrIndex, 1);

                this.setState({
                    checkIds: newArr,
                    checkReports: newReportArr
                });
            }
        }
    };

    /**
     * 格式化举报类型
     * @param type
     * @private
     */
    _formatReportType = (type) => {
        let typeStr = null;
        switch (type) {
            case REPORT_TYPE.PORN: typeStr = "色情";break;
            case REPORT_TYPE.POLITICAL: typeStr = "虚假消息";break;
            case REPORT_TYPE.ILLEGAL_CRIME: typeStr = "违法犯罪";break;
            case REPORT_TYPE.SPAM: typeStr = "垃圾广告";break;
            case REPORT_TYPE.RUMOR: typeStr = "造谣传谣";break;
            case REPORT_TYPE.FRAUD: typeStr = "涉嫌欺诈";break;
            case REPORT_TYPE.ABUSE: typeStr = "侮辱谩骂";break;
            case REPORT_TYPE.NON_ORIGINAL: typeStr = "非原创内容";break;
            case REPORT_TYPE.DANGER: typeStr = "危险行为";break;
            case REPORT_TYPE.INFRINGEMENT: typeStr = "侵权";break;
            case REPORT_TYPE.NOT_SUITABLE_FOR_MINORS: typeStr = "不适合未成年观看";break;
            case REPORT_TYPE.UNCOMFORTABLE: typeStr = "引人不适";break;
            case REPORT_TYPE.SELF_HARM: typeStr = "自残";break;
            case REPORT_TYPE.ILLEGAL_INDUCE: typeStr = "诱导点赞、分享、关注";break;
            case REPORT_TYPE.OTHER: typeStr = "其他";break;
            default: break;
        }

        return typeStr;
    };

    /**
     * 格式化处理进度
     * @param progress
     * @param isVideo
     * @private
     */
    _formatProgress = (progress, isVideo) => {
        let progressStr = null;
        switch (progress) {
            case REPORT_PROGRESS.PROCESSING: progressStr = "待处理";break;
            case REPORT_PROGRESS.COMPLETE: progressStr = isVideo ? "已下架" : "已封禁";break;
            case REPORT_PROGRESS.CANCEL: progressStr = "已取消";break;
            case REPORT_PROGRESS.REJECT: progressStr = "已驳回";break;
            default: break;
        }

        return progressStr;
    };

    /**
     * 批量处理举报内容
     * @private
     */
    _updateReport = (pass) => {
        let {checkIds, checkReports} = this.state;
        const token = this.userStore.user.token;

        if(checkIds.length > 0){
            this.setState({processing: true});

            const url = "/apiB/report/pass";
            const method = pass ? 'POST' : 'DELETE';
            const formData = new FormData();

            if(checkIds.length > 1) {
                checkReports.map((report) => {
                    const reviewReport = {
                        id: parseInt(report.id),
                        targetId: parseInt(report.targetId),
                        targetType: parseInt(report.targetType),
                        targetStatus: parseInt(report.targetStatus),
                        targetUpperId: parseInt(report.targetUpperId),
                        reporterId: parseInt(report.reporter.id)
                    };
                    formData.append("reports", JSON.stringify(reviewReport));
                });
            }else {
                const report = checkReports[0];
                const reviewReport = {
                    id: parseInt(report.id),
                    targetId: parseInt(report.targetId),
                    targetType: parseInt(report.targetType),
                    targetStatus: parseInt(report.targetStatus),
                    targetUpperId: parseInt(report.targetUpperId),
                    reporterId: parseInt(report.reporter.id)
                };
                formData.append("reports", null);
                formData.append("reports", JSON.stringify(reviewReport));
            }

            let isSuccess = false;

            fetch(url, {
                method: method,
                headers: {
                    'Authorization': token,
                },
                body: formData,
            })
                .then((response) => {
                    isSuccess = response.ok;
                    return response.json();
                })
                .then((json) => {
                    if(isSuccess){
                        let {reportList} = this.state;
                        let newArr = [];
                        reportList.map((report, index) => {
                            if(checkIds.indexOf(parseInt(report.id)) === -1){
                                newArr.push(report);
                            }
                        });

                        this.setState({
                            checkIds: [],
                            checkReports: [],
                            reportList: newArr
                        }, () => {
                            message.success(`处理成功`);
                        })
                    }else {
                        message.error(`处理失败，请重试`);
                    }
                    this.setState({processing: false});
                })
                .catch((err) => {
                    this.setState({processing: false});
                });
        }

    };

    /**
     * 拉取指定类型【举报】
     * @param fetchType
     * @param progress
     * @private
     */
    _fetchReports = (fetchType, progress) => {
        const {loading, reportList, isReportVideo} = this.state;

        if(!loading) {
            this.setState({loading: true});

            const token = this.userStore.user.token;
            const listSize = reportList.length;
            const isRefresh = fetchType === "refresh";
            const targetType = isReportVideo ? REPORT_TARGET_TYPE.VIDEO : REPORT_TARGET_TYPE.USER;
            const lastItemId = !isRefresh && listSize > 0 ? reportList[listSize - 1].id.toString() : "-1";
            const url = `/apiB/report/list/${targetType}/${lastItemId}/${progress}`;

            let isSuccess = false;
            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
                .then((response) => {
                    isSuccess = response.ok;
                    return response.json();
                })
                .then((json) => {
                    if(isSuccess){
                        let isLastPage = false;
                        let isEmpty = false;
                        let newList = isRefresh ? [] : reportList.length > 0 ? [].concat(reportList) : [];
                        if(json.length === 0){
                            isLastPage = true;
                            if(reportList.length === 0){
                                isEmpty = true;
                            }
                        }else {
                            json.map((report, index) => {
                                report['key'] = index;
                                newList.push(report);
                            });
                            if (json.length < reportPageSize) {
                                isLastPage = true;
                            }
                        }

                        this.setState({
                            loading: false,
                            isLastPage: isLastPage,
                            emptyList: isEmpty,
                            reportList: newList,
                        });
                    }
                    this.setState({loading: false});
                })
                .catch((err) => {
                    this.setState({loading: false});
                });


        }
    };

}

export default ReportListView;
