import {windowStore} from './windowStore';
import {navBarStore} from "./navBarStore";
import {userStore} from "./userStore";

const store = {
   windowStore,
    navBarStore,
    userStore,
};

export default store;
