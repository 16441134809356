import React from 'react';
import {inject, observer} from "mobx-react";
import {Button, Checkbox, Image, List, Modal, Typography, message} from "antd";
import {colorType, OPEN_VIDEO_STATUS, REC_VIDEO_WEIGHT, recVideoPageSize} from "../../constants/Constants";
import {Player} from "video-react";
import RightOutlined from "@ant-design/icons/lib/icons/RightOutlined";
import LeftOutlined from "@ant-design/icons/lib/icons/LeftOutlined";
import PlayCircleOutlined from "@ant-design/icons/lib/icons/PlayCircleOutlined";
import HLSSource from "../HLSSource/HLSSource";

@inject("windowStore", "userStore", "navBarStore")
@observer
class RecommendOpenVideo extends React.Component {

    constructor(props) {
        super(props);
        this.windowStore = this.props.windowStore;
        this.userStore = this.props.userStore;
        this.navBarStore = this.props.navBarStore;
        this.state={
            unRecList: [],      //权重为Null且公开的视频列表
            recList: [],     //权重为REC_OP且公开的视频列表
            columns: [
                {
                    flex: 0.5,
                    title: '选择',
                    dataIndex: 'checkbox',
                    key: 'checkbox',
                },
                {
                    flex: 0.5,
                    title: 'ID',
                    dataIndex: 'id',
                    key: 'id',
                },
                {
                    flex: 2,
                    title: '标题',
                    dataIndex: 'title',
                    key: 'title',
                },
                {
                    flex: 0.5,
                    title: '视频链接',
                    dataIndex: 'uri',
                    key: 'uri',
                },
                {
                    flex: 0.5,
                    title: '视频封面',
                    dataIndex: 'thumbnailUri',
                    key: 'thumbnailUri',
                },
            ],
            openVideoColumns: [
                {
                    flex: 0.5,
                    title: '选择',
                    dataIndex: 'checkbox',
                    key: 'checkbox',
                },
                {
                    flex: 0.5,
                    title: 'ID',
                    dataIndex: 'id',
                    key: 'id',
                },
                {
                    flex: 0.5,
                    title: '类型',
                    dataIndex: 'status',
                    key: 'status',
                },
                {
                    flex: 1.5,
                    title: '标题',
                    dataIndex: 'title',
                    key: 'title',
                },
                {
                    flex: 0.5,
                    title: '视频链接',
                    dataIndex: 'uri',
                    key: 'uri',
                },
                {
                    flex: 0.5,
                    title: '视频封面',
                    dataIndex: 'thumbnailUri',
                    key: 'thumbnailUri',
                },
            ],

            loadingTransfer: false,
            loadingUnRec: false,
            loadingRec: false,
            isRecLastPage: false,
            isUnRecLastPage: false,

            checkUnRecIds: [],
            checkRecIds: [],

            showVideo: false,
            showVideoUri: null,

            hasSelectedIntro: false,
        }
    }

    componentDidMount() {
        this._fetchUnRecVideos('refresh');
        this._fetchRecOpenVideos('refresh');
    }

    render(){
        const {
            recList, unRecList, checkRecIds, checkUnRecIds, showVideo, showVideoUri,
            loadingRec, loadingUnRec, isRecLastPage, isUnRecLastPage, columns, openVideoColumns, hasSelectedIntro
        } = this.state;
        const navBarHeight = this.navBarStore.height;
        const {width, height, col} = this.windowStore;
        const cols = ["XS", "S", "M", "L", "XL"];
        const colIndex = cols.indexOf(col);
        const smallThenL = colIndex < 4;
        const hasSelectedRec = checkRecIds.length > 0;
        const hasSelectedUnRec = checkUnRecIds.length > 0;

        const loadMoreUnRec = this._renderUnRecLoadMore(loadingUnRec, isUnRecLastPage);
        const loadMoreRec = this._renderRecLoadMore(loadingRec, isRecLastPage);

        return (
            <div style={{width: "100%", display: 'flex', flexDirection: 'row'}}>
                <div style={{width: smallThenL ? 60 : 290, height: 100}}/>
                <div style={{width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                    <Modal
                        title="视频"
                        centered
                        visible={showVideo}
                        width={500}
                        cancelText={'关闭'}
                        onOk={() => this._hideVideo()}
                        onCancel={() => this._hideVideo()}
                    >
                        <Player
                            ref={(ref) => this.videoModal = ref}
                            src={showVideoUri}
                        >
                            <HLSSource
                                isVideoChild
                                onRef={(ref) => {
                                    this.hlsSource = ref;
                                }}
                                src={showVideoUri}
                            />
                        </Player>
                    </Modal>
                    <div style={{width: "100%", height: height - navBarHeight, display: 'flex', flexDirection: 'row'}}>
                        <div style={{flex: 1, padding: 20}}>
                            <div style={{marginLeft: 16, display: 'flex', flexDirection: 'row'}}>
                                <Checkbox
                                    checked={hasSelectedUnRec}
                                    disabled={loadingUnRec}
                                    onChange={(e) => this._onCheck(e.target.checked, 'unRec', null)}
                                >
                                    {hasSelectedUnRec ? `${checkUnRecIds.length}/${unRecList.length}` : `${unRecList.length}个选项`}
                                </Checkbox>
                                <Checkbox
                                    checked={hasSelectedIntro}
                                    disabled={loadingUnRec}
                                    onChange={(e) => {this.setState({hasSelectedIntro: e.target.checked})}}
                                >
                                    官方介绍
                                </Checkbox>
                                <Typography.Paragraph
                                    strong={true}
                                    style={{marginLeft: 200,}}
                                >
                                    待推荐列表
                                </Typography.Paragraph>
                            </div>
                            <div style={{flex: 1, height: height - navBarHeight - 100, border: 'solid 1px #ccc'}}>
                                <div
                                    style={{
                                        width: "100%",
                                        height: 55,
                                        backgroundColor: '#dedede',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'flex-start',
                                        borderBottom: '1px solid #f0f0f0'
                                    }}
                                >
                                    {
                                        columns.map((columnItem, index) => {
                                            return (
                                                <div style={{flex: columnItem.flex, height: 55, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30}}>
                                                    <Typography.Paragraph strong={true}>{columnItem.title}</Typography.Paragraph>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <List
                                    className="unRecVideoList"
                                    loading={loadingUnRec}
                                    itemLayout="horizontal"
                                    style={{width: "100%", height: height - navBarHeight - 157, overflowY: 'scroll',  backgroundColor: '#fff',}}
                                    loadMore={unRecList.length > 0 ? loadMoreUnRec : null}
                                    dataSource={unRecList}
                                    renderItem={(video, index) => (
                                        <div
                                            key={index}
                                            style={{
                                                width: "100%",
                                                height: 50,
                                                backgroundColor: '#fff',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'flex-start',
                                                borderBottom: '1px solid #f0f0f0'
                                            }}
                                        >
                                            {
                                                columns.map((columnItem, cIndex) => {
                                                    const isCheckbox = columnItem.dataIndex === "checkbox";
                                                    const isVideo = columnItem.dataIndex === "uri";
                                                    const isThumbnail = columnItem.dataIndex === "thumbnailUri";

                                                    let info = video[columnItem.dataIndex];
                                                    if(isCheckbox){
                                                        info = (
                                                            <Checkbox
                                                                checked={checkUnRecIds.indexOf(parseInt(video.id)) > -1}
                                                                disabled={loadingUnRec}
                                                                onChange={(e) => this._onCheck(e.target.checked, 'unRec', video.id)}
                                                            >

                                                            </Checkbox>
                                                        )
                                                    }else if(isVideo){
                                                        info = (
                                                            <div
                                                                style={{
                                                                    width: 50,
                                                                    height: 50,
                                                                    display: 'flex',
                                                                    flexDirection: 'row'
                                                                }}
                                                            >
                                                                <div
                                                                    onClick={() => {this._openVideo(video[columnItem.dataIndex])}}
                                                                    style={{
                                                                        width: 50,
                                                                        height: 50,
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center'
                                                                    }}
                                                                >
                                                                    <Button icon={<PlayCircleOutlined />}/>
                                                                </div>
                                                            </div>
                                                        )
                                                    }else if(isThumbnail){
                                                        info = (
                                                            <Image
                                                                src={info}
                                                                style={{
                                                                    width: 25,
                                                                    height: 40,
                                                                }}
                                                            />
                                                        )
                                                    }

                                                    return (
                                                        <div
                                                            key={cIndex}
                                                            style={{flex: columnItem.flex, height:  50, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30}}>
                                                            <span>{info}</span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )}
                                />

                            </div>
                        </div>

                        <div style={{width: 100, height: height - navBarHeight, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                            <Button
                                disabled={!hasSelectedUnRec}
                                style={{width: 80, height: 80}}
                                icon={<RightOutlined style={{fontSize: 40}}/>}
                                onClick={() => this._transferToRec(hasSelectedIntro ? OPEN_VIDEO_STATUS.INTRO : OPEN_VIDEO_STATUS.DEFAULT, true)}
                            />
                            <Button
                                disabled={!hasSelectedRec}
                                style={{width: 80, height: 80, marginTop: 10}}
                                icon={<LeftOutlined style={{fontSize: 40}}/>}
                                onClick={() => this._transferToRec(OPEN_VIDEO_STATUS.BLOCK, false)}
                            />
                        </div>

                        <div style={{flex: 1, padding: 20}}>
                            <div style={{marginLeft: 16, display: 'flex', flexDirection: 'row'}}>
                                <Checkbox
                                    checked={hasSelectedRec}
                                    disabled={loadingRec}
                                    onChange={(e) => this._onCheck(e.target.checked, 'rec', null)}
                                >
                                    {hasSelectedRec ? `${checkRecIds.length}/${recList.length}` : `${recList.length}个选项`}
                                </Checkbox>
                                <Typography.Paragraph
                                    strong={true}
                                    style={{marginLeft: 200,}}
                                >
                                    开头视频列表
                                </Typography.Paragraph>
                            </div>
                            <div style={{flex: 1, height: height - navBarHeight - 100, border: 'solid 1px #ccc'}}>
                                <div
                                    style={{
                                        width: "100%",
                                        height: 55,
                                        backgroundColor: '#dedede',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'flex-start',
                                        borderBottom: '1px solid #f0f0f0'
                                    }}
                                >
                                    {
                                        openVideoColumns.map((columnItem, index) => {
                                            return (
                                                <div style={{flex: columnItem.flex, height: 55, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30}}>
                                                    <Typography.Paragraph strong={true}>{columnItem.title}</Typography.Paragraph>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <List
                                    className="recOpenVideoList"
                                    loading={loadingRec}
                                    itemLayout="horizontal"
                                    style={{width: "100%", height: height - navBarHeight - 157, overflowY: 'scroll', backgroundColor: '#fff',}}
                                    loadMore={recList.length > 0 ? loadMoreRec : null}
                                    dataSource={recList}
                                    renderItem={(openVideo, index) => (
                                        <div
                                            key={index}
                                            style={{
                                                width: "100%",
                                                height: 50,
                                                backgroundColor: '#fff',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'flex-start',
                                                borderBottom: '1px solid #f0f0f0'
                                            }}
                                        >
                                            {
                                                openVideoColumns.map((columnItem, cIndex) => {
                                                    const isCheckbox = columnItem.dataIndex === "checkbox";
                                                    const isStatus = columnItem.dataIndex === "status";
                                                    const isVideo = columnItem.dataIndex === "uri";
                                                    const isThumbnail = columnItem.dataIndex === "thumbnailUri";

                                                    let info = openVideo[columnItem.dataIndex];
                                                    if(isCheckbox){
                                                        info = (
                                                            <Checkbox
                                                                checked={checkRecIds.indexOf(parseInt(openVideo.videoId)) > -1}
                                                                disabled={loadingRec}
                                                                onChange={(e) => this._onCheck(e.target.checked, 'rec', openVideo.videoId)}
                                                            >

                                                            </Checkbox>
                                                        )
                                                    }else if(isStatus){
                                                        info = (
                                                            <div>
                                                                <Typography.Paragraph>
                                                                    {
                                                                        info === OPEN_VIDEO_STATUS.BLOCK ? '已下架' :
                                                                            info === OPEN_VIDEO_STATUS.DEFAULT ? '普通' :
                                                                                '官方介绍'
                                                                    }
                                                                </Typography.Paragraph>
                                                            </div>
                                                        )
                                                    }else if(isVideo){
                                                        info = (
                                                            <div
                                                                style={{
                                                                    width: 50,
                                                                    height: 50,
                                                                    display: 'flex',
                                                                    flexDirection: 'row'
                                                                }}
                                                            >
                                                                <div
                                                                    onClick={() => {this._openVideo(openVideo[columnItem.dataIndex])}}
                                                                    style={{
                                                                        width: 50,
                                                                        height: 50,
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center'
                                                                    }}
                                                                >
                                                                    <Button icon={<PlayCircleOutlined />}/>
                                                                </div>
                                                            </div>
                                                        )
                                                    }else if(isThumbnail){
                                                        info = (
                                                            <Image
                                                                src={info}
                                                                style={{
                                                                    width: 25,
                                                                    height: 40,
                                                                }}
                                                            />
                                                        )
                                                    }

                                                    return (
                                                        <div
                                                            key={cIndex}
                                                            style={{flex: columnItem.flex, height:  50, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30}}>
                                                            <span>{info}</span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    /**
     * 穿梭到推荐/未推荐列表
     * @private
     */
    _transferToRec = (status, isRec) => {
        const {recList, unRecList, checkUnRecIds, checkRecIds, loadingTransfer} = this.state;
        if(!loadingTransfer){
            this.setState({loadingTransfer: true});

            const url = "/apiB/video/op";
            const token = this.userStore.user.token;
            const formData = new FormData();

            if(isRec){
                formData.append("status", status);
                checkUnRecIds.map((vid, index) => {
                    formData.append("videoIdsStr", vid.toString());
                });
            }else {
                checkRecIds.map((vid, index) => {
                    formData.append("videoIdsStr", vid.toString());
                });
            }

            let isSuccess = false;
            fetch(url, {
                method: isRec ? 'POST' : 'DELETE',
                headers: {
                    'Authorization': token,
                    'Accept': 'application/json'
                },
                body: formData,
            })
                .then((response) => {
                    isSuccess = response.ok;
                    return response.json();
                })
                .then((json) => {
                    if(isSuccess){
                        let newRecList = isRec && recList.length > 0 ? [].concat(recList) : [];
                        let newUnRecList = !isRec && unRecList.length > 0 ? [].concat(unRecList) : [];
                        let newUnRecIds = [];
                        let newRecIds = [];

                        if(isRec){
                            //推荐
                            if(checkUnRecIds.length > 0){
                                unRecList.map((video, index) => {
                                    const arrIndex = checkUnRecIds.indexOf(parseInt(video.id));
                                    if(arrIndex > -1){
                                        //do nothing
                                    }else {
                                        newUnRecList.push(video);
                                    }
                                });
                                json.map((openVideo, oIndex) => {
                                    const video = {
                                        id: openVideo.id,
                                        videoId: openVideo.videoId,
                                        title: openVideo.title,
                                        opStatus: openVideo.status,
                                        thumbnailUri: openVideo.thumbnailUri,
                                        uri: openVideo.uri,
                                        upper: openVideo.upper
                                    }
                                    newRecList.unshift(video);
                                })
                            }

                        }else {
                            //不推荐
                            if(checkRecIds.length > 0){
                                recList.map((openVideo, index) => {
                                    const arrIndex = checkRecIds.indexOf(parseInt(openVideo.videoId));
                                    if(arrIndex > -1){
                                        const video = {
                                            id: openVideo.videoId,
                                            title: openVideo.title,
                                            thumbnailUri: openVideo.thumbnailUri,
                                            uri: openVideo.uri,
                                        }
                                        newUnRecList.unshift(video);
                                    }else {
                                        newRecList.push(openVideo);
                                    }
                                });
                            }
                        }

                        this.setState({
                            recList: newRecList,
                            unRecList: newUnRecList,
                            checkUnRecIds: newUnRecIds,
                            checkRecIds: newRecIds
                        });
                    }
                    this.setState({loadingTransfer: false});
                })
                .catch((err) => {
                    this.setState({loadingTransfer: false});
                });
        }
    };

    /**
     * 选中checkbox
     * @param checked
     * @param type
     * @param videoId
     * @private
     */
    _onCheck = (checked, type, videoId) => {
        if(videoId != null) {
            switch (type) {
                case 'unRec': {
                    const {checkUnRecIds} = this.state;
                    const arrIndex = checkUnRecIds.indexOf(parseInt(videoId));
                    if (checked) {
                        if (arrIndex === -1) {
                            let newArr = checkUnRecIds.length > 0 ? [].concat(checkUnRecIds) : [parseInt(videoId)];
                            if (checkUnRecIds.length > 0) {
                                newArr.push(parseInt(videoId));
                            }

                            this.setState({checkUnRecIds: newArr});
                        }
                    } else {
                        if (arrIndex > -1) {
                            let newArr = [].concat(checkUnRecIds);
                            newArr.splice(arrIndex, 1);

                            this.setState({checkUnRecIds: newArr});
                        }
                    }
                }
                    break;
                case 'rec': {
                    const {checkRecIds} = this.state;
                    const arrIndex = checkRecIds.indexOf(parseInt(videoId));
                    if (checked) {
                        if (arrIndex === -1) {
                            let newArr = checkRecIds.length > 0 ? [].concat(checkRecIds) : [parseInt(videoId)];
                            if (checkRecIds.length > 0) {
                                newArr.push(parseInt(videoId));
                            }

                            this.setState({checkRecIds: newArr});
                        }
                    } else {
                        if (arrIndex > -1) {
                            let newArr = [].concat(checkRecIds);
                            newArr.splice(arrIndex, 1);

                            this.setState({checkRecIds: newArr});
                        }
                    }
                }
                    break;
                default:
                    break;
            }
        }else {
            switch (type) {
                case 'unRec': {
                    const {unRecList} = this.state;
                    if(unRecList.length > 0) {
                        let newArr = [];
                        if (checked) {
                            unRecList.map((video, index) => {
                                newArr.push(parseInt(video.id));
                            })
                        }
                        this.setState({checkUnRecIds: newArr});
                    }
                }break;
                case 'rec': {
                    const {recList} = this.state;
                    if(recList.length > 0){
                        let newArr = [];
                        if(checked){
                            recList.map((video, index) => {
                               newArr.push(parseInt(video.id));
                            });
                        }
                        this.setState({checkRecIds: newArr});
                    }
                }break;
                default: break;
            }
        }
    };

    /**
     * 关闭视频
     * @private
     */
    _hideVideo = () => {
        this.videoModal.pause();
        this.setState({
            showVideo: false,
            showVideoUri: null
        })
    };

    /**
     * 打开视频
     * TODO 当前版本播放不了m3u8，用浏览器插件播放
     * @param uri
     * @private
     */
    _openVideo = (uri) => {
        if(uri.includes('.m3u8')){
            window.open(uri, '');
        }else {
            this.setState({
                showVideo: true,
                showVideoUri: uri,
            }, () => {
                this.videoModal.play();
            });
        }
    };

    /**
     * rec加载更多按钮
     * @param loadingRec
     * @param isRecLastPage
     * @returns {null}
     * @private
     */
    _renderRecLoadMore = (loadingRec, isRecLastPage) =>{
        return !loadingRec ? (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    marginBottom: 12,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button disabled={isRecLastPage} onClick={() => {
                    this._fetchRecOpenVideos("loadMore")
                }}>
                    {isRecLastPage ? "已无更多" : "加载更多"}
                </Button>
            </div>
        ) : null;
    };

    /**
     * unRec加载更多按钮
     * @param loadingUnRec
     * @param isUnRecLastPage
     * @returns {null}
     * @private
     */
    _renderUnRecLoadMore = (loadingUnRec, isUnRecLastPage) =>{
        return !loadingUnRec ? (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    marginBottom: 12,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button disabled={isUnRecLastPage} onClick={() => {
                    this._fetchUnRecVideos("loadMore")
                }}>
                    {isUnRecLastPage ? "已无更多" : "加载更多"}
                </Button>
            </div>
        ) : null;
    };

    /**
     * 拉取推荐列表
     * @param fetchType
     * @private
     */
    _fetchRecOpenVideos = (fetchType) => {
        const {loadingRec, recList} = this.state;
        if(!loadingRec) {
            this.setState({loadingRec: true});

            const token = this.userStore.user.token;
            const listSize = recList.length;
            const isRefresh = fetchType === "refresh";
            const lastItemId = !isRefresh && listSize > 0 ? recList[listSize - 1].id.toString() : "-1";
            const url = `/apiB/video/list/op`;

            let isSuccess = false;
            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
                .then((response) => {
                    isSuccess = response.ok;
                    return response.json();
                })
                .then((json) => {
                    if(isSuccess){
                        if(json.length === 0){
                            this.setState({
                                isRecLastPage: true
                            });
                        }else {
                            let isRecLastPage = false;
                            let newList = isRefresh ? [] : recList.length > 0 ? [].concat(recList) : [];
                            json.map((video, index) => {
                                video['key'] = index;
                                newList.push(video);
                            });
                            if (json.length < recVideoPageSize) {
                                isRecLastPage = true;
                            }
                            this.setState({
                                loadingRec: false,
                                isRecLastPage: isRecLastPage,
                                recList: newList,
                            })
                        }
                    }
                    this.setState({loadingRec: false});
                })
                .catch((err) => {
                    this.setState({loadingRec: false});
                });
        }
    };

    /**
     * 拉取未推荐列表
     * @param fetchType
     * @private
     */
    _fetchUnRecVideos = (fetchType) => {
        const {loadingUnRec, unRecList} = this.state;
        if(!loadingUnRec) {
            this.setState({loadingUnRec: true});

            const token = this.userStore.user.token;
            const listSize = unRecList.length;
            const isRefresh = fetchType === "refresh";
            const lastItemId = !isRefresh && listSize > 0 ? unRecList[listSize - 1].id.toString() : "-1";
            const url = `/apiB/video/list/release/${lastItemId}`;

            let isSuccess = false;
            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
                .then((response) => {
                    isSuccess = response.ok;
                    return response.json();
                })
                .then((json) => {
                    if(isSuccess){
                        if(json.length === 0){
                            this.setState({
                                isRecLastPage: true
                            });
                        }else {
                            let isUnRecLastPage = false;
                            let newList = isRefresh ? [] : unRecList.length > 0 ? [].concat(unRecList) : [];
                            json.map((video, index) => {
                                video['key'] = index;
                                newList.push(video);
                            });
                            if (json.length < recVideoPageSize) {
                                isUnRecLastPage = true;
                            }
                            this.setState({
                                loadingRec: false,
                                isUnRecLastPage: isUnRecLastPage,
                                unRecList: newList,
                            })
                        }
                    }
                    this.setState({loadingUnRec: false});
                })
                .catch((err) => {
                    this.setState({loadingUnRec: false});
                });
        }
    };
}

export default RecommendOpenVideo;
