/**
 * 自动登录
 * @param token
 */
import Global from "../Global";
import * as StringUtil from "./StringUtil";
import * as Cookie from "./Cookie";

/**
 * 登录成功后用token获取用户信息
 * @param token
 */
export async function autoLogin(token) {
    let isSuccess = false;
    const url = '/apiB/admin/autoLogin';
    let user = null;
    await fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': token
        },
    }).then((response) => {
        if (response.ok === true) {
            isSuccess = response.ok;
        }
        return response.json()
    }).then((json) => {
        if (isSuccess) {
            if (StringUtil.isNotBlank(json.id)) {
                Global.user = json;
                Global.isLogin = true;
                user = json;

                console.log("auto login success");
            }
        }
    }).catch((error) => {
        console.log(error);
        Cookie.docCookies.removeItem("token");
    });
    return await user;
}
