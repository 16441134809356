/**
 * 格式化视频状态
 * @param videoStatus
 * @returns {null}
 */
import {ALBUM_TYPE_STATUS, REC_ALBUM_WEIGHT, VIDEO_STATUS} from "../constants/Constants";

export function formatVideoStatus(videoStatus) {
    let statusStr = null;
    switch (videoStatus) {
        case VIDEO_STATUS.DRAFT: statusStr = "草稿";break;
        case VIDEO_STATUS.UN_APPROVED: statusStr = "审核未通过";break;
        case VIDEO_STATUS.BLOCKED_PRIVATE: statusStr = "封禁-自己可见";break;
        case VIDEO_STATUS.BLOCKED_FRIEND: statusStr = "封禁-朋友可见";break;
        case VIDEO_STATUS.BLOCKED_FANS: statusStr = "封禁-粉丝可见";break;
        case VIDEO_STATUS.BLOCKED_PUBLIC: statusStr = "封禁-公开可见";break;
        case VIDEO_STATUS.BLOCKED_TEAM: statusStr = "封禁-团队可见";break;
        case VIDEO_STATUS.IN_REVIEW_PRIVATE: statusStr = "审核中-自己可见";break;
        case VIDEO_STATUS.IN_REVIEW_FRIEND: statusStr = "审核中-朋友可见";break;
        case VIDEO_STATUS.IN_REVIEW_FANS: statusStr = "审核中-粉丝可见";break;
        case VIDEO_STATUS.IN_REVIEW_PUBLIC: statusStr = "审核中-公开可见";break;
        case VIDEO_STATUS.IN_REVIEW_TEAM: statusStr = "审核中-团队可见";break;
        case VIDEO_STATUS.PRIVATE: statusStr = "自己可见";break;
        case VIDEO_STATUS.PROTECTED_FRIEND: statusStr = "朋友可见";break;
        case VIDEO_STATUS.PROTECTED_FANS: statusStr = "粉丝可见";break;
        case VIDEO_STATUS.PUBLIC: statusStr = "公开可见";break;
        case VIDEO_STATUS.PROTECTED_TEAM: statusStr = "团队可见";break;

    }

    return statusStr;
}

/**
 * 格式化专辑类型状态
 * @param typeStatus
 * @returns {null}
 */
export function formatAlbumTypeStatus(typeStatus) {
    let statusStr = null;
    switch (typeStatus) {
        case ALBUM_TYPE_STATUS.VISIBLE: statusStr = "类型可见";break;
        case ALBUM_TYPE_STATUS.INVISIBLE: statusStr = "类型不可见";break;
        case ALBUM_TYPE_STATUS.RECOMMEND: statusStr = "推荐中";break;
        default: break;
    }

    return statusStr;
}

/**
 * 格式化专辑推荐权重
 * @param weight
 * @returns {*}
 */
export function formatAlbumWeight(weight) {
    let weightStr = null;
    switch (weight) {
        case null: weightStr = "普通";break;
        case REC_ALBUM_WEIGHT.HOT_ALBUM: weightStr = "热门";break;
        case REC_ALBUM_WEIGHT.LR_ALBUM: weightStr = "普通";break;
        case REC_ALBUM_WEIGHT.HL_ALBUM: weightStr = "头条";break;
        case REC_ALBUM_WEIGHT.OP_ALBUM: weightStr = "推荐中";break;
        case REC_ALBUM_WEIGHT.BANNER_ALBUM: weightStr = "Banner";break;
        default: break;
    }

    return weightStr;
}


