import {inject, observer} from "mobx-react";
import React from "react";
import {Button, List, Modal, Typography, Form, Input, Cascader, message, Checkbox, Tag, Radio} from "antd";
import CheckCircleOutlined from "@ant-design/icons/lib/icons/CheckCircleOutlined";
import StopOutlined from "@ant-design/icons/lib/icons/StopOutlined";
import ReloadOutlined from "@ant-design/icons/lib/icons/ReloadOutlined";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import {formatAlbumTypeStatus} from "../../util/StatusUtil";


@inject("windowStore", "userStore", "navBarStore")
@observer
class AlbumTypeListView extends React.Component {

    constructor(props) {
        super(props);
        this.windowStore = this.props.windowStore;
        this.userStore = this.props.userStore;
        this.navBarStore = this.props.navBarStore;
        this.state={
            typeList: [],
            columns: [
                {
                    flex: 0.5,
                    title: '选择',
                    dataIndex: 'checkbox',
                    key: 'checkbox',
                },
                {
                    flex: 1,
                    title: 'ID',
                    dataIndex: 'id',
                    key: 'id',
                },
                {
                    flex: 1,
                    title: '状态',
                    dataIndex: 'status',
                    key: 'status',
                },
                {
                    flex: 2,
                    title: '名称',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    flex: 1,
                    title: '创建时间',
                    dataIndex: 'createTime',
                    key: 'createTime',
                },
                {
                    flex: 1,
                    title: '投稿数',
                    dataIndex: 'countAlbums',
                    key: 'countAlbums',
                },
            ],

            loading: false,
            isLastPage: false,
            checkIds: [],

            isBlockList: false,
            blocking: false,

            showAddModal: false
        }
    }

    componentDidMount() {
        this._fetchTypes('refresh');
    }

    render(){
        const {typeList, loading, columns, checkIds, isBlockList, blocking, showAddModal} = this.state;
        const navBarHeight = this.navBarStore.height;
        const {width, height, col} = this.windowStore;
        const cols = ["XS", "S", "M", "L", "XL"];
        const colIndex = cols.indexOf(col);
        const smallThenL = colIndex < 4;
        const hasSelected = checkIds.length > 0;

        const tailLayout = {
            wrapperCol: { offset: 11},
        };

        return (
            <div style={{width: "100%", display: 'flex', flexDirection: 'row'}}>
                <div style={{width: smallThenL ? 60 : 290, height: 100}}/>
                <div style={{width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                    <Modal
                        title="添加专辑类型"
                        centered
                        visible={showAddModal}
                        width={548}
                        cancelText={'取消'}
                        okText={'提交'}
                        footer={null}
                        onCancel={() => this._showAddModal(false)}
                    >
                        <Form
                            onFinish={(value) => {this._addType(value.name)}}
                            layout="horizontal">
                            <Form.Item
                                label={"名称"}
                                name={"name"}
                                rules={[{ required: true, message: '名称不能为空' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item {...tailLayout}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={loading}
                                    icon={<ReloadOutlined/>}
                                >
                                    提交
                                </Button>
                            </Form.Item>
                        </Form>
                    </Modal>
                    <div style={{width: "80%", marginTop: 16, marginBottom: 16, marginLeft: 16, display: 'flex', flexDirection: 'row'}}>
                        <Button
                            type="default"
                            onClick={() => {this._blockTypes(isBlockList)}}
                            disabled={!hasSelected}
                            loading={blocking}
                            icon={isBlockList ? <CheckCircleOutlined /> : <StopOutlined/>}
                        >
                            {isBlockList ? "解封" : "封禁"}
                        </Button>
                        <Button
                            type="primary"
                            style={{marginLeft: 20}}
                            onClick={() => this._fetchTypes('refresh')}
                            loading={loading}
                            icon={<ReloadOutlined />}
                        >
                            刷新
                        </Button>
                        <Button
                            type="default"
                            primary
                            style={{marginLeft: 20}}
                            onClick={() => {this._showAddModal(true)}}
                            loading={loading}
                            icon={<PlusOutlined />}
                        >
                            添加
                        </Button>
                        <div style={{display: 'flex', flexDirection: 'row', marginLeft: 50, marginTop: 5}}>
                            <Radio.Group
                                onChange={(e) => {this._switchListType(e.target.value)}}
                                value={isBlockList}
                                style={{}}
                            >
                                <Radio value={false}>普通列表</Radio>
                                <Radio value={true}>封禁列表</Radio>
                            </Radio.Group>
                        </div>
                    </div>

                    <div
                        style={{
                            width: "100%",
                            height: 55,
                            backgroundColor: '#dedede',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            borderBottom: '1px solid #f0f0f0'
                        }}
                    >
                        {
                            columns.map((columnItem, index) => {
                                return (
                                    <div style={{flex: columnItem.flex, height: 55, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30}}>
                                        <Typography.Paragraph strong={true}>{columnItem.title}</Typography.Paragraph>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <List
                        className="typeList"
                        loading={loading}
                        itemLayout="horizontal"
                        style={{width: "100%", height: height - navBarHeight - 120, overflowY: 'scroll'}}
                        dataSource={typeList}
                        renderItem={(type, index) => (
                            <div
                                key={index}
                                style={{
                                    width: "100%",
                                    height: 50,
                                    backgroundColor: '#fff',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-start',
                                    borderBottom: '1px solid #f0f0f0'
                                }}
                            >
                                {
                                    columns.map((columnItem, cIndex) => {
                                        const isCheckbox = columnItem.dataIndex === "checkbox";
                                        let info = type[columnItem.dataIndex];

                                        if(isCheckbox){
                                            info = (
                                                <Checkbox
                                                    checked={checkIds.indexOf(parseInt(type.id)) > -1}
                                                    disabled={loading}
                                                    onChange={(e) => this._onCheck(e.target.checked, type.id)}
                                                >

                                                </Checkbox>
                                            )
                                        }else if(columnItem.dataIndex === "status"){
                                            info = <Tag color={isBlockList ? "error" : "success"}>{formatAlbumTypeStatus(type.status)}</Tag>
                                        }

                                        return (
                                            <div
                                                key={cIndex}
                                                style={{flex: columnItem.flex, height:  50, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30}}>
                                                <span>{info}</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )}
                    />

                </div>
            </div>
        )
    }

    /**
     * 切换列表类型
     * @param isBlockList
     * @private
     */
    _switchListType = (isBlockList) => {
        this.setState({
            isBlockList: isBlockList,
            typeList: []
        }, () => {
            this._fetchTypes('refresh');
        });
    };

    /**
     * 选中/取消
     * @param checked
     * @param typeId
     * @private
     */
    _onCheck = (checked, typeId) => {
        const {checkIds} = this.state;
        if(checked){
            if(checkIds.indexOf(parseInt(typeId)) === -1){
                let newArr = checkIds.length > 0 ? [].concat(checkIds) : [parseInt(typeId)];
                if(checkIds.length > 0){
                    newArr.push(parseInt(typeId));
                }
                this.setState({checkIds: newArr});
            }
        }else {
            const checkIndex = checkIds.indexOf(parseInt(typeId));
            if(checkIndex > -1){
                let newArr = [].concat(checkIds);
                newArr.splice(checkIndex, 1);

                this.setState({checkIds: newArr});
            }
        }
    };

    /**
     * 封禁或解封专辑类型
     * 一般不推荐此类型就好，不必封禁，实在要封，一般也是只封专辑不封专辑类型
     * @param isBlockList
     * @private
     */
    _blockTypes = (isBlockList) => {
        const {blocking, checkIds, typeList} = this.state;
        if(!blocking){
            this.setState({blocking: true});

            const token = this.userStore.user.token;
            const url = `/apiB/album/type/status`;
            const formData = new FormData();
            checkIds.map((typeId, index) => {
                formData.append("typeIds", typeId);
            });

            let isSuccess = false;
            fetch(url, {
                method: isBlockList ? 'DELETE' : 'PUT',
                headers: {
                    'Authorization': token,
                    'Accept': 'application/json'
                },
                body: formData,
            })
                .then((response) => {
                    isSuccess = response.ok;
                    return response.json();
                })
                .then((json) => {
                    if (isSuccess) {
                        let newArr = [];
                        typeList.map((type, index) => {
                            if(checkIds.indexOf(parseInt(type.id)) === -1){
                                newArr.push(type);
                            }
                        });
                        this.setState({
                            typeList: newArr,
                            checkIds: []
                        });
                        message.success(`${isBlockList ? "解封" : "封禁"}成功`);
                    }else {
                        message.error(`${isBlockList ? "解封" : "封禁"}失败`);
                    }
                    this.setState({blocking: false});
                })
                .catch((err) => {
                    message.error(`${isBlockList ? "解封" : "封禁"}失败`);
                    this.setState({blocking: false});
                });
        }
    };

    /**
     * 添加专辑类型
     * @private
     */
    _addType = (name) => {
        const {loading, typeList} = this.state;
        if(!loading) {
            this.setState({loading: true});

            const token = this.userStore.user.token;
            const url = `/apiB/album/type`;
            const formData = new FormData();
            formData.append("typeName", name);

            let isSuccess = false;
            fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': token,
                    'Accept': 'application/json'
                },
                body: formData,
            })
                .then((response) => {
                    isSuccess = response.ok;
                    return response.json();
                })
                .then((json) => {
                    if(isSuccess){
                        let newArr = typeList.length > 0 ? [].concat(typeList) : [json];
                        if(typeList.length > 0){
                            newArr.push(json);
                        }

                        this.setState({
                            typeList: newArr
                        });
                        this._showAddModal(false);
                        message.success("添加成功");
                    }else {
                        message.error("添加失败");
                    }
                    this.setState({loading: false});
                })
                .catch((err) => {
                    this.setState({loading: false});
                    message.error("添加失败");
                });

        }
    };

    /**
     * 显示添加Modal
     * @private
     */
    _showAddModal = (show) => {
        this.setState({showAddModal: show});
    };

    /**
     * 请求专辑类型列表
     * 类型不会多，不用分页
     * @param fetchType
     * @private
     */
    _fetchTypes = (fetchType) => {
        const {loading, isBlockList} = this.state;
        if(!loading) {
            this.setState({loading: true});

            const token = this.userStore.user.token;
            const url = `/apiB/album/type/list/${isBlockList ? 'block' : 'normal'}`;

            let isSuccess = false;
            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
                .then((response) => {
                    isSuccess = response.ok;
                    return response.json();
                })
                .then((json) => {
                    if (isSuccess) {
                        this.setState({
                            typeList: json
                        });
                    }
                    this.setState({loading: false});
                })
                .catch((err) => {
                    this.setState({loading: false});
                });

        }
    };

}

export default AlbumTypeListView;
