import React from 'react';
import './Home.css';
import {
    ContainerOutlined,
    PlayCircleOutlined,
    HomeOutlined,
    UserOutlined,
    TableOutlined,
    AndroidOutlined,
    ToolOutlined,
    SearchOutlined,
    WarningOutlined,
    PushpinOutlined,
    FireOutlined
} from '@ant-design/icons';
import Animated from 'animated/lib/targets/react-dom';
import {observer, inject} from "mobx-react";
import {action, observable} from "mobx";
import 'art/modes/svg';                  // SVG模式，不引入时可能是默认canvas模式
import SVGPath from 'art/modes/svg/path'
import UploadApk from "../UploadApk/UploadApk";
import {NavLink, Route, Switch} from "react-router-dom";
import Empty from "../Empty";
import ReviewVideo from "../ReviewVideo/ReviewVideo";
import UserListView from "../UserListView/UserListView";
import {Menu} from 'antd';
import VideoListView from "../VideoListView/VideoListView";
import ReportListView from "../ReportListView/ReportListView";
import RecommendVideo from "../RecommendVideo/RecommendVideo";
import DatabaseOutlined from "@ant-design/icons/lib/icons/DatabaseOutlined";
import AlbumTypeListView from "../AlbumTypeListView/AlbumTypeListView";
import RecommendAlbumType from "../RecommendAlbumType/RecommendAlbumType";
import RecommendAlbum from "../RecommendAlbum/RecommendAlbum";
import Dashboard from "../Dashboard/Dashboard";
import Bulletin from "../Bulletin/Bulletin";
import AddTestAccount from "../AddTestAccount/AddTestAccount";
import RecommendOpenVideo from "../RecommendOpenVideo/RecommendOpenVideo";
const { SubMenu } = Menu;

@inject("windowStore", "navBarStore")
@observer
class Home extends React.Component {

    @observable
    menus;

    @observable
    tabs;

    constructor(props) {
        super(props);

        this.windowStore = this.props.windowStore;
        this.navBarStore = this.props.navBarStore;

        this.state = {
            collapsed: false,
            showMenuAnimated: new Animated.Value(0),
            showMenu: true,
        };

        this.menus = [
            {index: 0, name: "控制中心", icon: <HomeOutlined />, link: "/index", component: Dashboard},
            {index: 1, name: "审核", icon: <SearchOutlined/>, link: "", component: null, subMenus: [
                    {index: 1, name: "视频", icon: <PlayCircleOutlined />, link: "/review/video", component: ReviewVideo},
                    {index: 2, name: "专辑", icon: <TableOutlined />, link: "/review/album", component: Empty},
                ]
            },
            {index: 2, name: "推荐", icon: <FireOutlined />, link: "", component: null, subMenus: [
                    {index: 3, name: "开头视频", icon: <PlayCircleOutlined />, link: "/recommend/openVideo", component: RecommendOpenVideo},
                    {index: 4, name: "推视频", icon: <PlayCircleOutlined />, link: "/recommend/video", component: RecommendVideo},
                    {index: 5, name: "推专辑", icon: <TableOutlined />, link: "/recommend/album", component: RecommendAlbum},
                    {index: 6, name: "推专辑类型", icon: <DatabaseOutlined />, link: "/recommend/albumType", component: RecommendAlbumType},
                ]
            },
            {index: 3, name: "视频管理", icon: <PlayCircleOutlined/>, link: "", component: null, subMenus: [
                    {index: 7, name: "正常列表", icon: <PlayCircleOutlined/>, link: "/video/normal", component: VideoListView},
                    {index: 8, name: "封禁列表", icon: <PlayCircleOutlined/>, link: "/video/block", component: VideoListView},
                ]
            },
            {index: 4, name: "用户管理", icon: <UserOutlined/>, link: "", component: null, subMenus: [
                    {index: 5, name: "青少年", icon: <UserOutlined />, link: "", component: null, subMenus: [
                            {index: 9, name: "公开", icon: <UserOutlined/>, link: "/user/teen/public", component: UserListView},
                            {index: 10, name: "私人", icon: <UserOutlined/>, link: "/user/teen/private", component: UserListView},
                            {index: 11, name: "封禁(普通)", icon: <UserOutlined/>, link: "/user/teen/normal/block", component: UserListView},
                            {index: 12, name: "封禁(私人)", icon: <UserOutlined/>, link: "/user/teen/private/block", component: UserListView},
                        ]
                    },
                    {index: 6, name: "成年", icon: <UserOutlined />, link: "", component: null, subMenus: [
                            {index: 13, name: "公开", icon: <UserOutlined/>, link: "/user/adult/public", component: UserListView},
                            {index: 14, name: "私人", icon: <UserOutlined/>, link: "/user/adult/private", component: UserListView},
                            {index: 15, name: "封禁(普通)", icon: <UserOutlined/>, link: "/user/adult/normal/block", component: UserListView},
                            {index: 16, name: "封禁(私人)", icon: <UserOutlined/>, link: "/user/adult/private/block", component: UserListView},
                        ]
                    },
                ]
            },
            {index: 5, name: "举报管理", icon: <WarningOutlined />, link: "", component: null, subMenus: [
                    {index: 17, name: "举报视频", icon: <PlayCircleOutlined/>, link: "/complain/video", component: ReportListView},
                    {index: 18, name: "举报用户", icon: <UserOutlined/>, link: "/complain/user", component: ReportListView},
                ]
            },

            {index: 18, name: "添加测试用户", icon: <UserOutlined/>, link: "/addTestAccount", component: AddTestAccount},
            {index: 19, name: "专辑类型", icon: <TableOutlined />, link: "/album/type", component: AlbumTypeListView},
            {index: 20, name: "用户反馈", icon: <ContainerOutlined />, link: "/feedback", component: Empty},
            {index: 21, name: "推送", icon: <PushpinOutlined />, link: "/push", component: Empty},
            {index: 22, name: "上传Apk", icon: <AndroidOutlined />, link: "/uploadApk", component: UploadApk},
            {index: 23, name: "系统维护", icon: <ToolOutlined />, link: "/bulletin", component: Bulletin},

        ];
        this.tabs = [
            "/console/index",
            "/console/review/video", "/console/review/album",
            "/console/recommend/openVideo", "/console/recommend/video", "/console/recommend/album", "/console/recommend/albumType",
            "/console/video/normal", "/console/video/block",
            "/console/user/teen/public", "/console/user/teen/private", "/console/user/teen/normal/block", "/console/user/teen/private/block",
            "/console/user/adult/public", "/console/user/adult/private", "/console/user/adult/normal/block", "/console/user/adult/private/block",
            "/console/complain/video", "/console/complain/user",
            "/console/addTestAccount", "/console/album/type", "/console/feedback", "/console/push", "/console/uploadApk", "/console/bulletin",
        ];
    }

    componentWillMount() {

    }

    _formatMenus = (menus) => {
        let formatArr = [];
        if(menus.length > 0){
            menus.map((item, index) => {
                if(item.component != null){
                    formatArr.push(item);
                }else {
                    item.subMenus.map((subItem, subIndex) => {  //二级
                        let newSubItem = {...subItem};
                        newSubItem.link = `${item.link}${subItem.link}`;
                        if(newSubItem.component != null){
                            formatArr.push(newSubItem);
                        }else {
                            newSubItem.subMenus.map((deepItem, deepIndex) => { //三级
                                let newDeepItem = {...deepItem};
                                newDeepItem.link = newSubItem.link + newDeepItem.link;
                                formatArr.push(newDeepItem);
                            });
                        }
                    })
                }
            })
        }
        return formatArr;
    };

    render() {
        const {showMenuAnimated} = this.state;
        const {width, height, col} = this.windowStore;

        const url = this.props.history.location.pathname;
        const tabs = this.tabs;
        const menus = this.menus;
        const menuComponents = this._formatMenus(menus);
        const navBarHeight = this.navBarStore.height;
        const cols = ["XS", "S", "M", "L", "XL"];
        const colIndex = cols.indexOf(col);
        const smallThenS = colIndex < 2;
        const smallThenL = colIndex < 4;
        const smallThenFullHD = width < 1920;

        const translateX = showMenuAnimated.interpolate({
            inputRange: [0, 1],
            outputRange: [0, smallThenL ? -60 : - 256]
        });

        return (
            <div
                className={"personal_body"}
                style={{width: '100%', height: height - navBarHeight, display: 'flex', flexDirection: 'row', backgroundColor: '#f9fafc'}}
            >
                <Animated.div style={{
                    position: 'absolute',
                    zIndex: 1000,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    transform: [{translateX: translateX}]
                }} className={"console"}>
                    <Menu
                        defaultSelectedKeys={['0']}
                        selectedKeys={["" + tabs.indexOf(url)]}
                        mode="inline"
                        style={{
                            width: smallThenL ? 60 : 256,
                            height: height - navBarHeight,
                            overflowY: 'auto',
                        }}
                        inlineCollapsed={smallThenL}
                    >

                        {
                            menus.map((item, index) => {
                                const link = "/console" + item.link;
                                if(item.component != null) {
                                    return (
                                        <Menu.Item
                                            key={`${item.index}`}
                                            style={{
                                                display: 'flex',
                                                justifyContent: smallThenL ? 'center' : 'flex-start',
                                                alignItems: 'center',
                                                width: smallThenL ? 60 : 'auto',
                                            }}>
                                            <NavLink
                                                to={link}
                                                onClick={() => smallThenFullHD ? this._openMenu() : null}
                                            >
                                                {item.icon}
                                                <span>{item.name}</span>
                                            </NavLink>
                                        </Menu.Item>
                                    )
                                }else {
                                    return (
                                        <SubMenu
                                            key={`sub${item.index}`}
                                            icon={item.icon}
                                            title={item.name}
                                        >
                                            {
                                                item.subMenus.map((subItem, subIndex) => {
                                                    const subLink = "/console" + subItem.link;
                                                    if(subItem.component != null){
                                                        return (
                                                            <Menu.Item
                                                                key={`${subItem.index}`}
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: smallThenL ? 'center' : 'flex-start',
                                                                    alignItems: 'center',
                                                                    width: smallThenL ? 60 : 'auto',
                                                                }}>
                                                                <NavLink
                                                                    to={subLink}
                                                                    onClick={() => smallThenFullHD ? this._openMenu() : null}
                                                                >
                                                                    {subItem.icon}
                                                                    <span>{subItem.name}</span>
                                                                </NavLink>
                                                            </Menu.Item>
                                                        )
                                                    }else {
                                                        return (
                                                            <SubMenu
                                                                key={`sub${subItem.index}`}
                                                                icon={subItem.icon}
                                                                title={subItem.name}
                                                            >
                                                                {
                                                                    subItem.subMenus.map((deepItem, deepIndex) => {
                                                                        const deepLink = subLink + deepItem.link;
                                                                        return (
                                                                            <Menu.Item
                                                                                key={`${deepItem.index}`}
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    justifyContent: smallThenL ? 'center' : 'flex-start',
                                                                                    alignItems: 'center',
                                                                                    width: smallThenL ? 60 : 'auto',
                                                                                }}>
                                                                                <NavLink
                                                                                    to={deepLink}
                                                                                    onClick={() => smallThenFullHD ? this._openMenu() : null}
                                                                                >
                                                                                    {deepItem.icon}
                                                                                    <span>{deepItem.name}</span>
                                                                                </NavLink>
                                                                            </Menu.Item>
                                                                        )
                                                                    })
                                                                }
                                                            </SubMenu>
                                                        )
                                                    }
                                                })
                                            }
                                        </SubMenu>
                                    )
                                }
                            })
                        }
                    </Menu>
                </Animated.div>
                <Animated.div style={{
                    display: 'flex',
                    width: "100%",
                    height: "100%",
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    backgroundColor: '#e4e4e4',
                }}>
                    <React.Fragment style={{backgroundColor: '#fff', overflowY: 'auto'}}>
                        <Switch style={{backgroundColor: '#fff'}}>
                            {
                                menuComponents.map((item, index) => {
                                    const path = "/console" + item.link;
                                    return (
                                        <Route path={path} component={item.component}/>
                                    )
                                })
                            }
                        </Switch>
                    </React.Fragment>
                </Animated.div>
            </div>
        )
    }

    _openMenu = () => {
        const isOpen = this.state.showMenu;
        if (!isOpen) {
            Animated.timing(
                this.state.showMenuAnimated,
                {
                    toValue: 0,
                    duration: 200
                }).start();
        }else {
            Animated.timing(
                this.state.showMenuAnimated,
                {
                    toValue: 1,
                    duration: 200
                }).start();
        }
        this.setState({
            showMenu: !isOpen
        });
    };
}

export default Home;
