import React from 'react';
import {
    Button,
    Row,
    Col,
    Typography,
    Tag,
    Input,
    Avatar,
    Tooltip,
    Checkbox,
    Select,
    Form,
    message,
    Alert,
    Modal
} from 'antd';
import Icon from '@ant-design/icons';
import './UnAuth.css';
import {observer, inject} from "mobx-react";
import {action, observable} from "mobx";
import * as Cookie from "../../util/Cookie";
import * as LoginUtil from "../../util/LoginUtil";
import * as StringUtil from "../../util/StringUtil";
import Global from '../../Global';
import Toast from "antd-mobile/es/toast/index";
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";
import SecurityScanOutlined from "@ant-design/icons/lib/icons/SecurityScanOutlined";
import QuestionCircleOutlined from "@ant-design/icons/lib/icons/QuestionCircleOutlined";

const { Option } = Select;

@inject("windowStore", "navBarStore", "userStore")
@observer
class UnAuth extends React.Component {

    @observable
    disabled;

    constructor(props) {
        super(props);
        this.windowStore = this.props.windowStore;
        this.navBarStore = this.props.navBarStore;
        this.userStore = this.props.userStore;

        this.state = {
            tid: null,
            platform: null,
            securityCode: null,
            confirmSubmit: false,
            showAlert: false
        };
        this.disabled = true;
    }

    componentWillMount() {
        setTimeout(() => {
            this.disabled = false;
        }, 100);
    }

    render() {
        const {tid, platform, securityCode, confirmSubmit, showAlert} = this.state;
        const {height, width, col} = this.windowStore;
        const isXs = col === "XS";
        const smallThenL = col === "M" || col === "L";


        return (
            <div className={"login-body"} style={{minHeight: height}}>
                <Modal
                    title="Revoke the vrClose license"
                    visible={showAlert}
                    onOk={() => {this._postUnAuth()}}
                    onCancel={() => {this._hideAlert()}}
                    okText={"Confirm"}
                >
                    <p>Confirm and your vrClose account will be closed</p>
                </Modal>
                <Row style={{width: "100%", marginTop: 0.15 * height}}>
                    <Col
                        xs={{span: 20, offset: 2}}
                        s={{span: 16, offset: 4}}
                        m={{span: 12, offset: 6}}
                        lg={{span: 8, offset: 8}}
                        xl={{span: 5, offset: 9}}
                    >
                        <div className={"input-card"}
                             style={{
                                 boxShadow: isXs ? null : "0px 0px 25px #e1e1e1",
                                 padding: isXs ? 0 : "50pt",
                                 borderRadius: isXs ? 0 : "10pt",
                                 backgroundColor: 'rgba(0,0,0,0.1)'
                             }}
                        >
                            <div id={"step-1"} style={{padding: "10px", paddingTop: 0}}>
                                <div className={"flex-row"} style={{marginTop: 20, borderBottom: "1px solid #ccc"}}>
                                    <Input
                                        id={"tid"}
                                        className={"input"}
                                        size={"large"}
                                        value={tid}
                                        disabled={this.disabled}
                                        placeholder={"Username / User ID"}
                                        onChange={(e) => this._setValue(e.target.value, 0)}
                                        prefix={<UserOutlined style={{color: '#c0c0c0'}}/>}
                                        suffix={
                                            <Tooltip placement="rightTop" title={"App Settings -> Security"}>
                                                <QuestionCircleOutlined
                                                    style={{color: '#c0c0c0'}}
                                                    onClick={() => {}}
                                                />
                                            </Tooltip>
                                        }
                                    />
                                </div>
                                <div className={"flex-row"} style={{borderBottom: "1px solid #ccc"}}>
                                    <Input
                                        id={"securityCode"}
                                        className={"input"}
                                        size={"large"}
                                        value={securityCode}
                                        disabled={this.disabled}
                                        placeholder={"Security Code"}
                                        onChange={(e) => this._setValue(e.target.value, 1)}
                                        prefix={<SecurityScanOutlined style={{color: '#c0c0c0'}}/>}
                                        suffix={
                                            <Tooltip placement="rightTop" title={"App Settings -> Security"}>
                                                <QuestionCircleOutlined
                                                    style={{color: '#c0c0c0'}}
                                                    onClick={() => {}}
                                                />
                                            </Tooltip>
                                        }
                                    />
                                </div>
                                <div className={"flex-row"} style={{marginTop: 20, display: 'flex', alignItems: 'center'}}>
                                    <Typography.Text style={{fontWeight: 'bold'}}>
                                        Remove by:
                                    </Typography.Text>
                                    <Select
                                        placeholder="Select"
                                        disabled={this.disabled}
                                        onChange={(value) => this._setValue(value, 2)}
                                        allowClear
                                        style={{marginLeft: 15, width: 280}}
                                    >
                                        <Option value="username">Username</Option>
                                        <Option value="facebook">Facebook user ID</Option>
                                        <Option value="twitter">Twitter user ID</Option>
                                    </Select>
                                </div>
                                <div className={"flex-row"} style={{marginTop: 20}}>
                                    <Checkbox
                                        disabled={this.disabled}
                                        checked={confirmSubmit}
                                        onChange={(e) => this._onCheck(e.target.checked)}
                                    >
                                        <Typography.Text>
                                            I agree to revoke the vrClose license
                                        </Typography.Text>
                                    </Checkbox>
                                </div>
                                <Button
                                    block
                                    disabled={!confirmSubmit}
                                    onClick={() => this._validateUnAuth()}
                                    type={"primary"}
                                    size={"large"}
                                    style={{marginTop: 20, borderRadius: 100}}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }

    /**
     * 确认删除
     * @param checked
     * @private
     */
    _onCheck = (checked) => {
        this.setState({confirmSubmit: checked});
    };


    @action
    _setValue = (value, index) => {
        switch (index) {
            case 0:
                this.setState({
                    tid: value
                });
                break;
            case 1:
                this.setState({
                    securityCode: value
                });
                break;
            case 2:
                this.setState({
                    platform: value
                });
                break;
            default:
                break;
        }
    };

    /**
     * 校验和提示
     * @private
     */
    _validateUnAuth = () => {
        const {tid, platform, securityCode} = this.state;
        if(tid == null || tid === ""){
            message.error("please input user ID");
        }else if(securityCode == null || securityCode === ""){
            message.error("please input security code");
        }else if(platform == null){
            message.error("please select platform");
        }else {
            this.setState({showAlert: true});
        }
    };

    /**
     * 提交后台
     * @private
     */
    _postUnAuth = () => {
        const {tid, platform, securityCode} = this.state;
        const url = `/apiB/user/auth`;

        const formData = new FormData();
        formData.append("platform", platform);
        formData.append("tid", tid + "");
        formData.append("securityCode", securityCode + "");

        let isSuccess = false;
        fetch(url, {
            method: 'DELETE',
            headers: {
                'Authorization': null,
                'Accept': 'application/json'
            },
            body: formData,
        })
            .then((response) => {
                const status = response.status;
                isSuccess = response.ok;
                if(isSuccess){
                    message.success("Execute success");
                }else {
                    let reason = null;
                    switch (status) {
                        case 402: reason = "Invalid Parameter";break;
                        case 502: reason = "Invalid Platform";break;
                        case 404: reason = "Invalid User";break;
                        case 503: reason = "Invalid Security Code";break;
                        default: break;
                    }
                    message.error(`Execute failed:${reason}`);
                }
                return response.json();
            })
            .then((json) => {
                this.setState({
                    tid: null,
                    platform: null,
                    securityCode: null,
                    showAlert: false
                });
            })
            .catch((err) => {
                message.error(`Execute failed:-1`);
            });

    };

    /**
     * 隐藏提示框
     * @private
     */
    _hideAlert = () => {
        this.setState({showAlert: false});
    };
}

export default UnAuth;
