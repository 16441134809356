import { observable, action } from "mobx";
import {isDev} from "../../constants/Constants";

class NavBarStore {
    @observable tabs;
    @observable height;

    constructor(){
        if(isDev){
            this.tabs = [
                {name: "Home", icon: "home"},
            ];
        }else {
            this.tabs = [
                {name: "Home", icon: "home"},
                {name: "Privacy Policy", icon: "privacy"},
                {name: "User Protocol", icon: "protocol"},
            ];
        }
        this.height = 0;
    }

    @action
    setNavBarHeight = (height) => {
        this.height = height;
    };

    @action
    addTab = (tab) => {
        this.tabs.unshift(tab)
    };

    @action
    deleteTab = (name) => {
        this.tabs.map((oldTab, index) => {
            if(oldTab.name === name){
                this.tabs.splice(index, 1);
            }
        })
    };

    @action
    changeTab = (newTab) => {
        let oldTabs = this.tabs;
        let newTabs = [];
        oldTabs.map((oldTab, index) => {
            if(oldTab.name === "登录" || oldTab.name === "退出"){
                newTabs.push(newTab);
            }else {
                newTabs.push(oldTab);
            }
        });
        this.tabs = newTabs;
    }

}

const navBarStore = new NavBarStore();

export {navBarStore};
