import React from 'react';
import {inject, observer} from "mobx-react";
import {NavLink, Route, Switch} from "react-router-dom";
import {Table, Checkbox, Typography, Button, Image, Modal, List, message, Tag} from "antd";
import {
    colorType,
    TIPS_TYPE_STR,
    USER_STATUS,
    USER_STATUS_COLOR,
    userPageSize,
    videoPageSize
} from "../../constants/Constants";
import CheckCircleOutlined from "@ant-design/icons/lib/icons/CheckCircleOutlined";
import ReloadOutlined from "@ant-design/icons/lib/icons/ReloadOutlined";
import StopOutlined from "@ant-design/icons/lib/icons/StopOutlined";
import moment from 'moment';
import WomanOutlined from "@ant-design/icons/lib/icons/WomanOutlined";
import ManOutlined from "@ant-design/icons/lib/icons/ManOutlined";

@inject("windowStore", "userStore", "navBarStore")
@observer
class UserListView extends React.Component {

    constructor(props) {
        super(props);
        this.windowStore = this.props.windowStore;
        this.userStore = this.props.userStore;
        this.navBarStore = this.props.navBarStore;
        this.state={
            userList: [],
            columns: [
                {
                    flex: 0.5,
                    title: '选择',
                    dataIndex: 'checkbox',
                    key: 'checkbox',
                },
                {
                    flex: 1,
                    title: 'ID',
                    dataIndex: 'id',
                    key: 'id',
                },
                {
                    flex: 0.5,
                    title: '用户状态',
                    dataIndex: 'userStatus',
                    key: 'userStatus',
                },
                {
                    flex: 1,
                    title: '头像',
                    dataIndex: 'profile',
                    key: 'profile',
                },
                {
                    flex: 0.5,
                    title: '性别',
                    dataIndex: 'sex',
                    key: 'sex',
                },
                {
                    flex: 1.5,
                    title: '昵称',
                    dataIndex: 'nickname',
                    key: 'nickname',
                },
                {
                    flex: 1.5,
                    title: '用户名',
                    dataIndex: 'username',
                    key: 'username',
                },
                {
                    flex: 1.5,
                    title: '创建日期',
                    dataIndex: 'createTime',
                    key: 'createTime',
                },

                {
                    flex: 1.5,
                    title: '出生日期',
                    dataIndex: 'birthday',
                    key: 'birthday',
                },
            ],

            blocking: false,
            loading: false,
            isLastPage: false,
            emptyList: false,
            checkIds: [],

            locationPath: null
        }
    }

    componentDidMount() {
        this._fetchUsers('refresh');
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const locationPath = window.location.pathname;
        if(this.state.locationPath !== locationPath){
            this.setState({
                locationPath: locationPath,
                userList: [],
                isLastPage: false,
                emptyList: false,
                checkIds: [],
            }, () => {
                this._fetchUsers('refresh');
            })
        }
    }

    render() {
        const {userList, blocking, isLastPage, loading, emptyList, columns, checkIds} = this.state;
        const locationPath = window.location.pathname;
        const isBlockList = locationPath.indexOf("block") > -1;
        const navBarHeight = this.navBarStore.height;
        const {width, height, col} = this.windowStore;
        const cols = ["XS", "S", "M", "L", "XL"];
        const colIndex = cols.indexOf(col);
        const smallThenL = colIndex < 4;
        const hasSelected = checkIds.length > 0;

        const loadMore = !loading ? (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    marginBottom: 12,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button disabled={isLastPage} onClick={() => {this._fetchUsers("loadMore")}}>
                    {isLastPage ? "已无更多" : "加载更多"}
                </Button>
            </div>
        ) : null;

        return (
            <div style={{width: "100%", display: 'flex', flexDirection: 'row'}}>
                <div style={{width: smallThenL ? 60 : 290, height: 100}}/>
                <div style={{width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                    <div style={{marginTop: 16, marginBottom: 16, marginLeft: 16, display: 'flex', flexDirection: 'row'}}>
                        <Button
                            type="default"
                            onClick={() => {this._updateUsers(isBlockList)}}
                            disabled={!hasSelected}
                            loading={blocking}
                            icon={isBlockList ? <CheckCircleOutlined /> : <StopOutlined/>}
                        >
                            {isBlockList ? "解封" : "封号"}
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => this._fetchUsers('refresh')}
                            style={{marginLeft: 20}}
                            loading={loading}
                            icon={<ReloadOutlined />}
                        >
                            刷新
                        </Button>
                    </div>
                    <div
                        style={{
                            width: "100%",
                            height: 55,
                            backgroundColor: '#dedede',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            borderBottom: '1px solid #f0f0f0'
                        }}
                    >
                        {
                            columns.map((columnItem, index) => {
                                return (
                                    <div style={{flex: columnItem.flex, height: 55, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30}}>
                                        <Typography.Paragraph strong={true}>{columnItem.title}</Typography.Paragraph>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <List
                        className="userList"
                        loading={false}
                        itemLayout="horizontal"
                        style={{width: "100%", height: height - navBarHeight - 120, overflowY: 'scroll'}}
                        loadMore={loadMore}
                        dataSource={userList}
                        renderItem={(user, index) => (
                            <div
                                key={index}
                                style={{
                                    width: "100%",
                                    height: 50,
                                    backgroundColor: '#fff',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-start',
                                    borderBottom: '1px solid #f0f0f0'
                                }}
                            >
                                {
                                    columns.map((columnItem, cIndex) => {
                                        let info = user[columnItem.dataIndex];
                                        const isCheckbox = columnItem.dataIndex === "checkbox";
                                        const isProfile = columnItem.dataIndex === "profile";
                                        const isDate = columnItem.dataIndex === "birthday" || columnItem.dataIndex === "createTime";
                                        const isGender = columnItem.dataIndex === "sex";
                                        const isStatus = columnItem.dataIndex === "userStatus";

                                        if(isCheckbox){
                                            info = (
                                                <Checkbox
                                                    checked={checkIds.indexOf(parseInt(user.id)) > -1}
                                                    disabled={loading}
                                                    onChange={(e) => this._onCheck(e.target.checked, user.id, user.userStatus)}
                                                >

                                                </Checkbox>
                                            )
                                        }else if(isProfile){
                                            const profile = user[columnItem.dataIndex];
                                            const crossOrigin = profile == null ? false : profile.indexOf("vrclose") > -1;

                                            if(profile != null){
                                                info = (
                                                    <Image
                                                        crossOrigin={crossOrigin ? null : "anonymous"}
                                                        src={profile}
                                                        style={{
                                                            width: 40,
                                                            height: 40,
                                                            borderRadius: 50,
                                                            marginRight: 10
                                                        }}
                                                    />
                                                )
                                            }
                                        }else if(isDate){
                                            let itemDate = user[columnItem.dataIndex];
                                            if(itemDate != null && itemDate !== ""){
                                                info = this._formatDate(itemDate);
                                            }
                                        }else if(isGender){
                                            const icon = user[columnItem.dataIndex] === 0 ? <ManOutlined style={{color: colorType.primary}}/> : <WomanOutlined style={{color: '#e6423c'}} />;
                                            info = user[columnItem.dataIndex] === 0 ? "男" : "女";
                                            info = (
                                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                                                    <span style={{marginRight: 5}}>{info}</span>
                                                    {icon}
                                                </div>
                                            )
                                        }else if(isStatus){
                                            info = <Tag color={USER_STATUS_COLOR[info]}>{this._formatStatus(info)}</Tag>;
                                        }

                                        return (
                                            <div
                                                key={cIndex}
                                                style={{flex: columnItem.flex, height:  50, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30}}>
                                                <span>{info}</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )}
                    />
                </div>
            </div>
        )
    }

    /**
     * 格式化用户状态
     * @param status
     * @returns {null}
     * @private
     */
    _formatStatus = (status) => {
        let statusStr = null;
        switch (status) {
            case USER_STATUS.TEENAGER: statusStr = "正常";break;
            case USER_STATUS.NORMAL: statusStr = "正常";break;
            case USER_STATUS.BLOCKED_TEENAGER_ADMIN: statusStr = "封禁";break;
            case USER_STATUS.BLOCKED_NORMAL_ADMIN: statusStr = "封禁";break;
            case USER_STATUS.TEENAGER_PRIVATE: statusStr = "正常";break;
            case USER_STATUS.NORMAL_PRIVATE: statusStr = "正常";break;
            case USER_STATUS.BLOCKED_TEENAGER_PRIVATE_ADMIN: statusStr = "封禁";break;
            case USER_STATUS.BLOCKED_NORMAL_PRIVATE_ADMIN: statusStr = "封禁";break;
            default: break;
        }

        return statusStr;
    };

    /**
     *
     * @param isBlockList
     * @private
     */
    _updateUsers = (isBlockList) => {
        const {checkIds, checkStatus} = this.state;
        const token = this.userStore.user.token;

        if(checkIds.length > 0){
            this.setState({blocking: true});

            const url = "/apiB/user/block";
            const method = !isBlockList ? 'POST' : 'DELETE';
            const formData = new FormData();
            checkIds.map((id, index) => {
                formData.append("checkIdsStr", id.toString());
                formData.append("checkStatus", checkStatus[index]);
            });

            let isSuccess = false;
            fetch(url, {
                method: method,
                headers: {
                    'Authorization': token,
                    'Accept': 'application/json'
                },
                body: formData,
            })
                .then((response) => {
                    isSuccess = response.ok;
                    return response.json();
                })
                .then((json) => {
                    if(isSuccess){
                        let {userList} = this.state;
                        let newArr = [];
                        userList.map((user, index) => {
                            if(checkIds.indexOf(parseInt(user.id)) === -1){
                                newArr.push(user);
                            }
                        });

                        this.setState({
                            checkIds: [],
                            checkStatus: [],
                            userList: newArr
                        }, () => {
                            message.success(`${isBlockList ? "解封" : "封号"}成功`);
                        })
                    }else {
                        message.error(`${isBlockList ? "解封" : "封号"}失败，请重试`);
                    }
                    this.setState({blocking: false});
                })
                .catch((err) => {
                    this.setState({blocking: false});
                });
        }
    };

    /**
     * 格式化日期
     * @param date
     * @private
     */
    _formatDate = (date) => {
        const formatDate =  new Date(date);
        return moment(formatDate.getTime()).utc(true).format("yyyy-MM-DD HH:mm:ss");
    };

    /**
     * 选择用户Item
     * @param checked
     * @param userId
     * @param userStatus
     * @private
     */
    _onCheck = (checked, userId, userStatus) => {
        let {checkIds, checkStatus} = this.state;
        if(checked){
            if(checkIds.indexOf(parseInt(userId)) === -1){
                const notEmpty = checkIds.length > 0;
                let newArr = notEmpty ? [].concat(checkIds) : [parseInt(userId)];
                let newStatusArr = notEmpty ? [].concat(checkStatus) : [parseInt(userStatus)];

                if(notEmpty){
                    newArr.push(parseInt(userId));
                    newStatusArr.push(parseInt(userStatus));
                }
                this.setState({
                    checkIds: newArr,
                    checkStatus: newStatusArr,
                });
            }
        }else {
            const arrIndex = checkIds.indexOf(parseInt(userId));
            if(arrIndex > -1){
                let newArr = [].concat(checkIds);
                let newStatusArr = [].concat(checkStatus);

                newArr.splice(arrIndex, 1);
                newStatusArr.splice(arrIndex, 1);

                this.setState({
                    checkIds: newArr,
                    checkStatus: newStatusArr,
                });
            }
        }
    };

    /**
     * 拉取用户列表
     * @param fetchType
     * @private
     */
    _fetchUsers = (fetchType) => {
        const {loading, userList} = this.state;
        if(!loading) {

            let filter = null;
            const locationPath = window.location.pathname;

            if(locationPath.indexOf("/teen/normal/block") > -1){
                filter = "010";
            }else if(locationPath.indexOf("/teen/private/block") > -1){
                filter = "011";
            }else if(locationPath.indexOf("/adult/normal/block") > -1){
                filter = "110";
            }else if(locationPath.indexOf("/adult/private/block") > -1){
                filter = "111";
            }else if(locationPath.indexOf("/teen/public") > -1){
                filter = "000";
            }else if(locationPath.indexOf("/teen/private") > -1){
                filter = "001";
            }else if(locationPath.indexOf("/adult/public") > -1){
                filter = "100";
            }else if(locationPath.indexOf("/adult/private") > -1){
                filter = "101";
            }else {
                return null;
            }

            this.setState({loading: true});
            const token = this.userStore.user.token;
            const listSize = userList.length;
            const isRefresh = fetchType === "refresh";
            const lastItemId = !isRefresh && listSize > 0 ? userList[listSize - 1].id : -1;
            const url = `/apiB/user/list/review/${lastItemId}/${filter}`;

            let isSuccess = false;
            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
                .then((response) => {
                    isSuccess = response.ok;
                    return response.json();
                })
                .then((json) => {
                   if(isSuccess){
                       let isEmpty = false;
                       if(json.length === 0){
                           if(userList.length === 0){
                               isEmpty = true;
                           }
                           this.setState({
                               emptyList: isEmpty,
                               isLastPage: true,
                               loading: false
                           });
                       }else {
                           let isLastPage = false;
                           let newList = isRefresh ? [] : userList.length > 0 ? [].concat(userList) : [];
                           json.map((user, index) => {
                               user['key'] = index;
                               newList.push(user);
                           });
                           if (json.length < userPageSize) {
                               isLastPage = true;
                           }
                           this.setState({
                               loading: false,
                               isLastPage: isLastPage,
                               userList: newList,
                               emptyList: isEmpty
                           })
                       }
                   }
                    this.setState({loading: false});
                })
                .catch((err) => {
                    this.setState({loading: false});
                });
        }
    };

}

export default UserListView;
