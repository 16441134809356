import {inject, observer} from "mobx-react";
import React from "react";
import {Button, Checkbox, Image, List, message, Typography, Tag} from 'antd';
import RightOutlined from "@ant-design/icons/lib/icons/RightOutlined";
import LeftOutlined from "@ant-design/icons/lib/icons/LeftOutlined";
import {formatAlbumTypeStatus} from "../../util/StatusUtil";

@inject("windowStore", "userStore", "navBarStore")
@observer
class RecommendAlbumType extends React.Component {

    constructor(props) {
        super(props);
        this.windowStore = this.props.windowStore;
        this.userStore = this.props.userStore;
        this.navBarStore = this.props.navBarStore;
        this.state={
            recList: [],
            unRecList: [],
            columns: [
                {
                    flex: 0.5,
                    title: '选择',
                    dataIndex: 'checkbox',
                    key: 'checkbox',
                },
                {
                    flex: 1,
                    title: 'ID',
                    dataIndex: 'id',
                    key: 'id',
                },
                {
                    flex: 1,
                    title: '状态',
                    dataIndex: 'status',
                    key: 'status',
                },
                {
                    flex: 2,
                    title: '名称',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    flex: 1,
                    title: '创建时间',
                    dataIndex: 'createTime',
                    key: 'createTime',
                },
                {
                    flex: 1,
                    title: '投稿数',
                    dataIndex: 'countAlbums',
                    key: 'countAlbums',
                },
            ],

            loadingRec: false,
            loadingUnRec: false,
            isLastPage: false,
            checkUnRecIds: [],
            checkRecIds: [],

            isRecList: true,
            recommending: false,

            showAddModal: false
        }
    }

    componentDidMount() {
        this._fetchTypes('refresh', 'rec');
        this._fetchTypes('refresh', 'default');
    }

    render(){
        const {recList, unRecList, loadingRec, loadingUnRec, columns, checkRecIds, checkUnRecIds, isRecList, recommending} = this.state;
        const navBarHeight = this.navBarStore.height;
        const {width, height, col} = this.windowStore;
        const cols = ["XS", "S", "M", "L", "XL"];
        const colIndex = cols.indexOf(col);
        const smallThenL = colIndex < 4;
        const hasSelectedRec = checkRecIds.length > 0;
        const hasSelectedUnRec = checkUnRecIds.length > 0;

        return (
            <div style={{width: "100%", display: 'flex', flexDirection: 'row'}}>
                <div style={{width: smallThenL ? 60 : 290, height: 100}}/>
                <div style={{width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                    <div style={{width: "100%", height: height - navBarHeight, display: 'flex', flexDirection: 'row'}}>
                        <div style={{flex: 1, padding: 20}}>
                            <div style={{marginLeft: 16, display: 'flex', flexDirection: 'row'}}>
                                <Checkbox
                                    checked={hasSelectedUnRec}
                                    disabled={loadingUnRec}
                                    onChange={(e) => this._onCheck(e.target.checked, 'unRec', null)}
                                >
                                    {hasSelectedUnRec ? `${checkUnRecIds.length}/${unRecList.length}` : `${unRecList.length}个选项`}
                                </Checkbox>
                                <Typography.Paragraph
                                    strong={true}
                                    style={{marginLeft: 200,}}
                                >
                                    待推荐列表
                                </Typography.Paragraph>
                            </div>
                            <div style={{flex: 1, height: height - navBarHeight - 100, border: 'solid 1px #ccc'}}>
                                <div
                                    style={{
                                        width: "100%",
                                        height: 55,
                                        backgroundColor: '#dedede',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'flex-start',
                                        borderBottom: '1px solid #f0f0f0'
                                    }}
                                >
                                    {
                                        columns.map((columnItem, index) => {
                                            return (
                                                <div style={{flex: columnItem.flex, height: 55, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30}}>
                                                    <Typography.Paragraph strong={true}>{columnItem.title}</Typography.Paragraph>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <List
                                    className="unRecVideoList"
                                    loading={loadingUnRec}
                                    itemLayout="horizontal"
                                    style={{width: "100%", height: height - navBarHeight - 157, overflowY: 'scroll', backgroundColor: '#fff',}}
                                    dataSource={unRecList}
                                    renderItem={(type, index) => (
                                        <div
                                            key={index}
                                            style={{
                                                width: "100%",
                                                height: 50,
                                                backgroundColor: '#fff',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'flex-start',
                                                borderBottom: '1px solid #f0f0f0'
                                            }}
                                        >
                                            {
                                                columns.map((columnItem, cIndex) => {
                                                    const isCheckbox = columnItem.dataIndex === "checkbox";
                                                    const isStatus = columnItem.dataIndex === "status";

                                                    let info = type[columnItem.dataIndex];
                                                    if(isCheckbox){
                                                        info = (
                                                            <Checkbox
                                                                checked={checkUnRecIds.indexOf(parseInt(type.id)) > -1}
                                                                disabled={loadingUnRec}
                                                                onChange={(e) => this._onCheck(e.target.checked, 'unRec', type.id)}
                                                            >

                                                            </Checkbox>
                                                        )
                                                    }else if(isStatus){
                                                        info = <Tag color={"success"}>{formatAlbumTypeStatus(type.status)}</Tag>
                                                    }

                                                    return (
                                                        <div
                                                            key={cIndex}
                                                            style={{flex: columnItem.flex, height:  50, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30}}>
                                                            <span>{info}</span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )}
                                />

                            </div>
                        </div>

                        <div style={{width: 100, height: height - navBarHeight, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                            <Button
                                disabled={!hasSelectedUnRec}
                                style={{width: 80, height: 80}}
                                icon={<RightOutlined style={{fontSize: 40}}/>}
                                onClick={() => this._transferToRec()}
                            />
                            <Button
                                disabled={!hasSelectedRec}
                                style={{width: 80, height: 80, marginTop: 10}}
                                icon={<LeftOutlined style={{fontSize: 40}}/>}
                                onClick={() => this._transferToUnRec()}
                            />
                        </div>

                        <div style={{flex: 1, padding: 20}}>
                            <div style={{marginLeft: 16, display: 'flex', flexDirection: 'row'}}>
                                <Checkbox
                                    checked={hasSelectedRec}
                                    disabled={loadingRec}
                                    onChange={(e) => this._onCheck(e.target.checked, 'rec', null)}
                                >
                                    {hasSelectedRec ? `${checkRecIds.length}/${recList.length}` : `${recList.length}个选项`}
                                </Checkbox>
                                <Typography.Paragraph
                                    strong={true}
                                    style={{marginLeft: 200,}}
                                >
                                    推荐列表
                                </Typography.Paragraph>
                            </div>
                            <div style={{flex: 1, height: height - navBarHeight - 100, border: 'solid 1px #ccc'}}>
                                <div
                                    style={{
                                        width: "100%",
                                        height: 55,
                                        backgroundColor: '#dedede',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'flex-start',
                                        borderBottom: '1px solid #f0f0f0'
                                    }}
                                >
                                    {
                                        columns.map((columnItem, index) => {
                                            return (
                                                <div style={{flex: columnItem.flex, height: 55, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30}}>
                                                    <Typography.Paragraph strong={true}>{columnItem.title}</Typography.Paragraph>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <List
                                    className="unRecVideoList"
                                    loading={loadingRec}
                                    itemLayout="horizontal"
                                    style={{width: "100%", height: height - navBarHeight - 157, overflowY: 'scroll', backgroundColor: '#fff',}}
                                    dataSource={recList}
                                    renderItem={(type, index) => (
                                        <div
                                            key={index}
                                            style={{
                                                width: "100%",
                                                height: 50,
                                                backgroundColor: '#fff',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'flex-start',
                                                borderBottom: '1px solid #f0f0f0'
                                            }}
                                        >
                                            {
                                                columns.map((columnItem, cIndex) => {
                                                    const isCheckbox = columnItem.dataIndex === "checkbox";
                                                    const isStatus = columnItem.dataIndex === "status";

                                                    let info = type[columnItem.dataIndex];
                                                    if(isCheckbox){
                                                        info = (
                                                            <Checkbox
                                                                checked={checkRecIds.indexOf(parseInt(type.id)) > -1}
                                                                disabled={loadingRec}
                                                                onChange={(e) => this._onCheck(e.target.checked, 'rec', type.id)}
                                                            >

                                                            </Checkbox>
                                                        )
                                                    }else if(isStatus){
                                                        info = <Tag color={"processing"}>{formatAlbumTypeStatus(type.status)}</Tag>
                                                    }

                                                    return (
                                                        <div
                                                            key={cIndex}
                                                            style={{flex: columnItem.flex, height:  50, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30}}>
                                                            <span>{info}</span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    /**
     * 穿梭到推荐列表
     * @private
     */
    _transferToRec = () => {
        const {recList, unRecList, checkUnRecIds, loadingTransfer} = this.state;
        if(!loadingTransfer){
            this.setState({loadingTransfer: true});

            const url = "/apiB/album/type/recommend";
            const token = this.userStore.user.token;
            const formData = new FormData();

            checkUnRecIds.map((tid, index) => {
                formData.append("typeIds", tid);
            });

            let isSuccess = false;
            fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': token,
                    'Accept': 'application/json'
                },
                body: formData,
            })
                .then((response) => {
                    isSuccess = response.ok;
                    return response.json();
                })
                .then((json) => {
                    if(isSuccess){
                        let newRecList = recList.length > 0 ? [].concat(recList) : [];
                        let newUnRecList = [];
                        let newUnRecIds = [];
                        let newRecIds = [];

                        if(checkUnRecIds.length > 0){
                            unRecList.map((type, index) => {
                                const arrIndex = checkUnRecIds.indexOf(parseInt(type.id));
                                if(arrIndex > -1){
                                    newRecList.unshift(type);
                                }else {
                                    newUnRecList.push(type);
                                }
                            });
                        }
                        this.setState({
                            recList: newRecList,
                            unRecList: newUnRecList,
                            checkUnRecIds: newUnRecIds,
                            checkRecIds: newRecIds
                        });
                        message.success("推荐成功");
                    }else {
                        message.error("推荐失败");
                    }
                    this.setState({loadingTransfer: false});
                })
                .catch((err) => {
                    message.error("推荐失败");
                    this.setState({loadingTransfer: false});
                });
        }
    };

    /**
     * 穿梭到未推荐列表
     * @private
     */
    _transferToUnRec = () => {
        const {recList, unRecList, checkRecIds, loadingTransfer} = this.state;
        if(!loadingTransfer) {
            this.setState({loadingTransfer: true});

            const url = "/apiB/album/type/recommend";
            const token = this.userStore.user.token;
            const formData = new FormData();

            checkRecIds.map((tid, index) => {
                formData.append("typeIds", tid);
            });

            let isSuccess = false;
            fetch(url, {
                method: 'DELETE',
                headers: {
                    'Authorization': token,
                    'Accept': 'application/json'
                },
                body: formData,
            })
                .then((response) => {
                    isSuccess = response.ok;
                    return response.json();
                })
                .then((json) => {
                    if(isSuccess){
                        let newRecList = [];
                        let newUnRecList = unRecList.length > 0 ? [].concat(unRecList) : [];
                        let newUnRecIds = [];
                        let newRecIds = [];

                        if(checkRecIds.length > 0){
                            recList.map((type, index) => {
                                const arrIndex = checkRecIds.indexOf(parseInt(type.id));
                                if(arrIndex > -1){
                                    newUnRecList.unshift(type);
                                }else {
                                    newRecList.push(type);
                                }
                            });
                        }
                        this.setState({
                            recList: newRecList,
                            unRecList: newUnRecList,
                            checkUnRecIds: newUnRecIds,
                            checkRecIds: newRecIds
                        });
                        message.success("取消推荐成功");
                    }else {
                        message.error("取消推荐失败");
                    }
                    this.setState({loadingTransfer: false});
                })
                .catch((err) => {
                    message.error("取消推荐失败");
                    this.setState({loadingTransfer: false});
                });
        }
    };

    /**
     * 选中checkbox
     * @param checked
     * @param type
     * @param typeId
     * @private
     */
    _onCheck = (checked, type, typeId) => {
        if(typeId != null) {
            switch (type) {
                case 'unRec': {
                    const {checkUnRecIds} = this.state;
                    const arrIndex = checkUnRecIds.indexOf(parseInt(typeId));
                    if (checked) {
                        if (arrIndex === -1) {
                            let newArr = checkUnRecIds.length > 0 ? [].concat(checkUnRecIds) : [parseInt(typeId)];
                            if (checkUnRecIds.length > 0) {
                                newArr.push(parseInt(typeId));
                            }

                            this.setState({checkUnRecIds: newArr});
                        }
                    } else {
                        if (arrIndex > -1) {
                            let newArr = [].concat(checkUnRecIds);
                            newArr.splice(arrIndex, 1);

                            this.setState({checkUnRecIds: newArr});
                        }
                    }
                }
                    break;
                case 'rec': {
                    const {checkRecIds} = this.state;
                    const arrIndex = checkRecIds.indexOf(parseInt(typeId));
                    if (checked) {
                        if (arrIndex === -1) {
                            let newArr = checkRecIds.length > 0 ? [].concat(checkRecIds) : [parseInt(typeId)];
                            if (checkRecIds.length > 0) {
                                newArr.push(parseInt(typeId));
                            }

                            this.setState({checkRecIds: newArr});
                        }
                    } else {
                        if (arrIndex > -1) {
                            let newArr = [].concat(checkRecIds);
                            newArr.splice(arrIndex, 1);

                            this.setState({checkRecIds: newArr});
                        }
                    }
                }
                    break;
                default:
                    break;
            }
        }else {
            switch (type) {
                case 'unRec': {
                    const {unRecList} = this.state;
                    if(unRecList.length > 0) {
                        let newArr = [];
                        if (checked) {
                            unRecList.map((typeId, index) => {
                                newArr.push(parseInt(typeId.id));
                            })
                        }
                        this.setState({checkUnRecIds: newArr});
                    }
                }break;
                case 'rec': {
                    const {recList} = this.state;
                    if(recList.length > 0){
                        let newArr = [];
                        if(checked){
                            recList.map((typeId, index) => {
                                newArr.push(parseInt(typeId.id));
                            });
                        }
                        this.setState({checkRecIds: newArr});
                    }
                }break;
                default: break;
            }
        }
    };

    /**
     * 请求专辑类型列表
     * 类型不会多，不用分页
     * @param fetchType
     * @param listType
     * @private
     */
    _fetchTypes = (fetchType, listType) => {
        const {loadingRec, loadingUnRec} = this.state;
        const isRec = listType === 'rec';
        if(isRec ? !loadingRec : !loadingUnRec) {
            this._setLoading(listType, true);

            const token = this.userStore.user.token;
            const url = `/apiB/album/type/list/${isRec ? 'recommend' : 'normal'}`;

            let isSuccess = false;
            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
                .then((response) => {
                    isSuccess = response.ok;
                    return response.json();
                })
                .then((json) => {
                    if (isSuccess) {
                        if(isRec){
                            this.setState({recList: json});
                        }else {
                            this.setState({unRecList: json});
                        }
                    }
                    this._setLoading(listType, false);
                })
                .catch((err) => {
                    this._setLoading(listType, false);
                });

        }
    };

    /**
     * 更新loading
     * @param listType
     * @param value
     * @private
     */
    _setLoading = (listType, value) => {
        if(listType === 'rec'){
            this.setState({loadingRec: value});
        }else {
            this.setState({loadingUnRec: value});
        }
    }
}

export default RecommendAlbumType;
