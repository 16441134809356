import {inject, observer} from "mobx-react";
import React from 'react';
import {Button, Checkbox, Image, List, message, Modal, Tag, Typography} from "antd";
import {formatAlbumTypeStatus, formatAlbumWeight} from "../../util/StatusUtil";
import RightOutlined from "@ant-design/icons/lib/icons/RightOutlined";
import LeftOutlined from "@ant-design/icons/lib/icons/LeftOutlined";
import {REC_ALBUM_WEIGHT, recVideoPageSize} from "../../constants/Constants";
import {Player} from "video-react";
import StarFilled from "@ant-design/icons/lib/icons/StarFilled";
import StarTwoTone from "@ant-design/icons/lib/icons/StarTwoTone";

@inject("windowStore", "userStore", "navBarStore")
@observer
class RecommendAlbum extends React.Component {

    constructor(props) {
        super(props);
        this.windowStore = this.props.windowStore;
        this.userStore = this.props.userStore;
        this.navBarStore = this.props.navBarStore;
        this.state={
            recList: [],
            unRecList: [],
            columns: [
                {
                    flex: 0.5,
                    title: '选择',
                    dataIndex: 'checkbox',
                    key: 'checkbox',
                },
                {
                    flex: 0.5,
                    title: 'ID',
                    dataIndex: 'id',
                    key: 'id',
                },
                {
                    flex: 0.5,
                    title: '状态',
                    dataIndex: 'weight',
                    key: 'weight',
                },
                {
                    flex: 0.5,
                    title: '类型',
                    dataIndex: 'type',
                    key: 'type',
                },
                {
                    flex: 2,
                    title: '标题',
                    dataIndex: 'title',
                    key: 'title',
                },
                {
                    flex: 0.5,
                    title: '描述',
                    dataIndex: 'description',
                    key: 'description',
                },
                {
                    flex: 0.5,
                    title: '封面图',
                    dataIndex: 'thumbnailUri',
                    key: 'thumbnailUri',
                },
                // {
                //     flex: 1,
                //     title: '发布者',
                //     dataIndex: 'upper',
                //     key: 'upper',
                // },

            ],

            loadingRec: false,
            loadingUnRec: false,
            isLastRecPage: false,
            isLastUnRecPage: false,
            checkUnRecIds: [],
            checkRecIds: [],

            isRecList: true,
            loadingTransfer: false,

            descriptionText: null
        }
    }

    componentDidMount() {
        this._fetchAlbums('refresh', 'rec');
        this._fetchAlbums('refresh', 'default');
    }

    render(){
        const {
            recList, unRecList, loadingRec, loadingUnRec, columns, checkRecIds, checkUnRecIds,
            isLastRecPage, isLastUnRecPage, isRecList, loadingTransfer, descriptionText
        } = this.state;
        const navBarHeight = this.navBarStore.height;
        const {width, height, col} = this.windowStore;
        const cols = ["XS", "S", "M", "L", "XL"];
        const colIndex = cols.indexOf(col);
        const smallThenL = colIndex < 4;
        const hasSelectedRec = checkRecIds.length > 0;
        const hasSelectedUnRec = checkUnRecIds.length > 0;

        return (
            <div style={{width: "100%", display: 'flex', flexDirection: 'row'}}>
                <div style={{width: smallThenL ? 60 : 290, height: 100}}/>
                <div style={{width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                    <Modal
                        title="视频"
                        centered
                        visible={descriptionText != null}
                        width={500}
                        cancelText={'关闭'}
                        onOk={() => this._hideDescription()}
                        onCancel={() => this._hideDescription()}
                    >
                        <span>{descriptionText}</span>
                    </Modal>
                    <div style={{width: "100%", height: height - navBarHeight, display: 'flex', flexDirection: 'row'}}>
                        <div style={{flex: 1, padding: 20}}>
                            <div style={{marginLeft: 16, display: 'flex', flexDirection: 'row'}}>
                                <Checkbox
                                    checked={hasSelectedUnRec}
                                    disabled={loadingUnRec}
                                    onChange={(e) => this._onCheck(e.target.checked, 'unRec', null)}
                                >
                                    {hasSelectedUnRec ? `${checkUnRecIds.length}/${unRecList.length}` : `${unRecList.length}个选项`}
                                </Checkbox>
                                <Typography.Paragraph
                                    strong={true}
                                    style={{marginLeft: 200,}}
                                >
                                    待推荐列表
                                </Typography.Paragraph>
                            </div>
                            <div style={{flex: 1, height: height - navBarHeight - 100, border: 'solid 1px #ccc'}}>
                                <div
                                    style={{
                                        width: "100%",
                                        height: 55,
                                        backgroundColor: '#dedede',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'flex-start',
                                        borderBottom: '1px solid #f0f0f0'
                                    }}
                                >
                                    {
                                        columns.map((columnItem, index) => {
                                            return (
                                                <div style={{flex: columnItem.flex, height: 55, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30}}>
                                                    <Typography.Paragraph strong={true}>{columnItem.title}</Typography.Paragraph>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <List
                                    className="unRecAlbumList"
                                    loading={loadingUnRec}
                                    itemLayout="horizontal"
                                    style={{width: "100%", height: height - navBarHeight - 157, overflowY: 'scroll', backgroundColor: '#fff',}}
                                    dataSource={unRecList}
                                    renderItem={(album, index) => this._renderItem(album, index, checkUnRecIds, loadingUnRec, 'unRec')}
                                />

                            </div>
                        </div>

                        <div style={{width: 100, height: height - navBarHeight, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                            <Button
                                disabled={!hasSelectedUnRec || loadingTransfer}
                                style={{width: 80, height: 80}}
                                icon={<RightOutlined style={{fontSize: 40}}/>}
                                onClick={() => this._transferToRec()}
                            />
                            <Button
                                disabled={!hasSelectedRec || loadingTransfer}
                                style={{width: 80, height: 80, marginTop: 10}}
                                icon={<LeftOutlined style={{fontSize: 40}}/>}
                                onClick={() => this._transferToUnRec()}
                            />
                        </div>

                        <div style={{flex: 1, padding: 20}}>
                            <div style={{marginLeft: 16, display: 'flex', flexDirection: 'row'}}>
                                <Checkbox
                                    checked={hasSelectedRec}
                                    disabled={loadingRec}
                                    onChange={(e) => this._onCheck(e.target.checked, 'rec', null)}
                                >
                                    {hasSelectedRec ? `${checkRecIds.length}/${recList.length}` : `${recList.length}个选项`}
                                </Checkbox>
                                <Typography.Paragraph
                                    strong={true}
                                    style={{marginLeft: 200,}}
                                >
                                    推荐列表
                                </Typography.Paragraph>
                            </div>
                            <div style={{flex: 1, height: height - navBarHeight - 100, border: 'solid 1px #ccc'}}>
                                <div
                                    style={{
                                        width: "100%",
                                        height: 55,
                                        backgroundColor: '#dedede',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'flex-start',
                                        borderBottom: '1px solid #f0f0f0'
                                    }}
                                >
                                    {
                                        columns.map((columnItem, index) => {
                                            return (
                                                <div style={{flex: columnItem.flex, height: 55, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30}}>
                                                    <Typography.Paragraph strong={true}>{columnItem.title}</Typography.Paragraph>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <List
                                    className="recAlbumList"
                                    loading={loadingRec}
                                    itemLayout="horizontal"
                                    style={{width: "100%", height: height - navBarHeight - 157, overflowY: 'scroll', backgroundColor: '#fff',}}
                                    dataSource={recList}
                                    renderItem={(album, index) => this._renderItem(album, index, checkRecIds, loadingRec, 'rec')}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    /**
     * 渲染Item
     * @param album
     * @param index
     * @param checkIds
     * @param loading
     * @param listType
     * @returns {*}
     * @private
     */
    _renderItem = (album, index, checkIds, loading, listType) => {
        const {columns} = this.state;
        return (
            <div
                key={index}
                style={{
                    width: "100%",
                    height: 50,
                    backgroundColor: '#fff',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    borderBottom: '1px solid #f0f0f0'
                }}
            >
                {
                    columns.map((columnItem, cIndex) => {
                        const isCheckbox = columnItem.dataIndex === "checkbox";
                        const isWeight = columnItem.dataIndex === "weight";
                        const isUpper = columnItem.dataIndex === "upper";
                        const isThumbnail = columnItem.dataIndex === "thumbnailUri";
                        const isDescription = columnItem.dataIndex === "description";
                        const isTitle = columnItem.dataIndex === "title";
                        const isType = columnItem.dataIndex === "type";

                        let info = album[columnItem.dataIndex];
                        if(isCheckbox){
                            info = (
                                <Checkbox
                                    checked={checkIds.indexOf(parseInt(album.id)) > -1}
                                    disabled={loading}
                                    onChange={(e) => this._onCheck(e.target.checked, listType, album.id)}
                                >

                                </Checkbox>
                            )
                        }else if(isWeight){
                            const isRec = listType === "rec";
                            const isBanner = album.weight === REC_ALBUM_WEIGHT.BANNER_ALBUM;
                            info = (
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <Tag color={isRec ? isBanner ? "warning" : "processing" : "success"}>{formatAlbumWeight(info)}</Tag>
                                    {
                                        isRec ?
                                            <div
                                                onClick={() => {this._transferToRecBanner(album.id, isBanner)}}
                                                style={{justifyContent: 'center', alignItems: 'center'}}>
                                                <StarTwoTone twoToneColor={isBanner ? "#fadb14" : "#ccc"}/>
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                            )
                        }else if(isUpper){
                            const upperId = album.upper.id;
                            const nickname = album.upper.nickname;
                            const profile = album.upper.profile;
                            const crossOrigin = profile == null ? false : profile.indexOf("vrclose") > -1;

                            info = (
                                <div style={{display: 'flex', flexDirection: 'row', alignSelf: 'center', justifyContent: 'center', alignItems: 'flex-start'}}>
                                    {
                                        profile == null ? null :
                                            <Image
                                                crossOrigin={crossOrigin ? null : "anonymous"}
                                                src={profile}
                                                style={{
                                                    width: 40,
                                                    height: 40,
                                                    borderRadius: 50,
                                                    marginRight: 10
                                                }}
                                            />
                                    }
                                    <span
                                        style={{
                                            width: "100%",
                                            overflowX: 'hidden',
                                            fontWeight: 'bold',
                                            fontSize: 20,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignSelf: 'flex-start'
                                        }}
                                    >
                                        {nickname}
                                    </span>
                                </div>
                            )
                        }else if(isThumbnail){
                            info = (
                                <Image
                                    src={info}
                                    style={{
                                        width: 25,
                                        height: 40,
                                    }}
                                />
                            )
                        }else if(isDescription) {
                            info = album.description == null ?
                                <Tag>无描述</Tag>
                                :
                                <Button
                                    onClick={() => this._showDescription(album.description)}
                                >
                                    查看
                                </Button>
                        }else if(isTitle){
                            if(info != null && info.length > 11){
                                info = info.substring(0, 10) + "...";
                            }
                        }else if(isType){
                            const albumType = album.type;
                            if(albumType != null){
                                const typeName = albumType.name;
                                // const typeStatus = albumType.status;
                                info = (
                                    <Tag>{typeName}</Tag>
                                )
                            }else {
                                info = (
                                    <Tag>无类型</Tag>
                                )
                            }
                        }

                        return (
                            <div
                                key={cIndex}
                                style={{flex: columnItem.flex, height:  50, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30}}>
                                <span>{info}</span>
                            </div>
                        )
                    })
                }
            </div>
        )
    };

    /**
     * 查看描述
     * @param description
     * @private
     */
    _showDescription = (description) => {
        this.setState({descriptionText: description});
    };

    /**
     * 关闭描述
     * @private
     */
    _hideDescription = () => {
        this.setState({descriptionText: null});
    };

    /**
     * 移到Banner列表
     * @param albumId
     * @private
     */
    _transferToRecBanner = (albumId, isBanner) => {
        const {recList, loadingTransfer} = this.state;
        let countBanners = 0;
        recList.map((album, index) => {
            if(album.weight === REC_ALBUM_WEIGHT.BANNER_ALBUM){
                countBanners++;
            }
        });
        if((!isBanner && countBanners < 5) || isBanner){
            if(!loadingTransfer) {
                this.setState({loadingTransfer: true});
                const url = "/apiB/album/banner";
                const token = this.userStore.user.token;
                const formData = new FormData();
                formData.append("albumIdsStr", albumId.toString());

                let isSuccess = false;
                fetch(url, {
                    method: isBanner ? 'DELETE' : 'POST',
                    headers: {
                        'Authorization': token,
                        'Accept': 'application/json'
                    },
                    body: formData,
                })
                    .then((response) => {
                        isSuccess = response.ok;
                        return response.json();
                    })
                    .then((json) => {
                       if(isSuccess){
                           recList.map((album, index) => {
                              if(album.id === albumId){
                                  recList[index].weight = isBanner ? REC_ALBUM_WEIGHT.OP_ALBUM : REC_ALBUM_WEIGHT.BANNER_ALBUM;
                              }
                           });
                           message.success("操作成功");
                       }else {
                           message.error("操作失败");
                       }
                        this.setState({loadingTransfer: false});
                    })
                    .catch((err) => {
                        this.setState({loadingTransfer: false});
                        message.error("操作失败");
                    });
            }
        }else {
            message.error("超过5条")
        }
    };

    /**
     * 批量移到推荐列表
     * @private
     */
    _transferToRec = () => {
        const {recList, unRecList, checkUnRecIds, loadingTransfer} = this.state;
        if(!loadingTransfer){
            this.setState({loadingTransfer: true});

            const url = "/apiB/album/recommend";
            const token = this.userStore.user.token;
            const formData = new FormData();

            checkUnRecIds.map((albumId, index) => {
                formData.append("albumIdsStr", albumId.toString());
            });

            let isSuccess = false;
            fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': token,
                    'Accept': 'application/json'
                },
                body: formData,
            })
                .then((response) => {
                    isSuccess = response.ok;
                    return response.json();
                })
                .then((json) => {
                    if(isSuccess){
                        let newRecList = recList.length > 0 ? [].concat(recList) : [];
                        let newUnRecList = [];
                        let newUnRecIds = [];
                        let newRecIds = [];

                        if(checkUnRecIds.length > 0){
                            unRecList.map((type, index) => {
                                const arrIndex = checkUnRecIds.indexOf(parseInt(type.id));
                                if(arrIndex > -1){
                                    type.weight = REC_ALBUM_WEIGHT.OP_ALBUM;
                                    newRecList.unshift(type);
                                }else {
                                    newUnRecList.push(type);
                                }
                            });
                        }
                        this.setState({
                            recList: newRecList,
                            unRecList: newUnRecList,
                            checkUnRecIds: newUnRecIds,
                            checkRecIds: newRecIds
                        });
                        message.success("推荐成功");
                    }else {
                        message.error("推荐失败");
                    }
                    this.setState({loadingTransfer: false});
                })
                .catch((err) => {
                    message.error("推荐失败");
                    this.setState({loadingTransfer: false});
                });
        }
    };

    /**
     * 批量移出推荐列表
     * @private
     */
    _transferToUnRec = () => {
        const {recList, unRecList, checkRecIds, loadingTransfer} = this.state;
        if(!loadingTransfer) {
            this.setState({loadingTransfer: true});

            const url = "/apiB/album/recommend";
            const token = this.userStore.user.token;
            const formData = new FormData();

            checkRecIds.map((albumId, index) => {
                formData.append("albumIdsStr", albumId.toString());
            });

            let isSuccess = false;
            fetch(url, {
                method: 'DELETE',
                headers: {
                    'Authorization': token,
                    'Accept': 'application/json'
                },
                body: formData,
            })
                .then((response) => {
                    isSuccess = response.ok;
                    return response.json();
                })
                .then((json) => {
                    if(isSuccess){
                        let newRecList = [];
                        let newUnRecList = unRecList.length > 0 ? [].concat(unRecList) : [];
                        let newUnRecIds = [];
                        let newRecIds = [];

                        if(checkRecIds.length > 0){
                            recList.map((type, index) => {
                                const arrIndex = checkRecIds.indexOf(parseInt(type.id));
                                if(arrIndex > -1){
                                    type.weight = REC_ALBUM_WEIGHT.LR_ALBUM;
                                    newUnRecList.unshift(type);
                                }else {
                                    newRecList.push(type);
                                }
                            });
                        }
                        this.setState({
                            recList: newRecList,
                            unRecList: newUnRecList,
                            checkUnRecIds: newUnRecIds,
                            checkRecIds: newRecIds
                        });
                        message.success("取消推荐成功");
                    }else {
                        message.error("取消推荐失败");
                    }
                    this.setState({loadingTransfer: false});
                })
                .catch((err) => {
                    message.error("取消推荐失败");
                    this.setState({loadingTransfer: false});
                });
        }
    };

    /**
     *
     * @param checked
     * @param type
     * @param albumId
     * @private
     */
    _onCheck = (checked, type, albumId) => {
        if(albumId != null) {
            switch (type) {
                case 'unRec': {
                    const {checkUnRecIds} = this.state;
                    const arrIndex = checkUnRecIds.indexOf(parseInt(albumId));
                    if (checked) {
                        if (arrIndex === -1) {
                            let newArr = checkUnRecIds.length > 0 ? [].concat(checkUnRecIds) : [parseInt(albumId)];
                            if (checkUnRecIds.length > 0) {
                                newArr.push(parseInt(albumId));
                            }

                            this.setState({checkUnRecIds: newArr});
                        }
                    } else {
                        if (arrIndex > -1) {
                            let newArr = [].concat(checkUnRecIds);
                            newArr.splice(arrIndex, 1);

                            this.setState({checkUnRecIds: newArr});
                        }
                    }
                }
                    break;
                case 'rec': {
                    const {checkRecIds} = this.state;
                    const arrIndex = checkRecIds.indexOf(parseInt(albumId));
                    if (checked) {
                        if (arrIndex === -1) {
                            let newArr = checkRecIds.length > 0 ? [].concat(checkRecIds) : [parseInt(albumId)];
                            if (checkRecIds.length > 0) {
                                newArr.push(parseInt(albumId));
                            }

                            this.setState({checkRecIds: newArr});
                        }
                    } else {
                        if (arrIndex > -1) {
                            let newArr = [].concat(checkRecIds);
                            newArr.splice(arrIndex, 1);

                            this.setState({checkRecIds: newArr});
                        }
                    }
                }
                    break;
                default:
                    break;
            }
        }else {
            switch (type) {
                case 'unRec': {
                    const {unRecList} = this.state;
                    if(unRecList.length > 0) {
                        let newArr = [];
                        if (checked) {
                            unRecList.map((video, index) => {
                                newArr.push(parseInt(video.id));
                            })
                        }
                        this.setState({checkUnRecIds: newArr});
                    }
                }break;
                case 'rec': {
                    const {recList} = this.state;
                    if(recList.length > 0){
                        let newArr = [];
                        if(checked){
                            recList.map((video, index) => {
                                newArr.push(parseInt(video.id));
                            });
                        }
                        this.setState({checkRecIds: newArr});
                    }
                }break;
                default: break;
            }
        }
    };

    /**
     * 分页拉取公开未推荐专辑和已推荐专辑列表
     * @param fetchType
     * @param listType
     * @private
     */
    _fetchAlbums = (fetchType, listType) => {
        const {loadingRec, loadingUnRec, recList, unRecList} = this.state;
        const isRec = listType === 'rec';
        if(isRec ? !loadingRec : !loadingUnRec) {
            this._setLoading(listType, true);

            const token = this.userStore.user.token;
            const sourceList = isRec ? recList : unRecList;
            const listSize = sourceList.length;
            const isRefresh = fetchType === "refresh";
            const lastItemId = !isRefresh && listSize > 0 ? sourceList[listSize - 1].id.toString() : "-1";
            const url = `/apiB/album/list/${isRec ? 'rec' : 'unRec'}/${lastItemId}`;

            let isSuccess = false;
            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
                .then((response) => {
                    isSuccess = response.ok;
                    return response.json();
                })
                .then((json) => {
                    if(isSuccess){
                        if(json.length === 0){
                            if(isRec){
                                this.setState({isLastRecPage: true});
                            }else {
                                this.setState({isLastUnRecPage: true});
                            }
                        }else {
                            let isLastPage = false;
                            let newList = isRefresh ? [] : sourceList.length > 0 ? [].concat(sourceList) : [];

                            json.map((album, index) => {
                                album['key'] = index;
                                newList.push(album);
                            });
                            if (json.length < recVideoPageSize) {
                                isLastPage = true;
                            }

                            if(isRec){
                                this.setState({
                                    recList: newList,
                                    isLastRecPage: isLastPage
                                });
                            }else {
                                this.setState({
                                    unRecList: newList,
                                    isLastUnRecPage: isLastPage
                                });
                            }
                        }
                    }
                    this._setLoading(listType, false);
                })
                .catch((err) => {
                    this._setLoading(listType, false);
                });
        }
    };

    /**
     * 更新loading
     * @param listType
     * @param value
     * @private
     */
    _setLoading = (listType, value) => {
        if(listType === 'rec'){
            this.setState({loadingRec: value});
        }else {
            this.setState({loadingUnRec: value});
        }
    }

}

export default RecommendAlbum;
