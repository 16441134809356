import { observable, action } from "mobx";

class WindowStore {
    @observable width;
    @observable height;
    @observable col;

    constructor(){
        this.width = window.innerWidth;
        this.height = window.innerHeight;
    }

    @action
    setWidth = (width) => {
        this.width = width;
    };

    @action
    setHeight = (height) => {
        this.height = height;
    };

    @action
    setCol = (col) => {
        this.col = col;
    }

}

const windowStore = new WindowStore();

export {windowStore};