import {inject, observer} from "mobx-react";
import React from "react";
import {Card, Cascader, Col, Form, DatePicker, Input, Row, Select, Spin, Typography, Button, message} from "antd";
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from "moment";

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

@inject("windowStore", "userStore")
@observer
class Bulletin extends React.Component {

    constructor(props) {
        super(props);
        this.windowStore = this.props.windowStore;
        this.userStore = this.props.userStore;
        this.state={
            startTime: null,
            endTime: null,
            remark: null,

            isModalVisible: false,
        }

    }

    componentDidMount() {

    }

    render() {
        const {isModalVisible, startOption, endOption} = this.state;
        const smallThenL = false;
        const {width, height, col} = this.windowStore;

        return (
            <div style={{width: '100%', height: height, backgroundColor: '#e4e4e4',}}>
                <div id={'loadingModal'} style={{display: isModalVisible ? 'flex' : 'none'}}>
                    <div style={{width: 500, height: 300, borderRadius: 10, justifyContent: 'center', alignItems: 'center', marginBottom: 200}}>
                        <Spin style={{marginTop: 130}}/>
                        <Typography style={{marginTop: 20, fontWeight: 'bold'}}>发布中...</Typography>
                    </div>
                </div>
                <div style={{width: '100%', height: "100%",  backgroundColor: '#e4e4e4'}}>
                    <Row style={{width: "100%", height: '90%', alignItems: 'center', justifyContent: 'space-between',  backgroundColor: '#e4e4e4',}}>
                        <Col
                            xs={{span: 24, offset: 0}}
                            s={{span: 20, offset: 2}}
                            m={{span: 12, offset: 6}}
                            lg={{span: 8, offset: 8}}
                            xl={{span: 6, offset: 9}}
                            style={{padding: 15,  backgroundColor: '#e4e4e4',}}
                        >
                            <Card title="发布维护公告" bordered={false} style={{borderRadius: 10,boxShadow: "5px 5px 55px rgba(0,0,0,0.1)"}}>
                                <Form
                                    {...layout}
                                    name="basic"
                                    initialValues={{ remember: true }}
                                    onFinish={() => {this._submitBulletin()}}
                                    onFinishFailed={(err) => {}}
                                >
                                    <Form.Item
                                        label="维护时间段"
                                        name="bulletinDate"
                                        rules={[{ required: true, message: '请选择日期范围' }]}
                                    >
                                        <RangePicker
                                            locale={locale}
                                            showTime={{ format: 'HH:mm' }}
                                            format="YYYY-MM-DD HH:mm"
                                            onChange={(value, dateString) => {this._resetDate(value)}}
                                            onOk={(value) => {this._setDate(value)}}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="公告类型"
                                        name="remark"
                                        rules={[{ required: true, message: '请选择公告类型' }]}
                                    >
                                        <Select
                                            placeholder="请选择公告类型"
                                            onChange={(value) => this._setRemark(value)}
                                            allowClear
                                        >
                                            <Option value="Planned maintenance">计划维护</Option>
                                            <Option value="Temporary maintenance">临时维护</Option>
                                            <Option value="Emergency maintenance">紧急维护</Option>
                                            <Option value="Service termination">停服</Option>
                                        </Select>
                                    </Form.Item>

                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        size={"large"}
                                        style={{paddingLeft: 50, paddingRight: 50}}
                                    >
                                        发布
                                    </Button>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }

    /**
     * 提交公告
     * @private
     */
    _submitBulletin = () => {
        this.setState({isModalVisible: true});

        const token = this.userStore.user.token;
        const {startTime, endTime, remark} = this.state;
        const bulletin = new FormData();
        bulletin.append("startTime", startTime);
        bulletin.append("endTime", endTime);
        bulletin.append("remark", remark);

        let isSuccess = false;
        fetch("/apiB/bulletin", {
            method: 'POST',
            headers: {
                'Authorization': token,
                'Accept': 'application/json'
            },
            body: bulletin
        })
            .then((response) => {
                if (response.ok === true) {
                    isSuccess = response.ok;
                }
                return response.json();
            })
            .then((json) => {
                if(isSuccess){
                    message.success("发布成功");
                }else {
                    message.error("发布失败");
                }

                this.setState({isModalVisible: false});
            })
            .catch((err) => {
                message.error("发布失败");
                this.setState({isModalVisible: false});
            });
    };

    /**
     * 备注
     * @param remark
     * @private
     */
    _setRemark = (remark) => {
        this.setState({remark: remark});
    };

    /**
     * 重置时间范围
     * @param value
     * @private
     */
    _resetDate = (value) => {
        if(value === null){
            this.setState({
                startTime: null,
                endTime: null
            });
        }
    };

    /**
     * 设置时间范围
     * @param value
     * @private
     */
    _setDate = (value) => {
        if(value[1] !== null){
            let startTime = moment(value[0]).format("yyyy-MM-DD HH:mm:ss");
            let endTime = moment(value[1]).format("yyyy-MM-DD HH:mm:ss");
            this.setState({
                startTime: startTime,
                endTime: endTime
            });
        }
    }


}

export default Bulletin;
