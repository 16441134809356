import React from 'react';
import {Button, Row, Col, Typography, Tag, Input, Avatar} from 'antd';
import Icon from '@ant-design/icons';
import './Login.css';
import {observer, inject} from "mobx-react";
import {action, observable} from "mobx";
import * as Cookie from "../../util/Cookie";
import * as LoginUtil from "../../util/LoginUtil";
import * as StringUtil from "../../util/StringUtil";
import Global from '../../Global';
import Toast from "antd-mobile/es/toast/index";

@inject("windowStore", "navBarStore", "userStore")
@observer
class Login extends React.Component {

    @observable
    user;

    @observable
    passwordInputType;

    @observable
    disabled;

    constructor(props) {
        super(props);
        this.windowStore = this.props.windowStore;
        this.navBarStore = this.props.navBarStore;
        this.userStore = this.props.userStore;

        this.state = {};

        this.user = {
            username: null,
            password: null,
        };
        this.passwordInputType = "password";
        this.disabled = true;
    }

    componentWillMount() {
        if (typeof this.props.location.state !== "undefined") {
            this.user.username = this.props.location.state.username;
            this.user.password = this.props.location.state.password;
        }
        setTimeout(() => {
            this.disabled = false;
        }, 100);
    }

    @action
    setValue = (e, index) => {
        const value = e.target.value;
        switch (index) {
            case 0:
                this.user.username = value;
                break;
            case 1:
                this.user.password = value;
                break;
            default:
                break;
        }
    };

    @action
    changePasswordInputType = (forwardType) => {
        if (forwardType === "password") {
            this.passwordInputType = "";
        } else {
            this.passwordInputType = "password";
        }
    };

    /**
     * 登录
     * @private
     */
    _login = () => {
        let isSuccess;
        const {username, password} = this.user;
        const formData = new FormData();
        formData.append("username", username);
        formData.append("password", password);

        if(username === null || password === null){
            Toast.fail("帐号密码不能为空", 1.5);
           return;
        }

        const url = "/apiB/admin/login";
        fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': null,
                'Accept': 'application/json'
            },
            body: formData,
        }).then((response) => {
            if (response.ok === true) {
                isSuccess = response.ok;
            }
            return response.json();
        }).then((json) => {
            if (isSuccess) {
                this.user.username = "";
                this.user.password = "";

                const user = json;
                if (user != null && user.id !== null) {
                    let date = new Date();
                    let usefulLife = 180;
                    date.setTime(date.getTime() + usefulLife * 24 * 3600 * 1000);
                    Cookie.docCookies.setItem("token", user.token, date);

                    Global.user = user;
                    Global.isLogin = true;

                    this.userStore.setUser(user);
                    this.navBarStore.addTab({name: "控制台", icon: "control"});
                    this.navBarStore.changeTab({name: "退出", icon: "logout"});
                    Toast.success('登录成功', 1.5);
                    setTimeout(() => {
                        this.props.history.replace("/console/index");
                    }, 200);
                }

                // LoginUtil.autoLogin(token).then(user => {
                //     if (user != null && user.id !== null) {
                //         Global.user = user;
                //         Global.isLogin = true;
                //         Cookie.docCookies.setItem("userType", user.type, date);
                //         this.userStore.setUser(user);
                //         this.navBarStore.addTab({name: "控制台", icon: "control"});
                //         this.navBarStore.changeTab({name: "退出", icon: "logout"});
                //         Toast.success('登录成功', 1.5);
                //         setTimeout(() => {
                //             this.props.history.replace("/console");
                //         }, 200);
                //     }
                // });

            } else {
                Toast.fail('帐号不存在或密码有误', 1.5);
            }
            this.setState({
                disabled: false
            });

        }).catch((error) => {
            Toast.fail(error.toString(), 1.5);
        });
    };

    _goToSignUp = () => {
        this.props.history.push("/signUp");
    };


    render() {
        const {height, width, col} = this.windowStore;
        const {username, password} = this.user;
        const isXs = col === "XS";
        const smallThenL = col === "M" || col === "L";


        return (
            <div className={"login-body"} style={{minHeight: height}}>
                <Row style={{width: "100%", marginTop: 0.15 * height}}>
                    <Col
                        xs={{span: 20, offset: 2}}
                        s={{span: 16, offset: 4}}
                        m={{span: 12, offset: 6}}
                        lg={{span: 8, offset: 8}}
                        xl={{span: smallThenL ? 6 : 4, offset: smallThenL ? 9 : 10}}
                    >
                        <div className={"input-card"}
                             style={{
                                 boxShadow: isXs ? null : "0px 0px 25px #e1e1e1",
                                 padding: isXs ? 0 : "50pt",
                                 borderRadius: isXs ? 0 : "10pt"
                             }}
                        >
                            <div id={"step-1"} style={{padding: "10px", paddingTop: 0}}>
                                <Avatar size={64} icon="user"/>
                                <div className={"flex-row"} style={{marginTop: 20}}>
                                    <Input
                                        className={"input"}
                                        size={"large"}
                                        value={username}
                                        disabled={this.disabled}
                                        placeholder={"请输入帐号"}
                                        onChange={(e) => this.setValue(e, 0)}
                                        prefix={<Icon type={"mobile"} style={{color: '#c0c0c0'}}/>}
                                        suffix={
                                            <Icon
                                                type={username === null || username.length === 0 ? "info-circle" : "close"}
                                                style={{color: '#c0c0c0'}}
                                                onClick={() => this.user.username = null}
                                            />
                                        }
                                    />
                                </div>
                                <div className={"flex-row"}>
                                    <Input
                                        type={this.passwordInputType}
                                        className={"input"}
                                        size={"large"}
                                        value={password}
                                        placeholder={"请输入密码"}
                                        onChange={(e) => this.setValue(e, 1)}
                                        prefix={<Icon type={"lock"} style={{color: '#c0c0c0'}}/>}
                                        suffix={
                                            <Icon
                                                type={this.passwordInputType === "password" ? "eye" : "eye-invisible"}
                                                style={{color: '#c0c0c0'}}
                                                onClick={() => this.changePasswordInputType(this.passwordInputType)}
                                            />
                                        }
                                    />
                                </div>
                                <Button
                                    block
                                    onClick={() => this._login()}
                                    type={"primary"}
                                    size={"large"}
                                    style={{marginTop: 40, borderRadius: 100}}
                                >
                                    登录
                                </Button>
                                {/*<Button*/}
                                {/*    block*/}
                                {/*    onClick={() => this._goToSignUp()}*/}
                                {/*    type={"default"}*/}
                                {/*    size={"large"}*/}
                                {/*    style={{marginTop: 20, borderRadius: 100}}*/}
                                {/*>*/}
                                {/*    注册*/}
                                {/*</Button>*/}
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default Login;
