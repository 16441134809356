import React from 'react';
import '../../App.css';

import {
    Form,
    Input,
    Button,
    Checkbox,
    Row,
    Col,
    Upload,
    Card,
    message,
    Radio,
    Typography, Select, Modal, Spin
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import {inject, observer} from "mobx-react";

const { TextArea } = Input;
const { Option } = Select;

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

@inject("windowStore", "userStore")
@observer
class UploadApk extends React.Component{

    constructor(props) {
        super(props);
        this.windowStore = this.props.windowStore;
        this.userStore = this.props.userStore;
        this.state={
            versionCode: 1,
            versionName: "0.0.1",
            remark: null,
            apkName: null,
            necessaryUpdate: true,  //默认强更
            channel: null,
            showFileEmptyErr: false,

            isModalVisible: false
        }
    }

    render(){
        let {isModalVisible, versionCode, versionName, remark, apkName, showFileEmptyErr, channel} = this.state;
        const smallThenL = false;
        const {width, height, col} = this.windowStore;

        return (
            <div style={{width: '100%', height: height, backgroundColor: '#e4e4e4',}}>
                <div id={'loadingModal'} style={{display: isModalVisible ? 'flex' : 'none'}}>
                    <div style={{width: 500, height: 300, borderRadius: 10, justifyContent: 'center', alignItems: 'center', marginBottom: 200}}>
                        <Spin style={{marginTop: 130}}/>
                        <Typography style={{marginTop: 20, fontWeight: 'bold'}}>上传中...</Typography>
                    </div>
                </div>
                <div style={{width: '100%', height: "100%",  backgroundColor: '#e4e4e4'}}>
                    <Row style={{width: "100%", height: '90%', alignItems: 'center', justifyContent: 'space-between',  backgroundColor: '#e4e4e4',}}>
                        <Col
                            xs={{span: 24, offset: 0}}
                            s={{span: 20, offset: 2}}
                            m={{span: 12, offset: 6}}
                            lg={{span: 8, offset: 8}}
                            xl={{span: smallThenL ? 6 : 4, offset: smallThenL ? 9 : 10}}
                            style={{padding: 15,  backgroundColor: '#e4e4e4',}}
                        >
                            <Card title="发布新版APK" bordered={false} style={{borderRadius: 10,boxShadow: "5px 5px 55px rgba(0,0,0,0.1)"}}>
                                <Form
                                    {...layout}
                                    name="basic"
                                    initialValues={{ remember: true }}
                                    onFinish={() => this._checkUploadFile()}
                                    onFinishFailed={(err) => this._checkUploadFileEmpty()}
                                >
                                    <Form.Item
                                        label="版本号"
                                        name="versionCode"
                                        rules={
                                            [
                                                { required: true, message: '请输入正确的版本号', type: 'number', transform(value) {

                                                        if(value){

                                                            return Number(value);//将输入框当中的字符串转换成数字类型

                                                        }

                                                    },
                                                }
                                            ]
                                        }
                                    >
                                        <Input
                                            placeholder={"请输入版本号(数字)"}
                                            onChange={(e) => this._setInput(0, e.target.value)}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="版本名"
                                        name="versionName"
                                        rules={[{ required: true, message: '请输入正确的版本名' }]}
                                    >
                                        <Input
                                            placeholder={"请输入版本名(x.x.x)"}
                                            onChange={(e) => this._setInput(1, e.target.value)}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={"强制性"}
                                        name={"necessaryUpdate"}
                                    >
                                        <Radio.Group
                                            onChange={(e) => this._setInput(2, e.target.value)}
                                            defaultValue="immediately"
                                        >
                                            <Radio.Button value="immediately">强制更新</Radio.Button>
                                            <Radio.Button value="later">普通更新</Radio.Button>
                                        </Radio.Group>
                                    </Form.Item>
                                    <Form.Item
                                        label="渠道"
                                        name="channel"
                                        rules={[{ required: true, message: '请选择渠道' }]}
                                    >
                                        <Select
                                            placeholder="请选择渠道"
                                            onChange={(value) => this._setInput(3, value)}
                                            allowClear
                                        >
                                            <Option value="google">Google Play</Option>
                                            <Option value="facebook">Facebook</Option>
                                            {/*<Option value="xiaomi">小米应用商店</Option>*/}
                                            {/*<Option value="vivo">VIVO应用商店</Option>*/}
                                            {/*<Option value="oppo">OPPO应用商店</Option>*/}
                                            {/*<Option value="huawei">华为应用商店</Option>*/}
                                            <Option value="test1">测试渠道一</Option>
                                            <Option value="test2">测试渠道二</Option>
                                            <Option value="other">其他渠道</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label="更新内容"
                                        name="remark"
                                        rules={[{ required: true, message: '请输入版本更新内容' }]}
                                    >
                                        <TextArea
                                            rows={5}
                                            placeholder={"请输入版本更新内容"}
                                            onChafnge={(e) => this._setInput(4, e.target.value)}
                                        />
                                    </Form.Item>


                                    <Form.Item
                                        label="* APK文件"
                                        name="apkFileName"
                                        rules={[{required: false, message: '请选择上传的文件'}]}
                                    >
                                        <div style={{width: "100%", height: "100%"}}>
                                            <div style={{width: "100%", height: "100%", flexDirection: 'row'}}>
                                                <Button
                                                    type={showFileEmptyErr ? "danger" : "default"}
                                                    style={{width: "80%" , backgroundColor: '#fff', }}
                                                    onClick={() => this.fileInput.click()}
                                                    icon={<Typography style={{color: showFileEmptyErr ? '#ff4d4f' : '#000'}}>{apkName == null ? "点击上传" : apkName}</Typography>}
                                                >
                                                </Button>
                                                <Button
                                                    style={{width: "20%"}}
                                                    onClick={() => apkName == null ? this.fileInput.click() : this._clearFileInput()}
                                                    icon={apkName == null ? <UploadOutlined/> : <Typography>X</Typography>}
                                                >

                                                </Button>
                                            </div>
                                            <Typography style={{color: '#ff4d4f'}}>{showFileEmptyErr ? '请选择上传文件' : ''}</Typography>
                                        </div>
                                    </Form.Item>

                                    <input
                                        ref={(ref) => {this.fileInput = ref}}
                                        type={"file"}
                                        onChange={() => this._setInput(5, this._getFileName())}
                                        style={{width: 0, height: 0, display: 'none'}}
                                    />

                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        size={"large"}
                                        style={{paddingLeft: 50, paddingRight: 50}}
                                    >
                                        发布
                                    </Button>
                                </Form>
                            </Card>
                        </Col>

                    </Row>

                </div>
            </div>
        )
    }

    /**
     * 除了file还有其他没校验过的选项
     * @private
     */
    _checkUploadFileEmpty = () => {
        if(typeof this.fileInput !== "undefined"){
            if(this.fileInput.files.length === 0 || this.state.apkName == null){
                this.setState({showFileEmptyErr: true});
            }else {
                this.setState({showFileEmptyErr: false});
            }
        }else {
            this.setState({showFileEmptyErr: true});
        }
    };

    /**
     * 除了file其他选项都校验过了
     * @returns {Promise<void>}
     * @private
     */
    _checkUploadFile = async () => {
        const {apkName} = this.state;
        if(typeof this.fileInput !== "undefined" && this.fileInput.files.length > 0 && apkName != null){
            if(apkName.indexOf(".apk") > -1) {
                this._uploadToAliOSS();
            }else {
                message.warn("请选择正确的apk文件");
            }
        }else {
            this.setState({showFileEmptyErr: true});
        }

    };

    /**
     * 获取file的文件名
     * @returns {null}
     * @private
     */
    _getFileName = () => {
        let filename = null;
        if(typeof this.fileInput !== "undefined" && this.fileInput.files.length > 0){
            filename = this.fileInput.files[0].name;
        }

        return filename;
    };

    /**
     * 清空file的input
     * @private
     */
    _clearFileInput = () => {
        if(typeof this.fileInput !== "undefined"){
            this.fileInput.value = '';
            this._setInput(5, null);
            this.setState({showFileEmptyErr: true});
        }
    };

    /**
     * 输入框赋值
     * @param type
     * @param value
     * @private
     */
    _setInput = (type, value) => {
        switch (type) {
            case 0: this.setState({versionCode: value});break;
            case 1: this.setState({versionName: value});break;
            case 2: this.setState({necessaryUpdate: value === "immediately"});break;
            case 3: this.setState({channel: value});break;
            case 4: this.setState({remark: value});break;
            case 5: {
                this.setState({apkName: value});
                if(value != null){
                    this.setState({showFileEmptyErr: false});
                }
            }break;

            default: break;
        }
    };

    /**
     * 提交给服务端，上传apk文件到阿里云OSS
     * @returns {Promise<void>}
     * @private
     */
    _uploadToAliOSS = async() => {
        this.setState({isModalVisible: true});
        const token = this.userStore.user.token;
        const versionData = {
            versionCode: this.state.versionCode,
            versionName: this.state.versionName,
            remark: this.state.remark,
            necessaryUpdate: this.state.necessaryUpdate,
            channel: this.state.channel
        };
        const apkFile = this.fileInput.files[0];
        const versionInfo = new FormData();
        versionInfo.append("versionInfo", JSON.stringify(versionData));
        versionInfo.append("apkFile", apkFile);

        let isSuccess = false;
        fetch("/apiB/version/upload", {
            method: 'POST',
            headers: {
                'Authorization': token,
                'Accept': 'application/json'
            },
            body: versionInfo
        })
            .then((response) => {
                if (response.ok === true) {
                    isSuccess = response.ok;
                }
                return response.json();
            })
            .then((json) => {
                if(isSuccess && typeof json.msg !== "undefined" && json.msg === "success"){
                    message.success("上传成功");
                    this.setState({
                        isModalVisible: false
                    })
                }else {
                    const msg = typeof json.msg !== "undefined" ? `: ${json.msg}` : "";
                    message.error("上传失败" + msg);
                    this.setState({isModalVisible: false})
                }
            })
            .catch((err) => {
                message.error("上传失败");
                this.setState({isModalVisible: false})
            })
    }

}

export default UploadApk;
