import React from 'react';
import * as echarts from 'echarts';
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/toolbox';
import 'echarts/lib/component/markPoint';
import 'echarts/lib/component/markLine';

import {inject, observer} from "mobx-react";
import {Button, Card, Col, Image, message, Typography} from 'antd';

import growth from '../../imgs/chart-growth.png';
import users from '../../imgs/users.png';
import hot from '../../imgs/hot.png';
import install from '../../imgs/install.png';

@inject("windowStore", "userStore", "navBarStore")
@observer
class Dashboard extends React.Component{

    constructor(props) {
        super(props);
        this.windowStore = this.props.windowStore;
        this.userStore = this.props.userStore;
        this.navBarStore = this.props.navBarStore;
        this.state={
            countAll: 0,
            countActive: 0,
            countIncrease: 0,
            countInstall: 0,

            loadingDashboard: false,

            increaseRangeType: 'week',
            activeRangeType: 'week'
        }
    }

    componentDidMount() {
        this._initCountAll();
        this._initChartIncrease();
        this._initChartActive();
    }

    render(){
        const {countAll, countActive, countIncrease, countInstall, increaseRangeType, activeRangeType} = this.state;
        const navBarHeight = this.navBarStore.height;
        const {width, height, col} = this.windowStore;
        const cols = ["XS", "S", "M", "L", "XL"];
        const colIndex = cols.indexOf(col);
        const smallThenL = colIndex < 4;

        return (
            <div style={{width: "100%", display: 'flex', flexDirection: 'row'}}>
                <div style={{width: smallThenL ? 60 : 290, height: 100}}/>
                <div style={{width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: 20}}>
                    <div style={{width: width - 40 - (smallThenL ? 60 : 270), display: 'flex', flexDirection: 'row'}}>
                        <div style={{flex: 1, height: 150, borderRadius: 10, display: 'flex', backgroundColor: '#0e8feb'}}>
                            <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',}}>
                                <Typography.Text style={{color: '#fff', fontSize: 50, fontWeight: 'bold'}}>{countAll}</Typography.Text>
                                <Typography.Text style={{color: '#fff', fontSize: 20}}>用户总量</Typography.Text>
                            </div>
                            <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
                                <Image src={users} style={{width: 80, height: 80}}/>
                            </div>
                        </div>
                        <div style={{flex: 1, height: 150, borderRadius: 10, display: 'flex', backgroundColor: '#01a755', marginLeft: 20}}>
                            <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',}}>
                                <Typography.Text style={{color: '#fff', fontSize: 50, fontWeight: 'bold'}}>{countIncrease}</Typography.Text>
                                <Typography.Text style={{color: '#fff', fontSize: 20}}>今日新增</Typography.Text>
                            </div>
                            <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
                                <Image src={growth} style={{width: 90, height: 90}}/>
                            </div>
                        </div>
                        <div style={{flex: 1, height: 150, borderRadius: 10, display: 'flex', backgroundColor: '#f46902', marginLeft: 20}}>
                            <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',}}>
                                <Typography.Text style={{color: '#fff', fontSize: 50, fontWeight: 'bold'}}>{countActive}</Typography.Text>
                                <Typography.Text style={{color: '#fff', fontSize: 20}}>日活量</Typography.Text>
                            </div>
                            <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
                                <Image src={hot} style={{width: 90, height: 90}}/>
                            </div>
                        </div>
                        <div style={{flex: 1, height: 150, borderRadius: 10, display: 'flex', backgroundColor: '#e6423c', marginLeft: 20}}>
                            <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',}}>
                                <Typography.Text style={{color: '#fff', fontSize: 50, fontWeight: 'bold'}}>{countInstall}</Typography.Text>
                                <Typography.Text style={{color: '#fff', fontSize: 20}}>下载量</Typography.Text>
                            </div>
                            <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
                                <Image src={install} style={{width: 90, height: 90}}/>
                            </div>
                        </div>
                    </div>
                    <div style={{width: width - 40 - (smallThenL ? 60 : 270), display: 'flex', flexDirection: 'row', marginTop: 20}}>
                        <Card
                            title="用户增长图表"
                            extra={
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    {/*<Button*/}
                                    {/*    type={increaseRangeType === 'year' ? "primary" : 'default'}*/}
                                    {/*    onClick={() => {this._setIncreaseRangeType('year')}}*/}
                                    {/*>年</Button>*/}
                                    <Button
                                        type={increaseRangeType === 'month' ? "primary" : 'default'}
                                        onClick={() => {this._setIncreaseRangeType('month')}}
                                        style={{marginLeft: 5, marginRight: 5}}
                                    >月</Button>
                                    <Button
                                        type={increaseRangeType === 'week' ? "primary" : 'default'}
                                        onClick={() => {this._setIncreaseRangeType('week')}}
                                    >周</Button>
                                </div>
                            }
                            bordered={false}
                            headStyle={{fontWeight: 'bold'}}
                            style={{flex: 1, borderRadius: 10, backgroundColor: '#fff', textAlign: 'left'}}
                        >
                            <div
                                id={"chart-increase"}
                                style={{flex: 1, height: 460, borderRadius: 10, backgroundColor: '#fff', textAlign: 'left', padding: 20}}
                            >

                            </div>
                        </Card>
                        <Card
                            title="日活增长图表"
                            extra={
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    {/*<Button*/}
                                    {/*    type={activeRangeType === 'year' ? "primary" : 'default'}*/}
                                    {/*    onClick={() => {this._setActiveRangeType('year')}}*/}
                                    {/*>年</Button>*/}
                                    <Button
                                        type={activeRangeType === 'month' ? "primary" : 'default'}
                                        onClick={() => {this._setActiveRangeType('month')}}
                                        style={{marginLeft: 5, marginRight: 5}}
                                    >月</Button>
                                    <Button
                                        type={activeRangeType === 'week' ? "primary" : 'default'}
                                        onClick={() => {this._setActiveRangeType('week')}}
                                    >周</Button>
                                </div>
                            }
                            bordered={false}
                            headStyle={{fontWeight: 'bold'}}
                            style={{flex: 1, borderRadius: 10, backgroundColor: '#fff', textAlign: 'left', marginLeft: 20}}
                        >
                            <div
                                id={"chart-active"}
                                style={{flex: 1, height: 460, borderRadius: 10, backgroundColor: '#fff', textAlign: 'left', padding: 20}}
                            >

                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }

    /**
     * 新增用户图表范围类型
     * @param rangeType 年、月、星期
     * @private
     */
    _setIncreaseRangeType = (rangeType) => {
        this.setState({increaseRangeType: rangeType}, () => {
            this._initChartIncrease();
        });
    };

    /**
     * 日活用户图表范围类型
     * @param rangeType
     * @private
     */
    _setActiveRangeType = (rangeType) => {
        this.setState({activeRangeType: rangeType}, () => {
            this._initChartActive();
        });
    };

    /**
     *
     * @private
     */
    _initCountAll = () => {
        const {loadingDashboard} = this.state;

        if(!loadingDashboard) {
            this.setState({loadingDashboard: true});

            const token = this.userStore.user.token;
            const url = `/apiB/user/count/dashboard`;

            let isSuccess = false;
            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
                .then((response) => {
                    isSuccess = response.ok;
                    return response.json();
                })
                .then((json) => {
                   if(isSuccess){
                       this.setState({
                           countAll: json.countAll,
                           countActive: json.countActive,
                           countIncrease: json.countIncrease,
                           countInstall: json.countInstall
                       });
                   }else {
                       message.error("查询失败");
                   }
                   this.setState({loadingDashboard: false});
                })
                .catch((err) => {
                    this.setState({loadingDashboard: false});
                });
        }
    };

    /**
     * 判断闰年
     * @param year
     * @returns {boolean}
     * @private
     */
    _isLeapYear = (year) => {
        return (year % 4 == 0) && (year % 100 != 0 || year % 400 == 0);
    };

    /**
     * 获取本月日数
     * @returns {[]}
     * @private
     */
    _getDateByMonth = () => {
        const month = new Date().getMonth() + 1;
        const m31 = [1,3,5,7,8,10,12];

        let countDates = 0;
        let dates = [];
        if(month === 2){
            countDates = this._isLeapYear(new Date().getFullYear()) ? 29 : 28;
        }else if(m31.indexOf(month) > -1){
            countDates = 31;
        }else {
            countDates = 30;
        }
        for (let i = 1; i <= countDates; i++){
            dates.push(`${i}`);
        }

        return dates;
    };

    /**
     * 用户新增图表
     * @private
     */
    _initChartIncrease = () => {
        let dateArray;
        const {increaseRangeType} = this.state;
        switch (increaseRangeType) {
            case "year": dateArray = ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'];break;
            case "month": dateArray = this._getDateByMonth();break;
            case "week": dateArray = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];break;
            default: break;
        }

        const url = `/apiB/user/count/increase/${increaseRangeType}/${0}`;
        const token = this.userStore.user.token;

        let isSuccess = false;
        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then((response) => {
                isSuccess = response.ok;
                return response.json();
            })
            .then((json) => {
                if(isSuccess){
                    const data = {
                        dates: dateArray,
                        counts: json.reverse(),
                    };
                    // 初始化
                    const increaseChart = echarts.init(document.getElementById('chart-increase'));
                    // 绘制图表
                    increaseChart.setOption({
                        title: { text: '' },
                        tooltip : {
                            trigger: 'axis'
                        },
                        toolbox: {
                            show : true,
                            feature : {
                                dataView : {show: true, readOnly: false},
                                magicType : {show: true, type: ['line', 'bar']},
                                restore : {show: true},
                                saveAsImage : {
                                    show: true,
                                    type: 'jpg'
                                }
                            }
                        },
                        xAxis : [
                            {
                                type : 'category',
                                data : data.dates
                            }
                        ],
                        yAxis : [
                            {
                                type : 'value'
                            }
                        ],
                        series : [
                            {
                                name:'新增数',
                                type:'bar',
                                data: data.counts,
                                markPoint : {
                                    data : [
                                        {type : 'max', name: '最大值'},
                                        {type : 'min', name: '最小值'}
                                    ]
                                },
                                markLine : {
                                    data : [
                                        {type : 'average', name: '平均值'}
                                    ]
                                }
                            },
                        ]
                    });
                }
            })
            .catch((err) => {

            });


    };

    /**
     * 用户新增图表
     * @private
     */
    _initChartActive = () => {
        let dateArray;
        const {activeRangeType} = this.state;
        switch (activeRangeType) {
            case "year": dateArray = ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'];break;
            case "month": dateArray = this._getDateByMonth();break;
            case "week": dateArray = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];break;
            default: break;
        }

        const url = `/apiB/user/count/active/${activeRangeType}/${0}`;
        const token = this.userStore.user.token;

        let isSuccess = false;
        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then((response) => {
                isSuccess = response.ok;
                return response.json();
            })
            .then((json) => {
                if(isSuccess) {
                    const data = {
                        dates: dateArray,
                        counts: json.reverse(),
                    };
                    // 初始化
                    const activeChart = echarts.init(document.getElementById('chart-active'));
                    // 绘制图表
                    activeChart.setOption({
                        title: {text: ''},
                        tooltip: {
                            trigger: 'axis'
                        },
                        toolbox: {
                            show: true,
                            feature: {
                                dataView: {show: true, readOnly: false},
                                magicType: {show: true, type: ['bar', 'line']},
                                restore: {show: true},
                                saveAsImage: {
                                    show: true,
                                    type: 'jpg'
                                }
                            }
                        },
                        xAxis: [
                            {
                                type: 'category',
                                data: data.dates
                            }
                        ],
                        yAxis: [
                            {
                                type: 'value'
                            }
                        ],
                        series: [
                            {
                                name: '活跃人数',
                                type: 'line',
                                data: data.counts,
                                markPoint: {
                                    data: [
                                        {type: 'max', name: '最大值'},
                                        {type: 'min', name: '最小值'}
                                    ]
                                },
                                markLine: {
                                    data: [
                                        {type: 'average', name: '平均值'}
                                    ]
                                }
                            },
                        ]
                    });
                }
            })
            .catch(() => {

            });
    }

}

export default Dashboard;
