/**
 * 格式化duration
 * @param durationMs        毫秒级
 * @returns {string}        hh:mm:ss
 * @private
 */
export function formatDuration (durationMs) {
    const durationS = durationMs / 1000;
    let duration = null;
    if(durationS < 60){
        let sec = durationS.toFixed(0);
        if(durationS < 10){
            sec = "0" + sec;
        }

        duration = "00:00:" + sec;
    }else if(durationS < 3600){
        let min = parseInt((durationS / 60).toString());
        if(min < 10){
            min = "0" + min;
        }

        let sec = parseInt(`${durationS % 60}`);
        if(sec < 10){
            sec = "0" + sec;
        }
        duration = "00:" + min + ":" + sec;
    }else{
        let hour = parseInt((durationS / 3600).toString());
        let hourStr = hour;
        if(hour < 10){
            hourStr = "0" + hour;
        }

        let min = parseInt(((durationS - (hour * 3600)) / 60).toString());
        if(min < 10){
            min = "0" + min;
        }

        let sec = parseInt(`${durationS % 60}`);
        if(sec < 10){
            sec = "0" + sec;
        }

        duration = hourStr + ":" + min + ":" + sec;
    }

    return duration;
};
