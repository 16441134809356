import Global from "../Global";

export function isBlank(str) {
    return (!str || /^\s*$/.test(str));
}

export function isNotBlank(str) {
    return typeof str !== "undefined" && !isBlank(str);
}


const name = /^[a-zA-Z\u0000-\u00FF]+$/;

/**
 * 验证名称
 * 只能输入半角
 */
export function testPaymentName(str) {
    return name.test(str);
}


const reVisa = /^(4\d{12}(?:\d{3})?)$/;

/**
 * 验证visa卡
 */
export function testVisaPaymentNumber(str) {
    return reVisa.test(str);
}


const reMasterCard = /^(5[1-5]\d{2})[\s-]?(\d{4})[\s-]?(\d{4})[\s-]?(\d{4})$/;

/**
 * 验证mater卡
 */
export function testMasterPaymentNumber(str) {
    return reMasterCard.test(str)
}


const r1 = /^\d{3,4}$/;

const regular1 = /^1[34578]\d{9}$/;

/**
 * 验证手机号
 */
export function testTel(str) {
    return regular1.test(str);
}


const regular2 = /^[a-zA-Z0-9_]{5,20}$/;


/**
 * 验证电子邮箱
 * 验证逻辑:
 * @ 之前必须有内容且只能是字母（大小写）、数字、下划线(_)、减号（-）、点（.）
 * @ 和最后一个点（.）之间必须有内容且只能是字母（大小写）、数字、点（.）、减号（-），且两个点不能挨着
 * 最后一个点（.）之后必须有内容且内容只能是字母（大小写）、数字且 2 <= 长度 <= 6
 */
const regularEmail = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;

export function testEmail(str) {
    return regularEmail.test(str);
}

/**
 * 验证字符串中是否带有数字（全角和半角）
 * @param str
 * @return {boolean}
 */
export function testInteger(str) {
    for (let i = 0; i < str.length; i++) {
        let chr = str.charCodeAt(i);
        if (chr >= 48 && chr <= 57) {
            return true;
        }
        if (chr >= 65296 && chr <= 65305) {
            return true;
        }
    }
    return false;
}

/**
 * 验证过去的日期
 */
export function testOldDays(str) {
    //根据指定分隔符获取字符串数组
    const array = str.split("-");
    const now = new Date();
    if (array[0] > now.getFullYear()) {
        return true;
    }
    // console.log(str);
    return false;
}
