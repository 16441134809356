import Login from "../components/Login/Login";
import Home from "../components/Home/Home";
import NoMatch from "../components/main/404";
import Intro from "../components/Intro/Intro";
import Privacy from "../components/Privacy/Privacy";
import Protocol from "../components/Privacy/Protocol";
import UnAuth from "../components/UnAuth/UnAuth";
import AddTestAccount from "../components/AddTestAccount/AddTestAccount";

export default [
    { path: "/", name: "App", component: Intro, auth: false },
    { path: "/console", name: "Home", component: Home, auth: true },
    { path: "/console/index", name: "Home", component: Home, auth: true },
    { path: "/console/user/teen/public", name: "Home", component: Home, auth: true },
    { path: "/console/user/teen/normal/block", name: "Home", component: Home, auth: true },
    { path: "/console/user/teen/private/block", name: "Home", component: Home, auth: true },
    { path: "/console/user/teen/private", name: "Home", component: Home, auth: true },
    { path: "/console/user/adult/public", name: "Home", component: Home, auth: true },
    { path: "/console/user/adult/normal/block", name: "Home", component: Home, auth: true },
    { path: "/console/user/adult/private/block", name: "Home", component: Home, auth: true },
    { path: "/console/user/adult/private", name: "Home", component: Home, auth: true },
    { path: "/console/video/normal", name: "Home", component: Home, auth: true },
    { path: "/console/video/block", name: "Home", component: Home, auth: true },
    { path: "/console/review/video", name: "Home", component: Home, auth: true },
    { path: "/console/review/album", name: "Home", component: Home, auth: true },
    { path: "/console/album/type", name: "Home", component: Home, auth: true },
    { path: "/console/recommend/openVideo", name: "Home", component: Home, auth: true },
    { path: "/console/recommend/video", name: "Home", component: Home, auth: true },
    { path: "/console/recommend/album", name: "Home", component: Home, auth: true },
    { path: "/console/recommend/albumType", name: "Home", component: Home, auth: true },
    { path: "/console/complain/video", name: "Home", component: Home, auth: true },
    { path: "/console/complain/user", name: "Home", component: Home, auth: true },
    { path: "/console/feedback", name: "Home", component: Home, auth: true },
    { path: "/console/push", name: "Home", component: Home, auth: true },
    { path: "/console/uploadApk", name: "Home", component: Home, auth: true },
    { path: "/console/bulletin", name: "Home", component: Home, auth: true },
    { path: "/console/addTestAccount", name: "Home", component: Home, auth: true },
    { path: "/privacy", name: "Privacy", component: Privacy, auth: false },
    { path: "/protocol", name: "Protocol", component: Protocol, auth: false },
    { path: "/login", name: "Login", component: Login, auth: false },
    { path: "/unAuth", name: "UnAuth", component: UnAuth, auth: false },
    { path: "/404", name: "404", component: NoMatch },
];
