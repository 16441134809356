const Global = {
    isLogin: false,
    user:{
        id: null,
        username: null,
        nickname: null,
        profile: null,
        token: null,
        tel: null,
        sex: null
    }
};

export default Global;
