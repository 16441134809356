import logo from './logo.svg';
import React from 'react';
import './App.css';
import UploadApk from "./components/UploadApk/UploadApk";
import { Router, Switch, Route} from 'react-router-dom';
import {Provider, observer} from 'mobx-react';
import NavBar from "./components/NavBar/NavBar";
import store from "./mobx/store/index";
import {RouterStore, syncHistoryWithStore} from 'mobx-react-router';
import NoMatch from "./components/main/404";
import SignUp from "./components/SignUp/SignUp";
import Login from "./components/Login/Login";
import Home from "./components/Home/Home";
import routerMap from "./router/routerMap";
import FrontendAuth from "./router/FrontendAuth";
import {Typography} from "antd";

const browserHistory = require("history").createBrowserHistory();
const routerStore = new RouterStore();
const history = syncHistoryWithStore(browserHistory, routerStore);
const rootStore = {
    router: routerStore,
};

export default class App extends React.Component {

    constructor(props){
        super(props);
        this.state={

        };
    }

    render(){
        return (
            <div className="App" style={{overflowY: 'hidden'}}>
                <Provider {...store}>
                    <Router history={history}>
                        <div>
                            <NavBar history={history}/>
                            <Switch>
                                <FrontendAuth routerConfig={routerMap} />
                            </Switch>
                        </div>
                    </Router>
                </Provider>

            </div>
        );
    }


}
