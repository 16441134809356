import React from 'react';
import {Col, Row, Typography} from "antd";
import {inject, observer} from "mobx-react";

@inject("windowStore")
@observer
class Privacy extends React.Component{

    constructor(props) {
        super(props);
        this.windowStore = this.props.windowStore;
        this.state={

        }
    }

    render() {
        const {width, height, col} = this.windowStore;
        const cols = ["XS", "S", "M", "L", "XL"];
        const colIndex = cols.indexOf(col);
        const smallThenL = colIndex < 4;



        return (
            <div style={{width: "100%", height: height, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{width: smallThenL ? "90%" : "60%"}}>
                    <Typography.Title
                        style={{marginTop: 20}}
                    >
                        Privacy Policy
                    </Typography.Title>
                </div>
                <div style={{width: smallThenL ? "90%" : "60%", height: "100%", flexDirection: 'column', textAlign: 'left', justifyContent: 'flex-start',}}>
                    <textarea style={{width: "100%", height: "95%", borderWidth: 0}}>
                        {this._getText()}
                    </textarea>
                    <div style={{width: "100%", textAlign: 'right', marginTop: 10, paddingRight: 100}}>
                        <text style={{fontWeight: 'bold', fontSize: 20}}>
                            edcturbo studio
                        </text>
                    </div>
                </div>
            </div>
        )
    }

    _getText = () => {
        const email = "edcturbotec@gmail.com";
        const webUrl = "https://www.edcturbo.com";
        const appName = "VrClose";
        const companyName = "edcturbo studio";

        return `\t This ${appName} User Privacy Policy (hereinafter referred to as the "Policy") applies only to ${appName} software and related services of EdcTurbo Studio (hereinafter referred to as "us"), including ${appName} website ${webUrl} and ${appName} mobile application (hereinafter referred to as the "Products"), and does not apply to third party products or services that have separate privacy policies and are not included in this Policy. When you use our products, we will collect, store, use and provide your personal information in accordance with this Policy. At the same time, we will explain to you through this Policy how we provide services for you to access, update, manage and protect your personal information. Hope you carefully read the privacy policy (hereinafter referred to as "the policy"), learn more about our way of information collection, use, in order to better understand our service and you whether in choosing services, and choose what services more in line with the real meaning of you said, if you use our services, it indicates that you agree with our contents stated in this policy.

${companyName} (hereinafter referred to as "We") is fully aware of the importance of personal information to you and will do our best to protect the security and reliability of your personal information. We are committed to maintaining your trust in us and abide by the following principles.

Protecting your personal information: the principle of alignment of rights and responsibilities, the principle of clarity of purpose, the principle of choice consent, the principle of least sufficient, the principle of ensuring security, the principle of principal participation, the principle of openness and transparency, etc. At the same time, we promise that we will take appropriate security measures to protect your personal information according to the industry's mature security solutions.

If you do not expressly agree to this Policy and start using it, we will not collect any of your information, which will make it impossible for us to provide you with complete products and services. We try our best to express in plain and simple words, and use bold font to remind you to pay attention to the provisions in this policy that have a significant relationship with your rights and interests. If you have any questions, comments or suggestions, please contact us in one of the following ways:

1. Email address: [${email}];

This Privacy Policy will help you understand the following:

1. How do we collect and use your Personal Information

2. How do we use cookies, peer technologies, and SDK technologies

3. How do we share, transfer and publicly disclose your personal information

4. How do we protect your personal information

5. How do you manage your personal information/your rights

6. How do we handle minors' personal information

7. Where and how long will your information be stored? How will your personal information be transferred around the world

8. How to update this Privacy Policy

9. How can I contact us

10. Advertising/messages we send to you

11. Scope of application

12. Other


For better clarification, the following concepts in this Policy are defined as:

Personal information refers to, in electronic or other ways to record can be alone or combined with other information to identify specific natural person identity or reflect specific natural person activity of all kinds of information, including personally identifiable information (name, date of birth, id number, including id, passport, driver's license), address, phone number, contacts, network identity information (including account name, account nickname, email address and password associated with the aforementioned with password protection questions and answers). Property information, transaction information, commonly used personal device information (including hardware model, device MAC address, operating system type, software list, unique device identification code, etc.), personal location information, etc.

Personal general equipment information refers to include hardware serial number, MAC address, software list, the only equipment identification number (such as IMEI/IDFA OPENUDID/GUID/OAID, SIM card IMSI information, etc.), a description of the basic conditions of personal common equipment information.

Personal location information: including track, accurate positioning information, accommodation information, latitude and longitude, etc.

Personal sensitive information refers to the personal information that, once leaked, illegally provided or abused, may endanger personal and property safety and easily lead to damage to personal reputation, physical and mental health or discriminatory treatment. Personal sensitive information in this privacy policy includes: personal identification information (ID card number, including ID card, military ID card, passport, driving license), facial recognition features; Property information and transaction information; Network identification information (including account name, account nickname, email address, and password and password protection questions and answers related to the foregoing); Other information (including address book, personal phone number, itinerary information, accurate positioning information).

De-identification refers to the process of technical processing of personal information to make it impossible to identify the subject of personal information without the aid of additional information.

Anonymization refers to the process in which the subject of personal information cannot be identified and the processed information cannot be recovered through technical processing of personal information.


1. How do we collect and use your personal information

Personal Information refers to all kinds of information recorded by electronic or other means that can independently or in combination with other information identify the identity of a specific natural person or reflect the activities of a specific natural person. We will collect and use your Personal Information for the following purposes as stated in this Policy:

The business functions that collect personal information and the personal information that is collected

We will provide the following services to you and collect the information listed below when we provide the following services:

1) Business function: register as a user. When you register for the ${appName} service, in order to create an account, you need to provide the following information for us to collect: mobile phone permissions, mobile phone number, nickname, birthday, gender and personal photo. This information is collected to help you complete the registration of ${appName}, obtain the account of ${appName}, and thus protect the security of your ${appName} account.

Mobile phone numbers and personal photos belong to sensitive information, which is collected to meet the online real-name system requirements of national laws and regulations and complete the registration of VrClose account.

If you do not provide such information, you may not be able to use our services normally

The above information provided by you will continue to be licensed to us for the duration of your use of the Service. We will keep your information until you cancel your account. When you cancel your account, we will stop using it and delete the above information.

The above information is stored only in the United States of America, and we will obtain your authorization separately for cross-border transmission.

2) Business function: chat. When you use the chat service, we do not require you to provide information.

If you provide the following additional information, it will help us to provide you with a better service and experience: photo album permissions, microphone permissions. However, if you do not provide this information, the basic business function of using the Service will not be affected.

The above information provided by you will continue to be licensed to us for the duration of your use of the Service. We will keep your information until you cancel your account. When you cancel your account, we will stop using it and delete the above information.

The above information is stored only in the United States of America, and we will obtain your authorization separately for cross-border transmission.

3) Business function: sliding card. When you use the Sliding Card Service, in order to recommend users nearby to you, you need to provide the following information for us to collect: Personal Location Information.

Personal location information is sensitive information.

If you do not provide this information, you may not be able to use the Sliding Card service.

During use, we will help us to provide you with better services and experience if you provide the following additional information: personal profile, occupation, company, hometown, hobbies, age, gender (hereinafter referred to as "Personal Information"). However, if you do not provide this information, the basic business function of using the Service will not be affected.

Personal profile, occupation, company, hometown, hobbies, age and gender are sensitive information.

The above information provided by you will continue to be licensed to us for the duration of your use of the Service. We will keep your information until you cancel your account. When you cancel your account, we will stop using it and delete the above information.

The above information is stored only in the United States of America, and we will obtain your authorization separately for cross-border transmission.

4) Business function: community. When you use community services, we do not require you to provide information.

During use, we will help us to provide you with a better service and experience if you provide us with the following additional information: Personal Location Information. However, if you do not provide this information, the basic business function of using the Service will not be affected.

Personal location information is sensitive information.

The above information provided by you will continue to be licensed to us for the duration of your use of the Service. We will keep your information until you cancel your account. When you cancel your account, we will stop using it and delete the above information.

The above information is stored only in the United States of America, and we will obtain your authorization separately for cross-border transmission.

5) Business function: publishing UGC content. When you use the UGC Content Service, in order to provide UGC material, you need to provide the following functions and information for us to collect: album permissions, camera permissions, personal location permissions.

If you do not provide such information, you may not be able to use the service of sending Moments.

During use, we will help us to provide you with a better service and experience if you provide us with the following additional information: Personal Location Information. However, if you do not provide this information, the basic business function of using the Service will not be affected.

Personal location information is sensitive information.

The above information provided by you will continue to be licensed to us for the duration of your use of the Service. We will keep your information until you cancel your account. When you cancel your account, we will stop using it and delete the above information.

The above information is stored only in the United States of America, and we will obtain your authorization separately for cross-border transmission.

6) Business function: live broadcasting function. We do not require you to provide information when you view others' livestreams and make rewards or comments.

When you use the live broadcast function, you need to provide the following functions and information for us to collect: photo album permissions, camera permissions, microphone permissions, and you also need to provide us with your ID card information for real name authentication. If you refuse to provide the license, you will not be able to use this function, but it will not affect your normal use of other functions.

During use, we will help us to provide you with a better service and experience if you provide us with the following additional information: Personal Location Information. However, if you do not provide this information, the basic business function of using the Service will not be affected.

Personal location information is sensitive information.

The above information provided by you will continue to be licensed to us for the duration of your use of the Service. We will keep your information until you cancel your account. When you cancel your account, we will stop using it and delete the above information.

The above information is stored only in the United States of America, and we will obtain your authorization separately for cross-border transmission.

7) Business function: real-name authentication. When you use real identity authentication service, the identity information such as the need to provide your id card or facial characteristics, such as biometric information (all belong to personal sensitive information) to complete the certification for specific products and services, or need to be real people, in order to identify the authenticity of the user's identity, you need to provide the following information for us to collect: id such as identity information or facial characteristics, such as biometric information.

Identifying information such as ID cards or biometric information such as facial features are sensitive information.

If you do not provide this information, you may not be better served.

The above information provided by you will continue to be licensed to us for the duration of your use of the Service. We will keep your information until you cancel your account. When you cancel your account, we will stop using it and delete the above information.

The above information is stored only in the United States of America, and we will obtain your authorization separately for cross-border transmission.

8) Business function: soul resonance. We do not require you to provide information when you use the Soul Resonance service.

During use, we will help us to provide you with a better service and experience if you provide us with the following additional information: Personal Location Information. However, if you do not provide this information, the basic business function of using the Service will not be affected.

Personal location information is sensitive information.

The above information provided by you will continue to be licensed to us for the duration of your use of the Service. We will keep your information until you cancel your account. When you cancel your account, we will stop using it and delete the above information.

The above information is stored only in the United States of America, and we will obtain your authorization separately for cross-border transmission.



9) Business function: payment function. The payment function is provided to you by a third-party payment institution cooperating with us. The third-party payment institution will need to collect your information and we will not be able to obtain your information. Refusing to provide information will only prevent you from using the above payment function, but will not affect your use of VrClose's other functions.

10)Other business functions: Match, Super Like, Super Expose, Slide Back, View the People I Like. When you use the above services, we will not collect your information or ask you to provide permissions.


Instructions for personalized recommendations

1. Personalized recommendation function is to provide you with pages, videos and other content more in line with your needs in the "Community" and "City" business module. In order to realize the personalized recommendation function, we may collect and use the following information of you:

A. Focus, search and browse your operation and use behavior information;

B. Feedback, release, comments and other information provided by you voluntarily;

C. Geo-location information with your express consent.

Based on the information collected above, we will conduct comprehensive statistics and make feature and preference analysis through algorithms, so as to provide you with personalized content recommendation, display or push other users you may be interested in or push specific functions or services more suitable for you in the "Community" and "City" business modules. In addition, if you do not want to use the personalized recommendation function, you can also turn off the personalized recommendation function at any time through the "System Settings - Privacy Settings - Personalization Options" control switch.

How do we use the collected information

We will use your information for the purposes described above. If we want to use the information for other purposes not specified in this Policy, we will obtain your prior consent. When we want to use information collected for a particular purpose for other purposes, we will obtain your prior consent.

You can share your relevant information with your other users through our services. For example, words and photos that you share publicly in the community.

Please note that this may include sensitive information such as your personally identifiable information, personal property information, etc. Please carefully consider disclosing your personal sensitive information.

You may delete the information you share publicly through Settings in the Service or the guidelines we provide. However, please note that this information may still be held independently by other users or non-associated third parties that are not under our control.

The purpose of collecting and using personal information is changed

We may make some adjustments to our products as our business develops. When the new function or service is related to the original use scenario of the above business function, the personal information collected and used is directly related to the original purpose. In cases not directly related to the original purpose, we will collect and use your personal information and get your consent again.


2. How do we use cookies, peer technologies, and SDK technologies

Cookie

We may store small data files called cookies on your computer or mobile device to ensure the proper operation of the Site, to provide you with an easier access experience, and to recommend content that may be of interest to you. Cookies typically contain identifiers, site names, and some numbers and characters. You can do the corresponding data cleanup in the browser.

Other technologies

In addition to cookies, we use other similar technologies on our sites. Member SMS notifications or Vrclose customer service or in-site notifications sent to you by us may contain an address link to our website content. If you click on the link, we will track this click to help us understand your product or service preferences so that we can proactively improve our customer service experience. You can unsubscribe at any time if you don't want your activities to be tracked in this way.

The SDK technology

The SDK technology is provided by the third party in cooperation with us, and we only know the authority they require to obtain authorization. Third parties will need to collect your information, and we cannot obtain your information unless the third party expressly discloses to us the sensitive information to be collected. For the rights required by the third party and the sensitive information collected and clearly disclosed to us, please refer to the SDK Technical Instructions Document.

If you refuse to provide the information to the third party, you will only be unable to use the functions carried by the technology provided by the third party, but it will not affect your use of other functions of VrClose.

3. How do we share, transfer and publicly disclose your personal information

Shared

We will not share your personal information with companies, organizations and individuals other than VRClose service providers, except in the following circumstances:

1) Sharing with your express consent: we will share your personal information with other parties after obtaining your express consent;

2) Sharing under legal circumstances: We may share your personal information according to the provisions of laws and regulations, the needs of litigation dispute settlement, or the requirements of administrative and judicial authorities according to law;

3) Sharing with Affiliates: In order to facilitate us to jointly provide services to you based on the Affiliated Account, recommend information that you may be interested in, or protect the personal and property safety of Vrclose Affiliates or other users or the public from infringement, your personal information may be shared with our Affiliates. We will share the necessary personal information (e.g., for the sake of you through VrClose account using our affiliate products or services, we will share you the necessary account information to the associated company), if we share your personal sensitive information or associated companies change the use of personal information and the handling of the purpose, will again ask your authorized permission;

4) Sharing with Authorized Partners: For the purpose stated in this Privacy Policy, some of our services will be jointly provided by us and our authorized partners. We may share some of your personal information with our partners to provide better customer service and user experience. For example, arranging partners to provide services. We will only share your Personal Information for lawful, legitimate, necessary, specific and explicit purposes, and will only share such Personal Information as is necessary to provide the Services. Our partners are not entitled to use the shared personal information for other purposes unrelated to the product or service.

We send information to vendors, service providers and other partners who support our business, including providing technical infrastructure services, analyzing how our services are used, measuring the effectiveness of advertising and services, providing customer service, facilitating payments, or conducting academic research and surveys.

For companies, organizations and individuals with whom we share personal information, we will sign strict data protection agreements with them, requiring them to handle personal information in accordance with our instructions, this Privacy Policy and any other relevant confidentiality and security measures.

The transfer

We will not transfer your Personal Information to any company, organization or individual, except in the following circumstances:

1) Transfer with express consent: we will transfer your personal information to other parties after obtaining your express consent;

2) when it comes to mergers, acquisitions or bankruptcy liquidation, or other situations involving mergers, acquisitions or bankruptcy liquidation, such as involving personal information transfer, we will request the new holder you personal information company, organization, continue to be bound by this policy, otherwise we will ask the company, organization or individual to ask for a grant to you.

Public disclosure

We will only publicly disclose your personal information if:

1) We may publicly disclose your Personal Information with your express consent or based on your active choice;

2) if we determine your appeared in violation of the laws, regulations or rules, a serious breach of VrClose related agreement or to protect VrClose users and their associated companies or the personal property safety of the public from the infringement, we may according to the related laws and regulations or VrClose agreement rules under the condition of your consent to disclose personal information about you, including related violations and the taken measure VrClose has for you.

Exceptions to obtaining prior authorization consent when sharing, transferring or publicly disclosing personal information

In the following circumstances, you do not need to obtain prior authorization to share, transfer or publicly disclose your personal information:

1) Related to national security and national defense security;

2) Those related to public safety, public health and major public interests;

3) Those related to criminal investigation, prosecution, trial and execution of judgment;

4) In order to protect your or other personal life, property and other important legitimate rights and interests, but it is difficult to get consent;

5) Your personal information that you disclose to the public;

6) Collection of personal information from legally disclosed information, such as legal news reports, government information disclosure and other channels.

By law, by sharing and transfer of the processing of personal information, and ensure the data receiver cannot recover and identify personal information main body, does not belong to the foreign share, transfer and disclosure of personal information, the preservation and processing of such data will be without prior notice to you and ask for your consent.

4. How do we protect your personal information

In order to protect your information security, we will strictly comply with the technical measures and operational procedures required by the normative documents such as laws and regulations to protect your information secrets, and at the same time:

1) We have taken reasonable and feasible security measures in line with the general solutions of the industry to protect the personal information provided by you and prevent unauthorized access, public disclosure, use, modification, damage or loss of personal information. We will use encryption to improve the security of our personal information; We will use trusted protection mechanisms to protect our personal information from malicious attacks; We establish a strict internal control system and a sound and sufficient management process. We will deploy an access control mechanism to ensure that only authorized personnel can access personal information. And we will organize security and privacy protection training courses to enhance employees' awareness of the importance of protecting personal information.

2) We have the industry's advanced data security management system centered on data life cycle, which improves the security of the whole system from the aspects of organization construction, system design, personnel management, product technology and so on.

3) We will take reasonable and practicable measures to avoid the collection of irrelevant personal information. We will retain your personal information only for the period of time necessary to achieve the purpose stated in the Policy, unless we need to extend the retention period or are permitted by law.

4) The Internet is not an absolutely safe environment. We strongly recommend that you do not send personal information using communication methods other than those recommended by VrClose. You can connect and share with each other through our service. When you communicate, trade or share through our services, you can choose the person with whom you communicate, trade or share as the third party who can see your trade content, contact information, exchange information or share information and other relevant information.

If you find that your personal information, especially your account or password, has been leaked, please contact the customer service of ${appName} immediately, so that we can take appropriate measures according to your application.

Please note that the information you voluntarily share or even publicly share when using our services may involve your or other people's personal information or even sensitive personal information, such as when you choose to upload pictures containing your personal information when Posting updates or in group chats, circles and other public places. Please consider more carefully whether to share or even publicly share relevant information when using our services.

Please use a complex password to help us keep your account secure. We will do our best to secure any information you send us.

We will update and publicize the contents of security risk, personal information security impact assessment and other reports from time to time.

The Internet environment is not 100 percent secure and we will do our best to ensure or warrant the security of any information you send us. If our physical, technical or management protection facilities are damaged, resulting in unauthorized access, public disclosure, tampering or destruction of information, which causes damage to your legitimate rights and interests, we will assume the corresponding legal liability.

In the unfortunate occurrence of personal information security incidents, we will in accordance with the requirements of laws and regulations, timely inform you: the basic situation and possible impact of the security incident, we have taken or will take the disposal measures, you can prevent and reduce the risk of independent suggestions, remedial measures for you. We will inform you of the relevant situation of the event by push notification in a timely manner, and send the announcement in a reasonable and effective way.

At the same time, we will also in accordance with the requirements of the regulatory authorities, the initiative to report the disposal of personal information security incidents.


5. How do you manage your personal information/your rights

In accordance with relevant laws, regulations, national standards, and industry practices of the United States of America, we guarantee that you exercise the following rights to your personal information:

1) Access and edit your personal information

Account Information -- If you want to access or edit your personal information in your account, set privacy and permissions, set message reminders and chat, change your bound mobile phone number and password, close your account, etc., you can login to your account through "My" - "Settings" to perform such operations.

Personal Information - If you want to access or edit your nickname, profile picture, date of birth, or other information in your profile, you can do so by logging into your account by clicking "My" Personal ID name or "Edit".

Dynamic Information - You can log into your account to access or clear the history of your published content through "My".

If you are not able to access such personal information through the above route, you may contact us at any time through ${appName} customer service. We will respond to your request for access within 15 days.

We will provide you with any other personal information that you may have in the course of using our products or services in accordance with the relevant arrangements.

You can request a copy of your personal information by contacting VRClose customer service.

2) Correct your personal information

You have the right to request us to make corrections or supplements when you find that there are errors in the personal information we process about you. You may apply for corrections or supplements in the manner set out in this section "Access and Edit Your Personal Information".

3) Delete your personal information

You may delete some of your personal information by means specified in this article "Access and Edit Your Personal Information".

You may request us to delete your personal information under the following circumstances:

A, if we deal with personal information in violation of laws and regulations;

B. If we collect and use your personal information without your express consent;

C. If our handling of personal information seriously violates the agreement with you;

D. If you no longer use our products or services, or if you voluntarily cancel your account;

E. If we no longer provide you with products or services.

If we decide to respond to your deletion request, we will also, as far as possible, notify the entities that have received your personal information from us to delete it in a timely manner, unless otherwise required by laws and regulations, or these entities have obtained your independent authorization.

When you delete information from our services, we may not delete the corresponding information from the backup system immediately, but we will delete this information when the backup is updated.

4) Manage the scope of your authorization

Each business function requires some basic personal information to complete. You may at any time give or withdraw your authorized consent for the collection and use of additional collected personal information.

You can do it yourself in the following ways:

You can change the scope of your authorization in the functions and services by deleting information, turning off device functions, and using "Settings" - "Account & Security, Notification Reminder, Privacy" in the product client application.

Certain business functions and services will require your Personal Information to be completed. After you withdraw your consent, we will not be able to provide you with the functions and services corresponding to the withdrawal of consent, nor will we process your Personal Information any more. However, your decision to withdraw your consent will not affect our previous personal information processing based on your consent.

5) User account logout

You can log in VRCloseApp by yourself (VRCloseApp → click "My" icon → click the gear icon in the upper right corner - system setting interface → "Account and Security" - click "Account Log Out (Delete Account)" to apply for account logout. Please note that after you successfully submit the account cancellation application according to the above procedures, your account will be cancelled immediately without any manual review. Please read and confirm the Account Cancellation Instructions carefully before you cancel your account.

After you voluntarily cancel your account, we will stop providing you with products or services, delete your personal information or make it anonymous in accordance with the requirements of the relevant laws.

6) Constraint the automatic decision of information system

In some business functions, we may make decisions based solely on non-human automated decision mechanisms such as information systems, algorithms, and so on. If these decisions significantly affect your legitimate rights and interests, you have the right to ask us for an explanation. We will also provide the appeal method under the premise of not infringing on Vrclose's trade secrets or other user rights and interests, as well as social and public interests.

7) Responding to your above request

For security reasons, you may need to provide a written request or otherwise prove your identity. We may ask you to verify your identity before processing your request.

We will reply within 15 days. If you are not satisfied with our response, you can also lodge a complaint through VrClose customer service.

For your reasonable request, we will try our best to meet your request. We may reject requests that are gratuitously repetitive, require too much technology (for example, require the development of a new system or a fundamental change in existing practices), pose a risk to the legitimate interests of others, or are highly impractical.

restrictions

In accordance with the requirements of laws and regulations, we will not be able to respond to your request under the following circumstances:

A. Relating to national security and national defense security;

B. Relating to public safety, public health or major public interests;

C. Related to criminal investigation, prosecution, trial and execution of judgment;

D. There is sufficient evidence to show that the subject of personal information has subjective malice or abuse of rights;

E. Responding to your request will cause serious damage to the legitimate rights and interests of you or other individuals or organizations;

F. Involving commercial secrets.



6. How do we deal with minors' personal information

Our products are mainly aimed at adults. We expect parents or guardians to instruct minors to use our services. If you are a minor under the age of 18, you should read and agree to this Policy under the supervision and guidance of your parents or other guardians before using our products. Children (under 14 years old) are not allowed to create their own user accounts without the consent of their parents or guardians.

We protect the personal information of minors in accordance with the relevant laws and regulations of the state. In the case of collecting the personal information of minors with the consent of parents, we will only use, share or publicly disclose such information when it is permitted by law, with the express consent of parents or guardians or when it is necessary to protect the minors.

If we find ourselves collecting personal information from minors without first obtaining verifiable parental consent, we will seek to delete the data as soon as possible.

7. Where and how long will the information be stored? How will your personal information be transferred around the world

Where your personal information is stored

We store users' personal information collected and generated in China in strict accordance with laws and regulations.

Personal information out of the situation we are not to transmit the information to the outside, if part of the product involved in cross-border business, we need to transfer to overseas institutions within the territory of collecting the relevant personal information, we will follow the relevant provisions of the state, and evaluate the information security of the information abroad and at the same time, and get your clear mandate, in accordance with the laws and regulations and the provisions of the relevant regulatory authorities, and through effective measures, such as agreement, the inspection requirements foreign institutions to get your personal information confidential, but you cross-border via the Internet publishing dynamic except personal initiative.

In the above cases, we will ensure that your personal information is adequately protected in accordance with this Privacy Policy.

The period during which the information is stored

Generally speaking, we will only retain your personal information for the shortest period necessary for the purpose, and any personal information beyond that period will be deleted or anonymized for processing. However, we may change the storage time of personal information due to the need to comply with the legal requirements under the following circumstances:

1) To comply with applicable laws, regulations or policies and other relevant provisions of normative documents;

2) To comply with the decisions of administrative organs or similar authorized organizations, judgements and rulings of judicial organs, rulings of arbitration organs or provisions of other legal procedures;

3) We have reason to believe that laws and regulations and other relevant provisions need to be observed;

4) Reasonable and necessary use for the implementation of relevant service agreements or this policy, maintenance of social and public interests, and protection of personal and property safety or other legitimate rights and interests of third parties such as our customers, us or our affiliates, other users or employees.

In the event of a shutdown of VRClose, we will notify you in the form of push notifications, announcements on the website or within the program, and delete or anonymize your personal information within a reasonable period of time.

8. How to update this Privacy Policy

Our privacy policy is subject to change. In case of any change in business function, use purpose or contact information of the person in charge of personal information protection, we will revise the privacy policy accordingly.

Without your express consent, we will not limit your rights under this Privacy Policy. For significant changes, we will provide significant notice (including through VrClose platform announcements, VrClose customer service notices, etc.).

Significant changes referred to in this Policy include, but are not limited to:

Our privacy policy is subject to change. In case of any change in business function, use purpose or contact information of the person in charge of personal information protection, we will revise the privacy policy accordingly.

Without your express consent, we will not limit your rights under this Privacy Policy. For significant changes, we will provide significant notice (including through VrClose platform announcements, VrClose customer service notices, etc.).

Significant changes referred to in this Policy include, but are not limited to:

1) Major changes have taken place in our service model. Such as the purpose of personal information processing, the type of personal information processing, the use of personal information, etc.;

2) The main objects of personal information sharing, transfer or public disclosure change;

3) Your rights to participate in the processing of personal information and the way to exercise them have changed significantly;

4) We are responsible for dealing with personal information security departments, contact information and complaint channels change;

5) When the personal information security impact assessment report indicates that there is a high risk.

If you choose to continue using our services upon notification, you agree to be bound by the revised policy.

We will also keep the old version of this policy on file for your reference. You can apply to us for reference through the contact information reserved in this policy

9. How can I contact us

If you have any questions or suggestions about this policy or related matters, we have set up a personal information protection line. You can get in touch with us through the following complaint channels:

Email: [${email}]

We will properly accept and timely feedback your appeal, and after verifying your user identity within 15 days to reply.

If you are not satisfied with our response, especially if you believe that our personal information processing has harmed your legitimate rights and interests, you can also seek a solution by filing a lawsuit in the court with jurisdiction in the place where the contract is signed.

10. Advertising/messages we send to you

We may use your information to send commercial advertisements to you on relevant websites, applications and other channels.

If you do not want to accept the commercial advertisement sent to you by us, you can cancel at any time through the corresponding product unsubscribe function.

11. Scope of application

This Privacy Policy applies to all services provided by EdcTurbo Studio, including Vrclose clients, websites, etc., but does not apply to third party products or services that have a separate privacy policy and are not included in this Privacy Policy.

12, other

The headings in this Privacy Policy are for convenience and reading only and do not affect the meaning or interpretation of any provision in this Privacy Policy.

The copyright of this "Privacy Policy" belongs to us, and we have the right to interpret and modify it to the extent permitted by law.`;
    }

}
export default Privacy;
