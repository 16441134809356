import React from 'react';
import {
    Button,
    Row,
    Col,
    Typography,
    Tag,
    Input,
    Avatar,
    Tooltip,
    Checkbox,
    Select,
    Form,
    message,
    Alert,
    Modal, Spin, Card, Radio
} from 'antd';
import Icon, {AccountBookOutlined, AuditOutlined, CodeOutlined, UploadOutlined} from '@ant-design/icons';
import {observer, inject} from "mobx-react";
import {action, observable} from "mobx";
import * as Cookie from "../../util/Cookie";
import * as LoginUtil from "../../util/LoginUtil";
import * as StringUtil from "../../util/StringUtil";
import Global from '../../Global';
import Toast from "antd-mobile/es/toast/index";
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";
import SecurityScanOutlined from "@ant-design/icons/lib/icons/SecurityScanOutlined";
import QuestionCircleOutlined from "@ant-design/icons/lib/icons/QuestionCircleOutlined";
import PhoneOutlined from "@ant-design/icons/lib/icons/PhoneOutlined";
import UnAuth from "../UnAuth/UnAuth";

const { Option } = Select;

@inject("windowStore", "navBarStore", "userStore")
@observer
class AddTestAccount extends React.Component {
    @observable
    disabled;

    constructor(props) {
        super(props);
        this.windowStore = this.props.windowStore;
        this.navBarStore = this.props.navBarStore;
        this.userStore = this.props.userStore;

        this.state = {
            username: null,
            password: null,
            nickname: null,
            phoneNumber: null,
            securityCode: null,
            confirmSubmit: false,
            showAlert: false
        };
        this.disabled = true;
    }

    componentWillMount() {
        setTimeout(() => {
            this.disabled = false;
        }, 100);
    }

    render() {
        const {username, password, nickname, phoneNumber, securityCode, confirmSubmit, showAlert} = this.state;
        const {height, width, col} = this.windowStore;
        const isXs = col === "XS";
        const smallThenL = col === "M" || col === "L";

        return (
            <div style={{width: '100%', height: height, backgroundColor: '#e4e4e4',}}>
                <Modal
                    title="Create new account"
                    visible={showAlert}
                    onOk={() => {this._signUp()}}
                    onCancel={() => {this._hideAlert()}}
                    okText={"Confirm"}
                >
                    <p>Confirm and create new account for test</p>
                </Modal>
                <div style={{width: '100%', height: "100%",  backgroundColor: '#e4e4e4'}}>
                    <Row style={{width: "100%", height: '90%', alignItems: 'center', justifyContent: 'space-between',  backgroundColor: '#e4e4e4',}}>
                        <Col
                            xs={{span: 24, offset: 0}}
                            s={{span: 20, offset: 2}}
                            m={{span: 12, offset: 6}}
                            lg={{span: 8, offset: 8}}
                            xl={{span: smallThenL ? 6 : 4, offset: smallThenL ? 9 : 10}}
                            style={{padding: 15,  backgroundColor: '#e4e4e4',}}
                        >
                            <Card title="添加测试用户" bordered={false} style={{paddingBottom: 20, borderRadius: 10,boxShadow: "5px 5px 55px rgba(0,0,0,0.1)"}}>
                                <div className={"flex-row"} style={{borderBottom: "1px solid #ccc"}}>
                                    <Input
                                        id={"username"}
                                        className={"input"}
                                        size={"large"}
                                        value={username}
                                        disabled={this.disabled}
                                        placeholder={"Username"}
                                        onChange={(e) => this._setValue(e.target.value, 0)}
                                        prefix={<UserOutlined style={{color: '#c0c0c0'}}/>}
                                        suffix={
                                            <Tooltip placement="rightTop" title={"App Settings -> User Info"}>
                                                <QuestionCircleOutlined
                                                    style={{color: '#c0c0c0'}}
                                                    onClick={() => {}}
                                                />
                                            </Tooltip>
                                        }
                                    />
                                </div>
                                <div className={"flex-row"} style={{borderBottom: "1px solid #ccc"}}>
                                    <Input
                                        id={"password"}
                                        className={"input"}
                                        size={"large"}
                                        value={password}
                                        disabled={this.disabled}
                                        placeholder={"Password"}
                                        onChange={(e) => this._setValue(e.target.value, 1)}
                                        prefix={<SecurityScanOutlined style={{color: '#c0c0c0'}}/>}
                                        suffix={
                                            <Tooltip placement="rightTop" title={"App Settings -> Security"}>
                                                <QuestionCircleOutlined
                                                    style={{color: '#c0c0c0'}}
                                                    onClick={() => {}}
                                                />
                                            </Tooltip>
                                        }
                                    />
                                </div>
                                <div className={"flex-row"} style={{borderBottom: "1px solid #ccc"}}>
                                    <Input
                                        id={"nickname"}
                                        className={"input"}
                                        size={"large"}
                                        value={nickname}
                                        disabled={this.disabled}
                                        placeholder={"Nickname"}
                                        onChange={(e) => this._setValue(e.target.value, 2)}
                                        prefix={<AuditOutlined style={{color: '#c0c0c0'}}/>}
                                        suffix={
                                            <Tooltip placement="rightTop" title={"App Settings -> User Info"}>
                                                <QuestionCircleOutlined
                                                    style={{color: '#c0c0c0'}}
                                                    onClick={() => {}}
                                                />
                                            </Tooltip>
                                        }
                                    />
                                </div>
                                <div className={"flex-row"} style={{borderBottom: "1px solid #ccc"}}>
                                    <Input
                                        id={"phoneNumber"}
                                        className={"input"}
                                        size={"large"}
                                        value={phoneNumber}
                                        disabled={this.disabled}
                                        placeholder={"PhoneNumber"}
                                        onChange={(e) => this._setValue(e.target.value, 3)}
                                        prefix={<PhoneOutlined style={{color: '#c0c0c0'}}/>}
                                        suffix={
                                            <Tooltip placement="rightTop" title={"App Settings -> Security"}>
                                                <QuestionCircleOutlined
                                                    style={{color: '#c0c0c0'}}
                                                    onClick={() => {}}
                                                />
                                            </Tooltip>
                                        }
                                    />
                                </div>
                                <div className={"flex-row"} style={{borderBottom: "1px solid #ccc"}}>
                                    <Input
                                        id={"securityCode"}
                                        className={"input"}
                                        size={"large"}
                                        value={securityCode}
                                        disabled={this.disabled}
                                        placeholder={"SecurityCode"}
                                        onChange={(e) => this._setValue(e.target.value, 4)}
                                        prefix={<CodeOutlined style={{color: '#c0c0c0'}}/>}
                                        suffix={
                                            <Tooltip placement="rightTop" title={"App Settings -> Security"}>
                                                <QuestionCircleOutlined
                                                    style={{color: '#c0c0c0'}}
                                                    onClick={() => {}}
                                                />
                                            </Tooltip>
                                        }
                                    />
                                </div>

                                <Button
                                    block
                                    // disabled={!confirmSubmit}
                                    onClick={() => {this._validateAccount()}}
                                    type={"primary"}
                                    size={"large"}
                                    style={{marginTop: 20, borderRadius: 100}}
                                >
                                    Submit
                                </Button>
                            </Card>
                        </Col>

                    </Row>

                </div>
            </div>

        )
    }

    /**
     * 创建测试账户
     * @private
     */
    _signUp = () => {
        const {username, password, nickname, phoneNumber, securityCode} = this.state;
        const url = `/apiB/user/account/test`;
        const token = this.userStore.user.token;
        const formData = new FormData();
        const userInfo = {
            username: username,
            password: password + "",
            nickname: nickname,
            phoneNumber: phoneNumber,
            securityCode: securityCode,
        }
        formData.append("userInfo", JSON.stringify(userInfo));

        let isSuccess = false;
        fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': token,
                'Accept': 'application/json'
            },
            body: formData,
        })
            .then((response) => {
                const status = response.status;
                isSuccess = response.ok;
                if(isSuccess){
                    message.success("Create success");
                }else {
                    let reason = null;
                    switch (status) {
                        case 402: reason = "Invalid Parameter";break;
                        case 502: reason = "Invalid Platform";break;
                        case 404: reason = "Invalid User";break;
                        case 503: reason = "Invalid Security Code";break;
                        default: break;
                    }
                    message.error(`Create failed:${reason}`);
                }
                return response.json();
            })
            .then((json) => {
                this.setState({
                    username: null,
                    password: null,
                    nickname: null,
                    phoneNumber: null,
                    securityCode: null,
                    showAlert: false
                });
            })
            .catch((err) => {
                message.error(`Create failed:-1`);
            });
    };

    /**
     * 校验和提示
     * @private
     */
    _validateAccount = () => {
        const {username, password, nickname} = this.state;
        if(username == null || username === ""){
            message.error("please input username");
        }else if(password == null || password === ""){
            message.error("please input password");
        }else if(nickname == null){
            message.error("please input nickname");
        }else {
            this.setState({showAlert: true});
        }
    };

    @action
    _setValue = (value, index) => {
        switch (index) {
            case 0:
                this.setState({
                    username: value
                });
                break;
            case 1:
                this.setState({
                    password: value
                });
                break;
            case 2:
                this.setState({
                    nickname: value
                });
                break;
            case 3:
                this.setState({
                    phoneNumber: value
                });
                break;
            case 4:
                this.setState({
                    securityCode: value
                });
                break;
            default:
                break;
        }
    };

    /**
     * 隐藏提示框
     * @private
     */
    _hideAlert = () => {
        this.setState({showAlert: false});
    };

};
export default AddTestAccount;