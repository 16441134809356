import React from 'react';
import {observer, inject} from "mobx-react";
import {action, observable} from "mobx";

@inject("windowStore")
@observer
class Empty extends React.Component {


    render(){
        return (
            <div>
                TODO 放一些图表
            </div>
        )
    }

};

export default Empty;
