import React from 'react';
import {Col, Row, Typography} from "antd";
import {inject, observer} from "mobx-react";

@inject("windowStore")
@observer
class Protocol extends React.Component{

    constructor(props) {
        super(props);
        this.windowStore = this.props.windowStore;
        this.state={

        }
    }

    render() {
        const {width, height, col} = this.windowStore;
        const cols = ["XS", "S", "M", "L", "XL"];
        const colIndex = cols.indexOf(col);
        const smallThenL = colIndex < 4;



        return (
            <div style={{width: "100%", height: height, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{width: smallThenL ? "90%" : "60%"}}>
                    <Typography.Title
                        style={{marginTop: 20}}
                    >
                        User Protocol
                    </Typography.Title>
                </div>
                <div style={{width: smallThenL ? "90%" : "60%", height: "100%", flexDirection: 'column', textAlign: 'left', justifyContent: 'flex-start',}}>
                    <textarea style={{width: "100%", height: "95%", borderWidth: 0}}>
                        {this._getText()}
                    </textarea>
                    <div style={{width: "100%", textAlign: 'right', marginTop: 10, paddingRight: 100}}>
                        <text style={{fontWeight: 'bold', fontSize: 20}}>
                            edcturbo studio
                        </text>
                    </div>
                </div>
            </div>
        )
    }

    _getText = () => {
        const email = "edcturbotec@gmail.com";
        const webUrl = "https://www.edcturbo.com";
        const appName = "VrClose";
        const companyName = "edcturbo studio";

        return `        ${appName} App is developed by ${companyName} (the "Company") and provides services to you (the "User") according to the following Terms of Service. The Company hereby reminds you to carefully read the ${appName} User Service Agreement (hereinafter referred to as the "Agreement"), the ${appName} User Privacy Policy and other relevant agreements before you register as a ${appName} user, and to ensure that you have fully understood the terms and conditions of this Agreement, especially the terms of exemption or limitation of liability. Please read carefully and choose to accept or not to accept this Agreement. Unless you accept all the terms of this Agreement, you will not have the right to register, log in or use the services covered by this Agreement. If you register, log in or use VrClose, you will be deemed to have read, fully understood and accepted the terms and conditions of this agreement.

Users aged 18 or above are recommended to register and use the Service. If you are under the age of 18, please carefully read and fully understand this Agreement accompanied by your legal guardian, and use the ${appName} software and relevant services after obtaining the consent of your legal guardian.

This Agreement is an agreement between Company and User concerning the rights and obligations related to registering, logging in and using the ${appName} Service (hereinafter referred to as the "Service"). "User" means a person or organization that registers, logs in, or uses the Service. This Agreement may be updated by the Company from time to time. The updated terms and conditions of this Agreement shall supersede the original terms and conditions of this Agreement upon publication without prior notice. Users may refer to the latest terms and conditions of this Agreement on this website. After the terms and conditions of the Agreement are updated, if the user does not accept it, please immediately stop using the Service. If you continue to use the Service, you will be deemed to accept all the constraints of the revised terms and conditions of the Agreement.

Company shall have the right, at its sole discretion, to arrange or designate its affiliates, controlling company, successor company or a third party company approved by Company to continue to operate the VRClose Software and related services or operations. In addition, certain services covered under this Agreement may be provided to you by an affiliate of the Company, a controlling company, a successor company or a third party company approved by the Company. If you know and agree to accept the relevant service content, you shall be deemed to have accepted the relevant rights and obligations and be bound by this Agreement.

I. Account registration

1. Users need to register a VrClose account before using the Service. The ${appName} account shall be registered using the Facebook/Twitter/Google account binding. Users are requested to register the ${appName} account with a third-party account that has not been banned by the Company pursuant to this Agreement. The Company may change the way of account registration and binding according to user needs or product needs, and you should cooperate with the requirements of laws and regulations.

2. ${appName} is a mobile social product based on geographical location. Users shall authorize the company to disclose and use their geographical location information before successfully registering ${appName} account and using related services of ${appName}. Therefore, the completion of user registration indicates that the user agrees with the company to extract, disclose and use the user's geo-location information. If a user needs to terminate the disclosure of his/her geographical location information to other users, he/she can cancel the account by himself/herself. ${appName} retains the geographical location information disclosed by the user to other users last time that the user has not signed off his/her account.

3. When users register and use the Service, the Company shall collect personal information that can identify users so that the Company can contact users when necessary or provide users with a better use experience. The information collected by the Company includes but is not limited to the user's name, gender, age, date of birth, ID number, address, school information, company information, industry, interests and hobbies, frequent haunt and personal description; Company agrees that the use of this information will be subject to the restrictions set forth in Article 3 of the Privacy Information Protection of Users and the ${appName} User Privacy Policy.

4. You need to ensure the validity and legality of any updated mobile phone number, name, avatar and other information, as well as any content posted on VRClose. If you provide any information that is illegal, unethical or contrary to the provisions of this Agreement, or open an account in the name of another person or VRClose has reason to suspect that you conduct any malicious operation, VRClose reserves the right to restrict or disable your account functions and refuse you to use all or any part of the Services now and in the future. Any User shall be solely responsible for the authenticity, correctness, completeness, applicability and/or current status of any Registration Information posted on ${appName}.

5, when registered, use and management of account, you should guarantee the authenticity of the fill in information of the identity of the registered account, please in registration, account management, legal, true, accurate and effective identity certificate of the related and necessary information (including your name and email address, phone number, address, etc.). In accordance with the provisions of national laws and regulations, in order to use part of the functions of "${appName}" software and related services, you need to fill in the real identity information. Please complete the real-name authentication in accordance with the relevant laws and regulations, and pay attention to update the above relevant information in time. If the materials submitted or information provided by you are inaccurate, untrue, non-standard, illegal or the Company has reason to suspect that the materials are wrong, untrue or illegal, the Company shall have the right to refuse to provide the relevant services to you, and you may not be able to use the "${appName}" software and the relevant services or some functions will be restricted during the use process.

6, to improve the efficiency of your content exposure and release, you agree to you in the "VrClose" software and related services of the bank account and the corresponding the entire content of the release is authorized the company released to your account automatically synchronized to the operation of the company and/or associated party other software and web sites, the head of your account, nicknames such as public information synchronization may at the same time. Your actions of Posting, modifying or deleting content in "VrClose" software/website will be synchronized with other software and website mentioned above.


II. Service Content

1. The specific contents of the Service shall be provided by the Company according to the actual situation, including but not limited to authorizing users to conduct instant messaging, add friends, join groups, follow others, post messages, and post updates through their accounts after completing real-name authentication (including mobile phone number or identity information authentication) procedures.

2. The Services provided by the Company may include advertisements, and the User agrees to display advertisements provided by the Company, third-party suppliers and partners during the use of the Services. At the same time, ${appName} has the right to add the website logo (or name) and time watermark to the content published and transmitted by users. ${appName} will do its best but does not guarantee that it will not affect your browsing and viewing experience. Except as expressly provided by laws and regulations, User shall make its own judgment on such advertising information and shall be solely responsible for any transactions made thereon. Company shall not be liable for any loss or damage suffered by User as a result of any transactions made thereon or the contents provided by such advertisers.

III. Protection of users' personal privacy information

1. In the process of registering an account or using the Service, users may need to fill in or submit some necessary information, such as the identity information required to be filled in as stipulated in laws, regulations and normative documents (hereinafter referred to as "laws and regulations"). If the information submitted by the user is incomplete or does not comply with the provisions of laws and regulations, the user may not be able to use the Service or be restricted in the process of using the Service.

2, personal privacy information is referred for users' personal identity or personal privacy information, respect the privacy of users' personal privacy information is consistent system of company, the company will take technical measures and other necessary measures to ensure that users' personal privacy information security, prevent in this service to collect users' personal privacy information disclosure, is damaged or missing. In the event of the occurrence of the foregoing or when the Company discovers the possibility of the occurrence of the foregoing, it shall promptly take remedial measures. For the specific policies related to users' personal privacy information, please refer to the ${appName} User Privacy Policy. Users confirm that they have accepted the ${appName} User Privacy Policy if they register, log in or use the Service.



IV. Content specification

1, the contents mentioned in this article refers to the user to use this service in the process of production, upload, copy, distribution, transmission of any content, including but not limited to account picture, name, the user instructions for registration information and certification information, or text, voice, image, video, graphic, automatic reply or a reply message sending and a link to the page, and use other accounts or the content of this service. In accordance with the relevant laws and regulations, ${appName} hereby solemnly wishes to remind you that any content posted or uploaded via the Service, whether transmitted publicly or privately, shall be the responsibility of the Content Provider. VrClose only provides the storage space for the Content and has no control over the Content transmitted through the Service and therefore does not guarantee the correctness, integrity or quality of the Content. You foresee that you may be exposed to objectionable, inappropriate or objectionable content when using the Service. In no event is VrClose responsible for any content, which you can report through the report function within VrClose. Upon verification, VRClose shall have the right to stop the transmission of any of the foregoing contents and take corresponding actions, including but not limited to suspending the user's use of the Service in whole or in part, keeping relevant records and reporting to the relevant authorities. Users shall not publish UGC contents that violate laws or regulations, or that harm other users or infringe upon the legitimate rights and interests of third parties. Otherwise, we have the right to delete the UGC content and temporarily/permanently ban the user who publishes UGC during the content review process or within 24 hours upon receipt of the report.

2. Users shall not use ${appName} account or the Service to make, upload, copy, publish or disseminate the following contents prohibited by laws, regulations and policies:

(1) Opposing the basic principles set forth in the Constitution;

(2) endangering national security, divulging state secrets, subverting state power or undermining national unity;

(3) Injure the honor and interests of the State;

(4) inciting national hatred or discrimination and undermining national unity;

(5) Those who undermine national religious policies and promote evil cults;

(6) Spreading rumors, disturbing social order and undermining social stability;

(7) Spreading obscenity, pornography, gambling, violence, murder, terror or instigating crimes;

(8) insulting or slandering others or infringing upon others' legitimate rights and interests;

(9) The bank does not comply with the bottom line of laws and regulations, the bottom line of national interests, the bottom line of citizens' legitimate rights and interests, the bottom line of social and public order, the bottom line of morality, and the bottom line of information authenticity;

(10) Information containing other contents prohibited by laws and administrative regulations.

3. Users shall not use ${appName}'s account or the Service to make, upload, copy, publish or spread the following contents that interfere with the normal operation of ${appName} or infringe upon the legitimate rights and interests of other users or third parties:

(1) Containing any sex or sexual implication;

(2) Contents of abuse, intimidation or threat;

(3) Contains harassment, spam, malicious information and deceptive information;

(4) Involve others' privacy, personal information or data;

(5) Infringement of others' right of reputation, right of portrait, intellectual property, trade secrets and other legal rights;

(6) Contains other information that interferes with the normal operation of the Service and infringes the legitimate rights and interests of other users or third parties.


Ⅴ.Rules of use

1. Any content or behavior transmitted, published or performed by Users in or through the Service does not reflect or represent, nor shall it be deemed to reflect or represent the views, positions or policies of the Company, for which the Company shall not be liable.

2. User shall not use ${appName} account or the Service for any of the following behaviors, otherwise, the Company may make its own judgment and deal with the situation, and at any time, the Company has the right to terminate the service provided to you without prior notice, and hold the Company liable according to law:

(1) Submitting or publishing false information, or misappropriating others' avatars or information, impersonating or using others' names;

(2) Forcing or inducing other users to pay attention to, click on the linked page or share information;

(3) Using technical means to set up fake accounts in batches;

(4) Use the ${appName} account or the Service to engage in any illegal or criminal activities;

(5) Make and publish methods and tools related to the above acts, or operate or disseminate such methods and tools, whether for commercial purposes or not;

(6) the act of publishing pornographic and pornographic content;

(7) Spreading obscene and pornographic content, including but not limited to soliciting whoring and booty call.

(8) publishing suggestive and suggestive audio, text, GIF, external website links, two-dimensional code and other content.

(9) The act of publishing any advertising related information.

(10) Threatening, slandering and insulting others.

(11) The act of embezzling others' data. Including but not limited to the following misappropriations:

A publishes or uses the registered business name or trademark of others without authorization, and infringes the exclusive right to use the business name and trademark of others.

B. Unauthorized publication and use of others' names, photos, ID numbers, infringement of others' right of reputation, right of portrait and other legal rights.

(12) The act of impersonating the official account of ${appName}, including but not limited to:

A. Publish, use or parody the words with the same or similar content as the ${appName} LOGO and other existing intellectual property rights, or the content easily confused with the design theme and appearance of existing ${appName} products

B. ${appName} and its related products and technologies are maliciously defamed, insulted, or ridiculed.

C impersonates the official customer service of VRClose to send information to the user.

(13) Acts that violate the existing laws and regulations of the State. Including but not limited to the following:

A. Anti-political activities: oppose the basic principles set by the Constitution, endanger national security, subvert state power, incite ethnic hatred, promote cults, illegal assembly, etc.



B Buying and selling drugs, counterfeit money, illegal drugs, fake certificates, etc.

C. False social behaviors, fraudulent behaviors and other dishonest behaviors that violate social morality for the purpose of seeking profits.

The information released by D contains contents such as gathering people to gamble and teaching gambling skills.

E.Hiring or inducing others to engage in acts of terror and violence

(14) Posting blood, violence, horror, disgust and other content that may make people feel uncomfortable. Including but not limited to:

A.Spreading authentic images of people or animals being killed, maimed, and injured by shooting, stabbing, or torture.

B. Contains content depicting violence or child abuse.

C.Release information that may disturb public order, such as revenge for others, violent collection, and declaration of gangster identity.

(15) Publishing phishing website links, website links containing Trojan viruses and QR codes and other contents that may affect the information security of users.

(16) Use of plug-ins and other malicious acts. Including but not limited to the following:

A.Use plug-ins, plugins or other third party tools and services to access VRClose and related systems without the written permission of VRClose.

B.Use plugins and manual activities that affect the normal operation of ${appName}.

C .Maliciously attack VrClose and related systems.

D. Maliciously collecting personal information of the ${appName} system or other ${appName} users.

E.Making and publishing methods and tools related to the above behaviors, or operating or disseminating such methods and tools.

F.The act of registering, logging in, or using a virtual machine.

(17) Gambling or gambling in disguise.

(18) Drainage to other platforms through VRclose may lead to fraud or other illegal acts as judged by the VRclose audit system.

(19) The act of releasing fake base stations and number changing software on the platform. Including but not limited to the following:

A.uses the number changing software to impersonate others to defraud.

B. The behavior of publicizing fake base station/changing number software, such as introducing and publicizing information about fake base station/changing number software; Demonstrate the use effect of fake base station/number change software; Persuade others to use fake base station/number change software, etc.

C.Selling fake base station/changing number software, such as: publishing information about selling fake base station equipment/changing number software; Publish information about fake base station equipment/number change software; Inquire or publish fake base station equipment/change number software sales channels, etc.

(20) Other behaviors that violate national laws and regulations or disturb the social environment of ${appName} platform have been reported by users.

(21) Other acts in violation of laws and regulations, or infringement of other users' legitimate rights and interests, or interference with the normal operation of ${appName} or conduct without express authorization of the Company.

3.Users shall be solely responsible for the authenticity, legality, harmlessness, accuracy and effectiveness of the information transmitted through their ${appName} account or the Service. Any legal liability related to the information transmitted by users shall be borne by users themselves, which has nothing to do with the Company. If any damage is caused to the Company or any third party, the User shall indemnify the Company according to law.

4.You shall not use VRClose to carry out any illegal or criminal activities, including but not limited to money laundering or online gambling activities. VrClose such behaviour will be closely monitored, and shall have the right to abnormalities in multiple directional account, large amount of virtual assets flow investigation, suspected of illegal and criminal behavior is found, the company has the right to take banned account and virtual property, and other measures and cooperate with the competent authority of the survey to submit your id information and transaction record about the account information, etc.

5.Users shall abide by the terms of this Agreement, use the Service correctly and appropriately, and shall not disturb the order of the Platform, including but not limited to, disrupting the financial order of the Platform (such as selling virtual props). If User violates any provision of this Agreement, Company shall have the right to suspend or terminate part or all of the services provided to User's ${appName} account or take other punitive measures in accordance with this Agreement. If any of your account services are terminated/suspended, you will not be able to participate in any official activities. If the account is permanently banned, all the virtual props in the account will be emptied and no cash will be withdrawn. Special attention should be paid to the account suspected of using unreasonable means to recharge (including but not limited to illegal use of credit card cash), the account will be temporarily or permanently closed by VRClose at its reasonable judgment. At the same time, the Company reserves the right to recall the ${appName} account and user name at any time.

6.Without the written consent of ${appName}, the content you broadcast, upload, publish or transmit via ${appName} shall not be displayed, distributed or promoted on any platform that is in competition with ${appName} or that has broadcast or social functions.


VI. Account management

1. The ownership of the ${appName} account shall be owned by the Company. After the completion of the registration procedures, the User shall obtain the right to use the ${appName} account. The Company shall have the right to reclaim the user's ${appName} account due to business needs. If the user is not the original registrant of the account, ${appName} has the right to freeze and recover the account without notice without assuming legal liability to the user of the account. Users shall be liable for any loss caused thereby, including but not limited to communication interruption and data emptied of the user. If there is any dispute over the ownership of the account, the Company has the right to temporarily freeze the disputed account upon receiving the complaint from the relevant party. The disputing parties shall provide evidence to prove the ownership of the account within the evidencing period (7 working days), and the Company shall unfreeze the disputed account after judging the ownership based on the evidence provided by the parties.

2. Users may change or delete personal information, registration information and transmission contents on the account of ${appName}, but it shall be noted that the text and pictures stored in the system will also be deleted when the relevant information is deleted, and users agree to bear the risks mentioned above.

3. Users shall be responsible for the security of their registered account information and account password. Users shall be liable for account theft or password theft due to improper safekeeping. The user shall bear full legal responsibility for the actions under the registered account and password. User agrees not to use another user's account or password under any circumstances. User agrees to immediately notify Company when User suspects that another person is using his account or password. At the same time, the security of your account shall be guaranteed. Some functions and/or certain individual services, including but not limited to UGC content release service and network live broadcast service (if any), shall be used by you according to relevant national laws and regulations. You shall register and log in with your real name before using them.

4. If ${appName} finds that your account has abnormal login and the possibility of account theft is high, you agree that ${appName} has the right to freeze your account. If you need to defrost, please apply through the VrClose feedback channel.

5. Users can recharge and exchange virtual props or use other types of value-added services during the use of ${appName} service. VRClose does not provide the service of refunding, exchanging or cashing into US dollars or any other currency after the user has recharged the money, except as provided by law.

6, such as user registration VrClose account after a year in a row, not logged in, the company shall have the right to cancel, recycling, replace, or to delete the account in VrClose any records in the database (including but not limited to registration information, virtual gifts, etc.), such as cleaning measures, so as not to cause waste of resources, the resulting adverse consequences shall be borne by the user. Before clearing such accounts, you will be notified by means including but not limited to pop-up Windows, in-site messages, client-side push messages, etc. After cleaning, your account will not be able to log in again, and the corresponding services will be terminated at the same time.

7. You can log in VRCloseApp by yourself (VRCloseApp → click "My" icon → click the gear icon in the upper right corner - System Setup Interface → "Account and Security" - click "Account Log Out (Delete Account)" to apply for account logout. Please note that after you successfully submit the account cancellation application according to the above procedures, your account will be cancelled immediately without any manual review. Please read and confirm the Account Cancellation Instructions carefully before you cancel your account. After you voluntarily cancel your account, the Company will make your account anonymous or delete relevant personal information as soon as possible in accordance with the requirements of applicable laws and regulations.


VII. Data storage

1. The Company shall not be responsible for the user's failure to delete or save relevant data in the Service.

2. Company shall have the right to determine the maximum storage period of User's data in the Service in accordance with relevant laws and regulations, and to allocate the maximum storage space for such data on the server. Users can back up the relevant data in the service according to their own needs.

3. If User ceases to use the Service or the Service terminates, Company may permanently delete User's data from the Server. After the cessation or termination of the Service, the Company shall have no obligation to return any data to the User.


VIII. Risk Assumption

1. Users understand and agree that ${appName} only provides users with a platform for information sharing, transmission and acquisition, and users must be responsible for all behaviors under their registered accounts, including any content sent by users and any consequences arising therefrom. Users shall make their own judgment on the Content in VRClose and the Service and bear all risks arising from the use of the Content, including risks arising from reliance on the correctness, completeness or usefulness of the Content. The Company cannot and will not be liable for any loss or damage arising out of the User's actions. If the User finds that any person violates this Agreement or uses the Service in other improper ways, please immediately report or complain to the Company, and the Company will deal with it in accordance with this Agreement.



IX. Intellectual Property Rights

1.In addition to this service is involved in the intellectual property rights by corresponding advertisers have advertising, companies in the services provided in the content (including but not limited to web pages, text, images, audio, video, graphics, etc.) of all intellectual property rights are owned by the company, but users before using this service to your content has been lawfully obtained unless the intellectual property rights.

2.Unless otherwise specially stated, the copyright, patent and other intellectual property rights of the software that the Company relies on to provide the Service shall be owned by the Company.

3.The copyright or trademark right of any graphics, text or its composition involved in the Service, as well as other logos of the Company and its products, service names, service marks, trade names, domain names, website names or other distinctive brand features (hereinafter collectively referred to as "Company logos") shall be owned by the Company. Without the prior written consent of the company, the user may not use this clause the company logo to display, alone or in combination with any way to use or apply for a registered trademark and domain name registration or company logo display or use in any way or for other processing, or to others shows that the user has the right to display, use, or other ACTS of reserves the right to deal with company logo.

4.The intellectual property rights legally owned by the above and any other Company or relevant advertisers shall be protected by law. Without the written permission of the Company or relevant advertisers, Users shall not use or create derivative works in any form.

${appName} respects and protects the intellectual property rights, reputation rights, name rights, privacy rights and other legitimate rights of users and others. You guarantee that the content (including but not limited to text, pictures, music, video, audio, sound, dialogue, links and other forms of content) that you live broadcast, upload, publish or transmit while using the VRClose Service is original or legally authorized by you. You shall ensure that the above content does not infringe the intellectual property rights or other legitimate rights and interests of others, and you do not need to pay any fees to any third party, otherwise, you shall be liable for the losses caused by ${appName} and its affiliates or related cooperative companies.

5.Unless otherwise agreed, you acknowledge, understand and agree that the intellectual property rights of the content (including but not limited to text, pictures, music, video, audio, sound, dialogue, links and other forms of content) broadcast, uploaded, published or transmitted by you through ${appName} service belong to you or the original right holder. You agree with the longest period of time permitted by law, including in the case of the application of law to allow permanent existence) awarded to the company and its affiliated parties, control, inheritance, a free, irrevocable, worldwide, to permit (through multi-level) rights (including but not limited to, copy right, translation rights, assembly, the right of information network transmission right, rights and the property rights make derivatives, performances and other books, etc.), so that VrClose platform for storage, distribution, use, copy, modify, adapt, translate, create derivative works to such user content, distribution, implementation and display, etc., The use of the above rights includes but is not limited to the use of the current or other websites, applications, products or terminal devices. You hereby acknowledge and agree that Company has the right to use and otherwise use or develop the Content (in whole or in part) in any publicity, promotion, advertising, marketing and/or research relating to the Content, the ${appName} Software and related services and the Company's brand, or to license third parties. To avoid doubt, you understand and accept that the grant of the right to include the use of owned or licensed to use, copy, and show you and personal image, image embedded in a content, name, trademarks, service marks, brand, name, logo and company tag (if any) and any other brand, marketing or promotion assets, such as material, the material rights and permissions.

6.Any authorized third party without the written consent of VrClose or the holder of the users may not be the holder of the user generated content on the platform, such as the speech to copy, modify, edit, transfer, use, through the information network dissemination or for other purposes, including but not limited to, by capturing video signal, fetching recorded audio/video, editing, video/audio/text and other forms of content, etc.

7.If the information provided by the service of ${appName} platform or the information uploaded, stored or transmitted by other users infringes your intellectual property rights or the intellectual property rights of a third party, please contact the customer service telephone number or contact information published on the platform to make a complaint, and the platform will verify and deal with it as soon as possible.

8.The Company shall provide technical support for the development and operation of VRClose, and shall have all rights to all data and information generated in the development and operation of VRClose software and related services within the scope permitted by laws and regulations.


X. Legal Liability

1. In the process of using ${appName} service, users can report and complain to the company staff if they find contents involving politics, guns, drugs, violence, gambling, pornography or infringement of their own or the legitimate rights and interests of a third party. After receiving the report and complaint, the company will send a specialist to deal with it in time.

2.If the company found or receive others to report complaints or user violates this agreement, the company shall have the right at any time without further notice to the related content, including but not limited to, user profiles, chat logs review, delete, and depending on the seriousness of the violations bank account be including but not limited to, warning, banned banned penalty, equipment banned, function, and notify the user. Users who are banned due to violation of the user agreement can submit an appeal to the customer service in VrCloseApp of the company. The company will review the appeal and decide whether to change the punishment measures at its own reasonable judgment.

3.The user understands and agrees that, the company shall have the right to according to the reasonable judgment of violation of the relevant laws and regulations or the provisions of this agreement for punishment, in violation of any user to take appropriate legal action, according to the laws and regulations keep relevant information reported to the relevant departments, such as user shall bear all the legal responsibilities and.

4.The User understands and agrees that the User shall indemnify the Company and its cooperative companies and affiliates against and hold them harmless from any claim, demand or loss, including reasonable attorney's fee, claimed by any third party arising out of or arising out of the breach of this Agreement by the User.

5.You guarantee that you are the copyright owner or have obtained legal authorization for the contents (including but not limited to texts, pictures, videos, audio, links, etc.) uploaded and published during the use of the VRClose Service, and the aforementioned contents do not infringe the intellectual property rights, reputation rights, name rights, privacy rights and other rights and legitimate rights of any third party. Otherwise, the Company shall have the right to remove the suspected infringing content upon receipt of the notice from the right party or relevant party. You shall handle all claims made by the third party by yourself and bear all legal liabilities that may arise therefrom, including but not limited to damages, fines, settlement fees, litigation arbitration fees, reasonable attorney's fees, etc. If the Company and its affiliates, the Controlling Company and the Successor Company suffer losses (including but not limited to economic and goodwill losses) as a result of your infringement, you shall indemnify the Company and its affiliates, the Controlling Company and the Successor Company in full for all losses incurred.



XI. Force majeure and other exemptions

1.Users understand and acknowledge that the Service may be interrupted due to force majeure and other risk factors during the use of the Service. Force majeure refers to unforeseeable, insurmountable and unavoidable objective events that have a major impact on one or both parties, including but not limited to natural disasters such as floods, earthquakes, epidemics and storms, as well as social events such as wars, disturbances and government actions. In case of the above situation, the Company will try to cooperate with the relevant units in the first time and make timely repairs. However, the Company and the cooperative units shall be exempted from liability to the extent permitted by law for the losses caused to users or third parties.

2.The Service, like most Internet services, is affected by factors including but not limited to user reasons, network service quality, social environment and other factors, and may be disturbed by various security issues, such as the use of users' information by others, resulting in harassment in real life; Other software downloaded and installed by users or other websites visited by users contain "Trojan horses" and other viruses, which threaten the security of users' computer information and data, and then affect the normal use of the Service. Users should strengthen the awareness of information security and the protection of user data, and pay attention to strengthen password protection to avoid loss and harassment.

3.Users understand and confirm, this service exists because of force majeure, a computer virus or hacker attacks, unstable system, the location of the user, the user shutdown as well as any other technology, such as the Internet, communication lines cause the service interruption or can not meet the requirement of the user's risk, thus leading to the user or the third party for any loss, the company does not undertake any responsibility.

4.If there is any suspicion of borrowing, investment and financing, financial management or other related property of the network information, account information such as password, advertising or promotion, please caution and judge for yourself, for you suffer from loss of profits, business reputation, information or other tangible or intangible losses, the company does not undertake any direct, indirect, incidental, special, derivatives or punitive liability to pay compensation.

5.User understand and confirmed that exist in the process of using this service from any others include misleading, fraudulent, threatening, defamatory, offensive, or illegal information, or infringement of the rights of others to remain anonymous or pseudonymous information, as well as with the behavior of such information, thus lead to the user or the third party for any loss, the company does not undertake any responsibility.

6.User understands and acknowledges that the Company needs to overhaul or maintain the ${appName} platform or related equipment on a regular or irregular basis, and the Company shall not be liable for any interruption of the Service within a reasonable period of time as a result of such circumstances, provided that the Company shall notify the Company in advance.

7.The Company is entitled to deal with violations or breaches of laws and regulations in accordance with the provisions hereof. Such right does not constitute an obligation or commitment of the Company, and the Company cannot guarantee to timely detect violations or breaches of laws and regulations or to deal with them accordingly.

8.The User understands and acknowledges that the Company shall not be liable for any quality defects of the following products or services provided by the Company to the User or for any losses caused thereto:

(1) Services provided by the Company to Users free of charge;

(2) Any products or services provided by the Company as a gift to the User.

9.In no event shall the Company be liable for any indirect, consequential, punitive, incidental, special or penal damages, including loss of profits suffered by the User as a result of his use of VRClose or the Service (even if the Company has been advised of the possibility of such loss). Notwithstanding the contrary provisions of this Agreement, Company's total liability to Customer, for whatever reason or for whatever manner of conduct, shall not exceed the fees (if any) paid by Customer to Company for the use of the Services provided by Company.

10. You understand and agree that the Company does not provide warranties or conditions of any kind, express or implied, with respect to the "VRClose" software and related services, including but not limited to merchantability, fitness for a particular purpose, etc. Your use of the "VrClose" software and related services is at your own risk.

11.You understand and agree that this agreement is intended to ensure comply with national laws and regulations, disgraceful consideration maintenance, protect the legal rights of the user and others company within the scope of the ability to try my best to be carried out in accordance with the relevant laws and regulations, but does not guarantee company judge fully agree with judicial organs and administrative organs of the judgment, the repercussions of such as so you understand and agree to take on its own.



XII. Change, Interruption and Termination of the Service

1.In view of the particularity of network services, User agrees that Company has the right to temporarily change or interrupt part or all of the services (including the paid services). If the Company changes or interrupts the service, the Company shall, as far as possible, notify the Users prior to the change or interruption and shall, as far as possible, provide equivalent alternative services to the affected Users.

2. In case of any of the following circumstances, the Company shall have the right to change, interrupt or terminate the free or paid Services provided to the User without any liability to the User or any third party:

(1) The user is required to submit true information according to the law, but the personal information provided by the user is not true or inconsistent with the registration information and fails to provide reasonable proof;

(2) Users violate relevant laws and regulations or provisions hereof;

(3)In accordance with legal provisions or requirements of competent authorities;

(4) For safety reasons or other necessary circumstances.

3.The user fully understands and agrees that: because business grows need, emergency situation, such as company may unilaterally suspend, restrict or terminate the VrClose software part or all of the services, the company will work hard but does not guarantee that can do advance notice, as so as your company is not liable for damages, loss of user knowledge and agreed to take the risk. Such as company to suspend, restrict or terminate the VrClose software part or all of the services involved in paid services or virtual items/property, the company will be conducted on VrClose announcement ahead of schedule, and inform you about the paid services or virtual items/property of processing methods and processing time, if you do not processed within the time limit set forth in the announcement of your paid services or virtual items/property, the company shall be entitled to the same shall be specified in the announcement after the expiry of the time limit for handling of empty your account all the virtual property/property or other carried interest and not be liable for breach of contract, the user aware of and willing to take the risk.



XIII. Terms of Use for Minors

1. If you are a minor under the age of 18, you should carefully read and agree to this Agreement under the supervision and guidance of your guardian and with the consent of your guardian before using ${appName} software and related services.

2.The company attaches great importance to the protection of minors' personal information. When minors fill in personal information, they should strengthen their awareness of personal protection and treat it with caution, and properly use ${appName} software and related services with the consent of the guardian and under the guidance of the guardian.

3.Minor Users and their guardians understand and confirm that, if you violate laws and regulations and the contents of this Agreement, you and your guardians shall bear all possible legal liabilities in accordance with laws and regulations.

4.Special tips for minors

Minors who use ${appName} software and related services should learn to use the Internet correctly within a reasonable range under the supervision and guidance of their guardians, avoid being addicted to the virtual network space and develop good Internet habits. Young users must abide by the National Convention on Youth Internet Civilization:

(1) Be good at online learning and don't browse bad information;

(2) Be honest and friendly and do not insult or cheat others;

(3) to enhance the sense of self-protection, not casual dating net friends;

(4) To maintain network security and not disrupt network order;

(5) to beneficial physical and mental health, do not indulge in virtual time and space.

To better protect minors' rights and privacy, the company special remind you carefully release include minors the content of the material, once released, you have to get the right holder shall be deemed to be agreed in VrClose software and related services to show the image of minors, the information such as sound, and allows the company on the basis of this agreement use and process the content related to minors.

5. guardian special prompt

(1) If your ward uses ${appName} software and related services, you, as the guardian, shall guide and supervise the registration and use of the ward. If your ward applies for the registration of ${appName} account, the Company shall have the right to consider that it has obtained your consent

(2) Your ward may use recharge, reward (if any) and other functions when using ${appName} software and related services. As your guardian, please take good care of your payment equipment, payment account and payment password, etc., in order to avoid the use of recharge, reward (if any) and other functions by your ward through your VrClose account without your consent.



XIV Miscellaneous

1.The Company solemnly reminds users to pay attention to the provisions in this Agreement which exempt the Company from liability and limit users' rights. Users are requested to read carefully and consider risks independently.

2.The validity, interpretation and dispute resolution of this Agreement shall be governed by the laws of the country where the User is located.

3.If any provision of this Agreement is invalid or unenforceable for any reason, the remaining provisions shall remain valid and binding on both parties.

4.For the healthy and orderly operating VrClose and provide you with better service or by national laws and regulations and policy adjustment, technical conditions, product function changes, the company will timely revise or add other types of binding agreements to this agreement/rules, including but not limited to, platform management norms, community convention, user violation management rules, such as content of revised or new binding agreements/rules shall form part of this agreement. After this Agreement is amended or binding agreements/rules are added, the Company will publish the updated version or new agreements/rules on the "VrClose" platform so that you can keep abreast of the latest version or new agreements/rules of this Agreement. If you continue to use the "VrClose" software and related services after the update of this Agreement or the release of the new agreements/rules, you shall mean that you have agreed to accept the revised contents of this Agreement and the new binding agreements/rules. If you have any objection to the contents of the revised Agreement or the added binding agreements/rules, please immediately stop logging in or using the "VrClose" software and relevant services. If you continue to log in or use the "VrClose" software and the related services, you shall be deemed to have accepted and voluntarily accepted the contents of the revised Agreement and the newly added Agreement/Rules.

5.The headings in this Agreement are for ease of reading only and shall not affect the meaning or interpretation of any provision of this Agreement.

6.You and the Company are independent entities. Under no circumstances does this Agreement constitute any kind of express or implied warranty or condition of the Company to you, nor does it constitute an agency, partnership, joint venture or employment relationship between the parties.
`;
    }

}
export default Protocol;
