import {inject, observer} from "mobx-react";
import React from "react";
import {Button, Checkbox, Image, List, message, Modal, Typography, Input, Tag} from "antd";
import CheckCircleOutlined from "@ant-design/icons/lib/icons/CheckCircleOutlined";
import StopOutlined from "@ant-design/icons/lib/icons/StopOutlined";
import ReloadOutlined from "@ant-design/icons/lib/icons/ReloadOutlined";
import {Player} from "video-react";
import PlayCircleOutlined from "@ant-design/icons/lib/icons/PlayCircleOutlined";
import {TIPS_TYPE_STR, videoPageSize} from "../../constants/Constants";
import {formatDuration} from "../../util/DateUtil";
import {formatVideoStatus} from "../../util/StatusUtil";

const { Search } = Input;


@inject("windowStore", "userStore", "navBarStore")
@observer
class VideoListView extends React.Component {

    constructor(props) {
        super(props);
        this.windowStore = this.props.windowStore;
        this.userStore = this.props.userStore;
        this.navBarStore = this.props.navBarStore;
        this.state={
            videoList: [],
            columns: [
                {
                    flex: 0.5,
                    title: '选择',
                    dataIndex: 'checkbox',
                    key: 'checkbox',
                },
                {
                    flex: 1,
                    title: 'ID',
                    dataIndex: 'id',
                    key: 'id',
                },
                {
                    flex: 1,
                    title: '可见范围',
                    dataIndex: 'videoStatus',
                    key: 'videoStatus',
                },
                {
                    flex: 3,
                    title: '标题',
                    dataIndex: 'title',
                    key: 'title',
                },
                {
                    flex: 1,
                    title: '视频链接',
                    dataIndex: 'uri',
                    key: 'uri',
                },
                {
                    flex: 1,
                    title: '视频封面',
                    dataIndex: 'thumbnailUri',
                    key: 'thumbnailUri',
                },
                {
                    flex: 1,
                    title: '时长',
                    dataIndex: 'duration',
                    key: 'duration',
                },
                {
                    flex: 1,
                    title: '贴士',
                    dataIndex: 'tips',
                    key: 'tips',
                },
                {
                    flex: 1,
                    title: '提案',
                    dataIndex: 'clues',
                    key: 'clues',
                },
                {
                    flex: 1,
                    title: '上传时间',
                    dataIndex: 'createTime',
                    key: 'createTime',
                },
                {
                    flex: 1.5,
                    title: '上传者',
                    dataIndex: 'upper',
                    key: 'upper',
                }
            ],

            blocking: false,
            loading: false,
            isLastPage: false,
            emptyList: false,
            checkIds: [],

            locationPath: null,

            showVideo: false,
            showVideoUri: null,

            showTips: false,
            tipVideo: null,
            tipColumnItem: null,

            showImage: false,
            imageSrc: null,

        }
    }

    componentDidMount() {
        this._fetchVideos('refresh');
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const locationPath = window.location.pathname;
        if(this.state.locationPath !== locationPath){
            this.setState({
                locationPath: locationPath,
                videoList: [],
                isLastPage: false,
                emptyList: false,
                checkIds: [],
            }, () => {
                this._fetchVideos('refresh');
            })
        }
    }

    render(){
        const {
            videoList, blocking, isLastPage, loading, emptyList, columns, checkIds,
            showVideo, showVideoUri, showTips, tipVideo, tipColumnItem, showImage, imageSrc
        } = this.state;
        const locationPath = window.location.pathname;
        const isBlockList = locationPath.indexOf("block") > -1;
        const navBarHeight = this.navBarStore.height;
        const {width, height, col} = this.windowStore;
        const cols = ["XS", "S", "M", "L", "XL"];
        const colIndex = cols.indexOf(col);
        const smallThenL = colIndex < 4;
        const hasSelected = checkIds.length > 0;

        const loadMore = !loading ? (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    marginBottom: 12,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button disabled={isLastPage} onClick={() => {this._fetchVideos("loadMore")}}>
                    {isLastPage ? "已无更多" : "加载更多"}
                </Button>
            </div>
        ) : null;

        return (
            <div style={{width: "100%", display: 'flex', flexDirection: 'row'}}>
                <div style={{width: smallThenL ? 60 : 290, height: 100}}/>
                <div style={{width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                    <Modal
                        title="视频"
                        centered
                        visible={showVideo}
                        width={500}
                        cancelText={'关闭'}
                        onOk={() => this._hideVideo()}
                        onCancel={() => this._hideVideo()}
                    >
                        <Player
                            ref={(ref) => this.videoModal = ref}
                            src={showVideoUri}>

                        </Player>
                    </Modal>
                    <Modal
                        title="Tips"
                        centered
                        visible={showTips}
                        width={1000}
                        cancelText={'关闭'}
                        onOk={() => this._hideTips()}
                        onCancel={() => this._hideTips()}
                    >
                        {
                            showTips ? this._renderTips(tipVideo, tipColumnItem) : null
                        }
                    </Modal>
                    <Modal
                        title="图片"
                        centered
                        visible={showImage}
                        width={548}
                        cancelText={'关闭'}
                        onOk={() => this._hideImage()}
                        onCancel={() => this._hideImage()}
                    >
                        {
                            showImage ? <Image src={imageSrc} style={{width: 500}}/> : null
                        }
                    </Modal>
                    <div style={{width: "80%", marginTop: 16, marginBottom: 16, marginLeft: 16, display: 'flex', flexDirection: 'row'}}>
                        <Button
                            type="default"
                            onClick={() => {this._blockVideos(isBlockList)}}
                            disabled={!hasSelected}
                            loading={blocking}
                            icon={isBlockList ? <CheckCircleOutlined /> : <StopOutlined/>}
                        >
                            {isBlockList ? "解封" : "封禁"}
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => this._fetchVideos('refresh')}
                            style={{marginLeft: 20}}
                            loading={loading}
                            icon={<ReloadOutlined />}
                        >
                            刷新
                        </Button>
                        <div style={{width: "90%", alignItems: 'center'}}>
                            <Search
                                placeholder="输入视频ID搜索"
                                onSearch={(value) => this._fetchVideoById(value)}
                                loading={loading}
                                enterButton
                                style={{width: 200}}
                            />
                            <Button
                                type="default"
                                onClick={() => this._fetchVideos('refresh')}
                                style={{marginLeft: 10}}
                                loading={loading}
                                icon={<ReloadOutlined />}
                            >
                                重置
                            </Button>
                        </div>
                    </div>

                    <div
                        style={{
                            width: "100%",
                            height: 55,
                            backgroundColor: '#dedede',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            borderBottom: '1px solid #f0f0f0'
                        }}
                    >
                        {
                            columns.map((columnItem, index) => {
                                return (
                                    <div style={{flex: columnItem.flex, height: 55, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30}}>
                                        <Typography.Paragraph strong={true}>{columnItem.title}</Typography.Paragraph>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <List
                        className="videoList"
                        loading={false}
                        itemLayout="horizontal"
                        style={{width: "100%", height: height - navBarHeight - 120, overflowY: 'scroll'}}
                        loadMore={loadMore}
                        dataSource={videoList}
                        renderItem={(video, index) => (
                            <div
                                key={index}
                                style={{
                                    width: "100%",
                                    height: 50,
                                    backgroundColor: '#fff',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-start',
                                    borderBottom: '1px solid #f0f0f0'
                                }}
                            >
                                {
                                    columns.map((columnItem, cIndex) => {
                                        const isCheckbox = columnItem.dataIndex === "checkbox";
                                        const isVideo = columnItem.dataIndex === "uri";
                                        const isThumbnail = columnItem.dataIndex === "thumbnailUri";
                                        const isUpper = columnItem.dataIndex === "upper";
                                        const isTips = columnItem.dataIndex === "tips";
                                        const isClues = columnItem.dataIndex === "clues";
                                        const isStatus = columnItem.dataIndex === "videoStatus";
                                        const isDuration = columnItem.dataIndex === "duration";

                                        let info = video[columnItem.dataIndex];

                                        if(isCheckbox){
                                            info = (
                                                <Checkbox
                                                    checked={checkIds.indexOf(parseInt(video.id)) > -1}
                                                    disabled={loading}
                                                    onChange={(e) => this._onCheck(e.target.checked, video.id, video.videoStatus, video.upper.id)}
                                                >

                                                </Checkbox>
                                            )
                                        }else if(isDuration){
                                            info = formatDuration(info);
                                        }else if(isStatus){
                                            info = <Tag>{formatVideoStatus(video.videoStatus)}</Tag>;
                                        }else if(isVideo){
                                            info = (
                                                <div
                                                    style={{
                                                        width: 200,
                                                        height: 50,
                                                        display: 'flex',
                                                        flexDirection: 'row'
                                                    }}
                                                >
                                                    <div
                                                        onClick={() => {this._openVideo(video[columnItem.dataIndex])}}
                                                        style={{
                                                            width: 50,
                                                            height: 50,
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <Button icon={<PlayCircleOutlined />}/>
                                                    </div>
                                                </div>
                                            )
                                        }else if(isThumbnail){
                                            info = (
                                                <Image
                                                    src={info}
                                                    style={{
                                                        width: 25,
                                                        height: 40,
                                                    }}
                                                />
                                            )
                                        }else if(isUpper){
                                            const nickname = video[columnItem.dataIndex].nickname;
                                            const profile = video[columnItem.dataIndex].profile;
                                            const crossOrigin = profile == null ? false : profile.indexOf("vrclose") > -1;

                                            info = (
                                                <div style={{display: 'flex', flexDirection: 'row', alignSelf: 'center', justifyContent: 'center', alignItems: 'flex-start'}}>
                                                    {
                                                        profile == null ? null :
                                                            <Image
                                                                crossOrigin={crossOrigin ? null : "anonymous"}
                                                                src={profile}
                                                                style={{
                                                                    width: 40,
                                                                    height: 40,
                                                                    borderRadius: 50,
                                                                    marginRight: 10
                                                                }}
                                                            />
                                                    }
                                                    <span
                                                        style={{
                                                            width: "100%",
                                                            overflowX: 'hidden',
                                                            fontWeight: 'bold',
                                                            fontSize: 20,
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignSelf: 'flex-start'
                                                        }}
                                                    >
                                                        {nickname}
                                                    </span>
                                                </div>
                                            )
                                        }else if(isTips){
                                            const hasTips = video[columnItem.dataIndex] != null && video[columnItem.dataIndex].length > 0;
                                            if(hasTips){
                                                info = (
                                                    <Button
                                                        onClick={() => this._showTips(video, columnItem)}
                                                        type={"primary"}>
                                                        查看
                                                    </Button>
                                                );
                                            }else {
                                                info = (<Button disabled={true} type={"default"}>没有</Button>);
                                            }

                                        }else if(isClues){
                                            info = null;
                                        }

                                        return (
                                            <div
                                                key={cIndex}
                                                style={{flex: columnItem.flex, height:  50, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30}}>
                                                <span>{info}</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )}
                    />

                </div>
            </div>
        )
    }

    /**
     * 根据ID精确查询
     * @param value
     * @private
     */
    _fetchVideoById = (value) => {
        if(value != null && value !== ""){
            const isNan = isNaN(value);
            if(!isNan){
                this.setState({loading: true});

                const locationPath = window.location.pathname;
                const videoStatusType = locationPath.indexOf("/normal") > -1 ? 'normal' : 'block';
                const token = this.userStore.user.token;
                const videoId = value.toString();
                const url = `/apiB/video/${videoStatusType}/${videoId}`;

                let isSuccess = false;

                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Authorization': token,
                        'Accept': 'application/json'
                    },
                })
                    .then((response) => {
                        isSuccess = response.ok;
                        return response.json();
                    })
                    .then((json) => {
                        if(isSuccess){
                            const newArr = [json];
                            this.setState({
                                videoList: newArr,
                                isLastPage: true,
                                checkIds: [],
                            })
                        }else {
                            message.error("该视频不存在");
                        }
                        this.setState({loading: false});
                    })
                    .catch((err) => {
                        this.setState({
                            loading: false,
                            videoList: [],
                            isLastPage: true,
                            checkIds: [],
                        });
                        message.error("该视频不存在");
                    });

            }
        }

    };

    /**
     * 更新视频状态
     * @param isBlockList
     * @private
     */
    _blockVideos = (isBlockList) => {
        const {checkIds, checkStatus, checkUpperIds} = this.state;
        const token = this.userStore.user.token;

        if(checkIds.length > 0){
            this.setState({blocking: true});

            const url = "/apiB/video/block";
            const formData = new FormData();
            checkIds.map((vid, index) => {
                formData.append("checkIdsStr", vid.toString());
                formData.append("checkUpperIdsStr", checkUpperIds[index].toString());
                formData.append("checkStatus", checkStatus[index]);
            });

            let isSuccess = false;
            fetch(url, {
                method: !isBlockList ? 'POST' : 'DELETE',
                headers: {
                    'Authorization': token,
                    'Accept': 'application/json'
                },
                body: formData,
            })
                .then((response) => {
                    isSuccess = response.ok;
                    return response.json();
                })
                .then((json) => {
                    if(isSuccess){
                        let {videoList} = this.state;
                        let newArr = [];
                        videoList.map((video, index) => {
                            if(checkIds.indexOf(parseInt(video.id)) === -1){
                                newArr.push(video);
                            }
                        });

                        this.setState({
                            checkIds: [],
                            checkUpperIds: [],
                            checkStatus: [],
                            videoList: newArr
                        }, () => {
                            message.success("封禁成功");
                        })
                    }else {
                        message.error("封禁失败，请重新操作");
                    }
                    this.setState({blocking: false});
                })
                .catch((err) => {
                    this.setState({blocking: false});
                });
        }
    };

    /**
     * 拉取视频列表
     * @param fetchType
     * @private
     */
    _fetchVideos = (fetchType) => {
        const {loading, videoList} = this.state;
        if(!loading){
            this.setState({loading: true});

            const locationPath = window.location.pathname;
            const videoStatusType = locationPath.indexOf("/normal") > -1 ? 'normal' : 'block';
            const token = this.userStore.user.token;
            const listSize = videoList.length;
            const isRefresh = fetchType === "refresh";
            const lastItemId = !isRefresh && listSize > 0 ? videoList[listSize - 1].id.toString() : "-1";
            const url = `/apiB/video/list/${videoStatusType}/${lastItemId}`;

            let isSuccess = false;
            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
                .then((response) => {
                    isSuccess = response.ok;
                    return response.json();
                })
                .then((json) => {
                    if(isSuccess){
                        let isEmpty = false;
                        if(json.length === 0){
                            if(videoList.length === 0){
                                isEmpty = true;
                            }
                            this.setState({
                                emptyList: isEmpty,
                                isLastPage: true,
                                loading: false
                            });
                        }else {
                            let isLastPage = false;
                            let newList = isRefresh ? [] : videoList.length > 0 ? [].concat(videoList) : [];
                            json.map((video, index) => {
                                video['key'] = index;
                                newList.push(video);
                            });
                            if (json.length < videoPageSize) {
                                isLastPage = true;
                            }
                            this.setState({
                                loading: false,
                                isLastPage: isLastPage,
                                videoList: newList,
                                emptyList: isEmpty
                            })
                        }
                    }
                    this.setState({loading: false});
                })
                .catch((err) => {
                    this.setState({loading: false});
                });

        }
    };

    /**
     * 打开链接
     * @param src
     * @private
     */
    _showSrc = (src) => {
        if(src != null && src.indexOf("http") > -1){
            if(src.endsWith(".png") || src.endsWith(".jpg")){
                this._showImage(src);
            }else {
                window.open(src);
            }
        }
    };

    /**
     * 关闭视频
     * @private
     */
    _hideVideo = () => {
        this.videoModal.pause();
        this.setState({
            showVideo: false,
            showVideoUri: null
        })
    };

    /**
     * 打开视频
     * @param uri
     * @private
     */
    _openVideo = (uri) => {
        this.setState({
            showVideo: true,
            showVideoUri: uri,
        }, () => {
            this.videoModal.play();
        })
    };

    /**
     * 关闭Tips
     * @private
     */
    _hideTips = () => {
        this.setState({
            showTips: false,
            tipVideo: null,
            tipColumnItem: null
        })
    };

    /**
     * 打开Tips
     * @param video
     * @param columnItem
     * @private
     */
    _showTips = (video, columnItem) => {
        this.setState({
            showTips: true,
            tipVideo: video,
            tipColumnItem: columnItem
        });
    };

    /**
     * 关闭图片
     * @private
     */
    _hideImage = () => {
        this.setState({
            showImage: false,
            imageSrc: null,
        })
    };

    /**
     * 预览图片
     * @param src
     * @private
     */
    _showImage = (src) => {
        this.setState({
            showImage: true,
            imageSrc: src,
        })
    };

    /**
     * 渲染Tips表单
     * @param video
     * @param columnItem
     * @returns {*}
     * @private
     */
    _renderTips = (video, columnItem) => {
        const tips = video[columnItem.dataIndex];
        return (
            <div style={{flex: columnItem.flex, maxHeight: 500}}>
                <div style={{width: "100%", height: 30, display: 'flex', flexDirection: 'row', backgroundColor: 'rgba(250,250,250,1)'}}>
                    <Typography.Paragraph style={{flex: 1}} strong={true}>类型</Typography.Paragraph>
                    <Typography.Paragraph style={{flex: 6}} strong={true}>链接</Typography.Paragraph>
                    <Typography.Paragraph style={{flex: 2}} strong={true}>标题</Typography.Paragraph>
                    <Typography.Paragraph style={{flex: 1}} strong={true}>单位</Typography.Paragraph>
                </div>
                <List
                    className="tipList"
                    loading={false}
                    itemLayout="horizontal"
                    style={{width: "100%", maxHeight: 470, overflowY: 'auto', display: 'flex', justifyContent: 'flex-start', }}
                    dataSource={tips}
                    renderItem={(tip, tipIndex) => {
                        const tipType = TIPS_TYPE_STR[tip.type];
                        const tipSrc = tip.src;
                        const tipTitle = tip.title;
                        const tipUnit = tip.unit;

                        return (
                            <div
                                key={tipIndex}
                                style={{width: "100%", display: 'flex', flexDirection: 'row'}}
                            >
                                <div style={{width: "10%", height: 30}}>
                                    <Typography.Paragraph style={{width: "100%"}}>
                                        {tipType}
                                    </Typography.Paragraph>
                                </div>
                                <div style={{width: "60%", height: 30, }}>
                                    <Typography.Paragraph
                                        style={{width: "100%", overflow: 'hidden'}}
                                        ellipsis={true}
                                        underline={tipSrc !== null}
                                        onClick={() => {this._showSrc(tipSrc)}}
                                    >
                                        {tipSrc == null ? '无' : tipSrc}
                                    </Typography.Paragraph>
                                </div>
                                <div style={{width: "20%", height: 30}}>
                                    <Typography.Paragraph style={{flex: 1}} ellipsis={true}>
                                        {tipTitle == null ? '无' : tipTitle}
                                    </Typography.Paragraph>
                                </div>
                                <div style={{width: "10%", height: 30}}>
                                    <Typography.Paragraph style={{flex: 1}} ellipsis={true}>
                                        {tipUnit == null ? '无' : tipUnit}
                                    </Typography.Paragraph>
                                </div>
                            </div>
                        )
                    }}
                />
            </div>
        )
    };

    /**
     * 选中或取消选中
     * @param checked       选中状态
     * @param videoId       视频ID
     * @param videoStatus   视频状态
     * @param upperId       upper的ID
     * @private
     */
    _onCheck = (checked, videoId, videoStatus, upperId) => {
        let {checkIds, checkStatus, checkUpperIds} = this.state;
        if(checked){
            if(checkIds.indexOf(parseInt(videoId)) === -1){
                const notEmpty = checkIds.length > 0;
                let newArr = notEmpty ? [].concat(checkIds) : [parseInt(videoId)];
                let newStatusArr = notEmpty ? [].concat(checkStatus) : [parseInt(videoStatus)];
                let newUpperArr = notEmpty ? [].concat(checkUpperIds) : [parseInt(upperId)];

                if(notEmpty){
                    newArr.push(parseInt(videoId));
                    newStatusArr.push(parseInt(videoStatus));
                    newUpperArr.push(parseInt(upperId));
                }
                this.setState({
                    checkIds: newArr,
                    checkStatus: newStatusArr,
                    checkUpperIds: newUpperArr
                });
            }
        }else {
            const arrIndex = checkIds.indexOf(parseInt(videoId));
            if(arrIndex > -1){
                let newArr = [].concat(checkIds);
                let newStatusArr = [].concat(checkStatus);
                let newUpperArr = [].concat(checkUpperIds);

                newArr.splice(arrIndex, 1);
                newStatusArr.splice(arrIndex, 1);
                newUpperArr.splice(arrIndex, 1);

                this.setState({
                    checkIds: newArr,
                    checkStatus: newStatusArr,
                    checkUpperIds: newUpperArr
                });
            }
        }
    };

}

export default VideoListView;
