import { observable, action } from "mobx";

class UserStore {
    @observable
    user={
        id: null,
        nickname: null,
        username: null,
        token: null,
        tel: null,
        profile: null,
        sex: null
    };

    constructor(){
        this.user = {
            id: null,
            nickname: null,
            username: null,
            token: null,
            tel: null,
            profile: null,
            sex: null
        }
    }

    @action
    setUser = (user) => {
        this.user = user;
    };

    @action
    logout = () => {
        this.user = {
            id: null,
            nickname: null,
            username: null,
            token: null,
            tel: null,
            profile: null,
            sex: null
        }
    }
}

const userStore = new UserStore();

export {userStore};
