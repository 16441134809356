import {inject, observer} from "mobx-react";
import React from "react";
import {Button, Carousel, Image, Row, Tag, Typography} from "antd";
import intro1 from '../../imgs/intro1.jpg';
import intro2 from '../../imgs/intro2.jpg';
import intro3 from '../../imgs/intro3.jpg';
import intro4 from '../../imgs/intro4.jpg';
import iphoneX from '../../imgs/iphoneX.png';
import bgHome from '../../imgs/bg_home.png';
import {isDev} from "../../constants/Constants";

@inject("windowStore")
@observer
class Intro extends React.Component {

    constructor(props) {
        super(props);
        this.windowStore = this.props.windowStore;
        this.state={

        }
    }

    render() {
        const {width, height, col} = this.windowStore;
        const isMobile = height / width > 1;
        const picWidth = isMobile ? '100%' : 0.15 * width;
        const picHeight = isMobile ? '100%' : 0.32 * width;
        const picPadding = isMobile ? 0 : 0.1 * width;
        const tips = ["①Pie charts", "②Pillar charts", "③Linear charts", "④Image", "⑤Landing guide", "⑥Screenshot", "⑦Link", "⑧Hand-painted"];

        return (
            <div style={{width: '100%', height: 'auto', backgroundColor: '#fff', overflow: 'hidden', overflowY: isMobile ? 'scroll' : 'hidden'}}>
                <div
                    style={{
                        width: width,
                        height: width / 4,
                        backgroundImage: `url("${bgHome}")`,
                        backgroundSize: "cover",
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}
                >
                    <Typography.Title style={{fontSize: '70pt', color: 'rgba(250,250,250,0.5)'}}>
                        VrClose
                    </Typography.Title>
                    <Typography.Title style={{fontSize: '20pt', color: 'rgba(250,250,250,0.5)', marginTop: 5}}>
                        REDEFINE SHORT VIDEO
                    </Typography.Title>
                </div>
                {
                    isMobile ?
                        <Carousel>
                            <Image
                                src={intro1}
                                style={{width: picWidth, height: picHeight, boxShadow: '0px 0px 30pt #ccc'}}
                            />
                            <Image
                                src={intro2}
                                style={{width: picWidth, height: picHeight, boxShadow: '0px 0px 30pt #ccc', marginLeft: picPadding, marginRight: picPadding}}
                            />
                            <Image
                                src={intro3}
                                style={{width: picWidth, height: picHeight, boxShadow: '0px 0px 30pt #ccc'}}
                            />
                            <Image
                                src={intro4}
                                style={{width: picWidth, height: picHeight, boxShadow: '0px 0px 30pt #ccc'}}
                            />
                        </Carousel>
                        :
                        <div style={{width: '100%', height: picHeight * 4, justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                            <div style={{width: '100%', height: picHeight, display: 'flex', flexDirection: 'row', backgroundColor: 'rgba(243,243,243,0.8)'}}>
                                <div style={{width: picWidth, height: picHeight, marginLeft: 0.15 * width,}}>
                                    <Image
                                        src={iphoneX}
                                        style={{
                                            width: 0.9 * picWidth,
                                            height: 0.9 * picHeight,
                                            transform: 'rotateX(60deg) rotateZ(-30deg)',
                                            display: 'flex',
                                            position: 'relative',
                                            top: 20,
                                            right: 20
                                        }}
                                    />
                                    <Image
                                        src={intro1}
                                        style={{
                                            width: 0.8 * picWidth,
                                            height: 0.8 * picHeight,
                                            boxShadow: '0px 0px 30pt #ccc',
                                            transform: 'rotateX(60deg) rotateZ(-30deg)',
                                            position: 'relative',
                                            top: -(picHeight - 100)
                                        }}
                                    />
                                </div>
                                <div style={{width: 0.7 * width, height: picHeight, textAlign: 'left', padding: 80, paddingLeft: 0.1 * width}}>
                                    <div style={{width: 0.7 * width}}>
                                        <Tag color={"blue"} style={{fontSize: 40, padding: 30, paddingTop: 20, paddingBottom: 20, borderRadius: 15}}>
                                            V-Tips
                                        </Tag>
                                    </div>
                                    <div style={{width: 0.7 * width, display: 'flex', flexDirection: 'column', marginTop: 20}}>
                                        {
                                            tips.map((tip) => {
                                                return (
                                                    <Typography.Text style={{marginTop: 10, fontSize: 30, fontWeight: 100, lineHeight: 1.2}}>
                                                        {tip}
                                                    </Typography.Text>
                                                )
                                            })
                                        }
                                        <Typography.Text style={{marginTop: 20, fontSize: 30}}>
                                            Make every message more intuitive
                                        </Typography.Text>
                                    </div>
                                </div>
                            </div>
                            <div style={{width: '100%', height: picHeight, display: 'flex', flexDirection: 'row'}}>
                                <div style={{width: 0.7 * width, height: picHeight, textAlign: 'right', padding: 80, paddingRight: 0.1 * width}}>
                                    <div style={{width: 0.6 * width}}>
                                        <Tag color={"blue"} style={{fontSize: 40, padding: 30, paddingTop: 20, paddingBottom: 20, borderRadius: 15}}>
                                            V-Album
                                        </Tag>
                                    </div>
                                    <div style={{width: 0.6 * width, display: 'flex', flexDirection: 'column', marginTop: 20}}>
                                        <Typography.Text style={{marginTop: 20, fontSize: 30, lineHeight: 1.2}}>
                                            Infinitely subdivided albums
                                        </Typography.Text>
                                        <Typography.Text style={{marginTop: 20, fontSize: 30, lineHeight: 1.2}}>
                                            Make categorization easier
                                        </Typography.Text>
                                    </div>
                                </div>
                                <div style={{width: picWidth, height: picHeight, marginRight: 0.15 * width}}>
                                    <Image
                                        src={iphoneX}
                                        style={{
                                            width: 0.9 * picWidth,
                                            height: 0.9 * picHeight,
                                            transform: 'rotateX(-60deg) rotateZ(30deg)',
                                            display: 'flex',
                                            position: 'relative',
                                            top: 20,
                                            left: 20
                                        }}
                                    />
                                    <Image
                                        src={intro2}
                                        style={{
                                            width: 0.8 * picWidth,
                                            height: 0.8 * picHeight,
                                            boxShadow: '0px 0px 30pt #ccc',
                                            transform: 'rotateX(-60deg) rotateZ(30deg)',
                                            position: 'relative',
                                            top: -(picHeight - 100)
                                        }}
                                    />
                                </div>
                            </div>
                            <div style={{width: '100%', height: picHeight, display: 'flex', flexDirection: 'row', backgroundColor: 'rgba(243,243,243,0.8)'}}>
                                <div style={{width: picWidth, height: picHeight, marginLeft: 0.15 * width,}}>
                                    <Image
                                        src={iphoneX}
                                        style={{
                                            width: 0.9 * picWidth,
                                            height: 0.9 * picHeight,
                                            transform: 'rotateX(60deg) rotateZ(-30deg)',
                                            display: 'flex',
                                            position: 'relative',
                                            top: 20,
                                            right: 20
                                        }}
                                    />
                                    <Image
                                        src={intro3}
                                        style={{
                                            width: 0.8 * picWidth,
                                            height: 0.8 * picHeight,
                                            boxShadow: '0px 0px 30pt #ccc',
                                            transform: 'rotateX(60deg) rotateZ(-30deg)',
                                            position: 'relative',
                                            top: -(picHeight - 100)
                                        }}
                                    />
                                </div>
                                <div style={{width: 0.7 * width, height: picHeight, textAlign: 'left', padding: 80, paddingLeft: 0.1 * width}}>
                                    <div style={{width: 0.7 * width}}>
                                        <Tag color={"blue"} style={{fontSize: 40, padding: 30, paddingTop: 20, paddingBottom: 20, borderRadius: 15}}>
                                            V-Project
                                        </Tag>
                                    </div>
                                    <div style={{width: 0.7 * width, display: 'flex', flexDirection: 'column', marginTop: 20}}>
                                        <Typography.Text style={{marginTop: 20, fontSize: 30, lineHeight: 1.2}}>
                                            Covering life, work, entertainment and other types of projects
                                        </Typography.Text>
                                        <Typography.Text style={{marginTop: 20, fontSize: 30, lineHeight: 1.2}}>
                                            Make every video more meaningful
                                        </Typography.Text>
                                    </div>
                                </div>
                            </div>
                            <div style={{width: '100%', height: picHeight, display: 'flex', flexDirection: 'row'}}>
                                <div style={{width: 0.7 * width, height: picHeight, textAlign: 'right', padding: 80, paddingRight: 0.1 * width}}>
                                    <div style={{width: 0.6 * width}}>
                                        <Tag color={"blue"} style={{fontSize: 40, padding: 30, paddingTop: 20, paddingBottom: 20, borderRadius: 15}}>
                                            V-Resume
                                        </Tag>
                                    </div>
                                    <div style={{width: 0.6 * width, display: 'flex', flexDirection: 'column', marginTop: 20}}>
                                        <Typography.Text style={{marginTop: 20, fontSize: 30, lineHeight: 1.2}}>
                                            A vibrant resume spreads more information
                                        </Typography.Text>
                                        <Typography.Text style={{marginTop: 20, fontSize: 30, lineHeight: 1.2}}>
                                            V era makes everything more possible
                                        </Typography.Text>
                                    </div>
                                </div>
                                <div style={{width: picWidth, height: picHeight, marginRight: 0.15 * width}}>
                                    <Image
                                        src={iphoneX}
                                        style={{
                                            width: 0.9 * picWidth,
                                            height: 0.9 * picHeight,
                                            transform: 'rotateX(-60deg) rotateZ(30deg)',
                                            display: 'flex',
                                            position: 'relative',
                                            top: 20,
                                            left: 20
                                        }}
                                    />
                                    <Image
                                        src={intro4}
                                        style={{
                                            width: 0.8 * picWidth,
                                            height: 0.8 * picHeight,
                                            boxShadow: '0px 0px 30pt #ccc',
                                            transform: 'rotateX(-60deg) rotateZ(30deg)',
                                            position: 'relative',
                                            top: -(picHeight - 100)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                }

                {
                    isDev ?
                        <div style={{width: "100%", height: 200, backgroundColor: '#ccc', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Typography.Text style={{color: '#000', fontSize: '15pt'}}>
                                Copyright © 2021 edcturbo.cn | 梅香道  <a style={{color: '#1c8efe', fontSize: '15pt'}} href="https://beian.miit.gov.cn/#/Integrated/index">桂ICP备17004753号</a>
                            </Typography.Text>
                        </div>
                        :
                        <div>
                            <Button
                                onClick={() => {window.open("https://play.google.com/store/apps/details?id=com.edcturbo.vrclose")}}
                                type={"primary"}
                                size={"large"}
                                style={{width: '200pt', marginTop: 100, borderRadius: 100}}
                            >
                                Download
                            </Button>
                            <div style={{width: "100%", height: height - picHeight - (isMobile ? 0 : 200) - 40 - 150}}>
                            </div>
                        </div>
                }

            </div>
        )
    }

}

export default Intro;
