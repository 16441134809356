import React from 'react';
import {observer, inject} from "mobx-react";
import {action, observable} from "mobx";
import {
    Avatar,
    Button,
    Icon,
    Input,
    Row,
    Col,
    Typography,
    Radio,
    AutoComplete
} from "antd";
import '../Login/Login.css';
import './SignUp.css';
import Toast from "antd-mobile/es/toast/index";
import SwipeableViews from 'react-swipeable-views';
import InputGroup from '../InputGroup/index';
import * as StringUtil from '../../util/StringUtil';

const RadioGroup = Radio.Group;

@inject("windowStore")
@observer
class SignUp extends React.Component {

    @observable
    user;

    @observable
    passwordInputType;

    @observable
    allCorrect;

    @observable
    step;

    @observable
    phoneCorrect;

    @observable
    smsCode;

    @observable
    disabledAutoComplete;

    constructor(props) {
        super(props);

        this.windowStore = this.props.windowStore;

        this.state = {};

        this.user = {
            username: null,
            password: null,
            nickname: null,
            address: null,
            tel: null,
            sex: 0,
        };
        this.passwordInputType = "password";
        this.allCorrect = false;
        this.step = 0;
        this.phoneCorrect = false;
        this.smsCode = null;
        this.disabledAutoComplete = true;

        this._sendSmsCode = this._sendSmsCode.bind(this);
    }

    @action
    setSmsCode = (smsCode) => {
        this.smsCode = smsCode;
        if (smsCode.toString().length === 4) {
            this._validateSmsCode(smsCode);
        }
    };

    /**
     * 请求后台校验短信验证码
     * @param smsCode
     * @private
     */
    _validateSmsCode = (smsCode) => {
        const {tel} = this.user;
        const url = "/apiB/user/smsCode/" + tel + "/" + smsCode;
        let isSuccess = false;

        fetch(url, {
            method: "GET",
            header: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        })
            .then((response) => {
                isSuccess = response.ok;
                return response.json();
            })
            .then((json) => {
                if(isSuccess){
                    if(json.msg == "验证成功"){
                        Toast.success(json.msg, 1);
                        this._setStep(2);
                    }else {
                        const msg = json.msg.indexOf("触发") === -1 ? json.msg : "同一号码每天只能发送五次，请明天再试";
                        Toast.fail(msg, 1);
                    }
                }else {
                    Toast.fail('校验失败', 1);
                }
            })
            .catch((e) => {
                const msg = e.message.indexOf("触发") === -1 ? e.message : "同一号码每天只能发送五次，请明天再试";
                Toast.fail(msg, 1);
            })

    };

    /**
     * 请求后台发送短信验证码
     * @private
     */
    @action
    _sendSmsCode = (step) => {
        const url = "/apiB/user/smsCode";
        const that = this;
        let isSuccess = false;

        const formData = new FormData();
        formData.append("phoneNumber", "" + this.user.tel);

        fetch(url, {
            method: 'POST',
            header: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: formData
        })
            .then((response) => {
                if (response.ok === true) {
                    isSuccess = response.ok;
                }
                return response.json();
            })
            .then((json) => {
                if (isSuccess) {
                    if(json.msg == "发送成功") {
                        Toast.success("已发送 ", 1);
                        that.step = 1;
                    }else {
                        const msg = json.msg.indexOf("触发") === -1 ? json.msg : "每天只能发送五次，请明天再试";
                        Toast.fail(msg, 1.5);
                    }
                } else {
                    const error = new Error(json.status);
                    error.response = json;
                    throw error;
                }
            })
            .catch((e) => {
                Toast.fail(e.response.message)
            })
    };

    @action
    setValue = (e, index) => {
        const value = e.target.value;
        switch (index) {
            case 0:
                this.user.username = value;
                this.user.tel = value;
                if (value !== null && (StringUtil.testTel(value))) {
                    this.phoneCorrect = true;
                } else {
                    this.phoneCorrect = false;
                }
                break;
            case 1:
                this.user.password = value;
                break;
            case 2:
                this.user.nickname = value;
                break;
            case 3:
                this.user.address = value;
                break;
            case 4:
                this.user.sex = value;
                break;
            default:
                break;
        }
        this._validateUser();
    };

    @action
    changePasswordInputType = (forwardType) => {
        if (forwardType === "password") {
            this.passwordInputType = "";
        } else {
            this.passwordInputType = "password";
        }
    };

    @action
    _setStep = (step) => {
        if (step === 1) {
            this.disabledAutoComplete = false;
            if (this.step === 0) {
                this._sendSmsCode(step);
            } else {
                this.step = step;
            }
        } else {
            this.step = step;
        }
    };

    render() {
        const {height, width, col} = this.windowStore;
        const {username, password, nickname, address, sex, tel} = this.user;
        const isXs = col === "XS";
        const smallThenL = col === "M" || col === "L";

        return (
            <div className={"login-body"} style={{minHeight: height}}>
                <Row style={{width: "100%", marginTop: isXs ? 0.05 * height : 0.15 * height}}>
                    <Col
                        xs={{span: 20, offset: 2}}
                        s={{span: 16, offset: 4}}
                        m={{span: 12, offset: 6}}
                        lg={{span: 8, offset: 8}}
                        xl={{span: smallThenL ? 6 : 4, offset: smallThenL ? 9 : 10}}
                    >
                        <div
                            className={"input-card"}
                            style={{
                                boxShadow: isXs ? null : "0px 0px 25px #e1e1e1",
                                padding: isXs ? 0 : "50pt",
                                borderRadius: isXs ? 0 : "10pt"
                            }}
                        >
                            <SwipeableViews
                                disabled={true}
                                enableMouseEvents={false}
                                index={this.step}
                            >
                                <div id={"step-1"} style={{padding: "10px", paddingTop: 0}}>
                                    <Avatar size={64} icon="user" style={{marginTop: isXs ? 0.1 * height : 0}}/>
                                    <div className={"flex-row"} id={"username"} style={{marginTop: 20}}>
                                        <Input
                                            className={"input"}
                                            size={"large"}
                                            value={username}
                                            placeholder={"请输入手机号"}
                                            maxLength={11}
                                            onChange={(e) => this.setValue(e, 0)}
                                            style={{paddingLeft: "10px"}}
                                            prefix={<Typography.Text
                                                style={{color: '#c0c0c0', left: 2}}>+86</Typography.Text>}
                                            suffix={
                                                <Icon
                                                    type={username === null || username.length === 0 ? "info-circle" : "close"}
                                                    style={{color: '#c0c0c0'}}
                                                    onClick={() => this.user.username = null}
                                                />
                                            }
                                        />
                                    </div>
                                    <div style={{padding: "8px", display: 'flex', alignItems: 'flex-start'}}>
                                        <Typography.Text onClick={() => this.props.history.replace("/login")}>
                                            我已注册，使用密码登录
                                        </Typography.Text>
                                    </div>
                                    <Button
                                        block
                                        onClick={() => this._setStep(1)}
                                        disabled={!this.phoneCorrect}
                                        type={"primary"}
                                        size={"large"}
                                        style={{marginTop: 30, borderRadius: 100}}
                                    >
                                        下一步
                                    </Button>
                                </div>
                                <div id={"step-2"} style={{padding: "10%"}}>
                                    <div style={{marginTop: isXs ? 0.1 * height : 0}}>
                                        <Typography.Text>
                                            我们已向 <span style={{fontWeight: 'bold', fontSize: 17}}>{tel}</span>
                                            发送短信验证码请查看短信并输入验证码
                                        </Typography.Text>
                                    </div>
                                    <InputGroup
                                        getValue={this.setSmsCode}
                                        length={4}
                                        style={{width: "50%"}}
                                        type={'line'}
                                        disabled={this.disabledAutoComplete}
                                    />
                                </div>
                                <div id={"step-3"} style={{padding: "10%"}}>
                                    <div className={"flex-row"} style={{marginTop: isXs ? 0.1 * height : 0}}>
                                        <Input
                                            type={this.passwordInputType}
                                            className={"input"}
                                            size={"large"}
                                            value={password}
                                            placeholder={"请输入密码"}
                                            onChange={(e) => this.setValue(e, 1)}
                                            prefix={<Icon type={"lock"} style={{color: '#c0c0c0'}}/>}
                                            suffix={
                                                <Icon
                                                    type={this.passwordInputType === "password" ? "eye" : "eye-invisible"}
                                                    style={{color: '#c0c0c0'}}
                                                    onClick={() => this.changePasswordInputType(this.passwordInputType)}
                                                />
                                            }
                                        />
                                    </div>
                                    <div className={"flex-row"}>
                                        <Input
                                            className={"input"}
                                            size={"large"}
                                            value={nickname}
                                            placeholder={"请输入姓氏或姓名"}
                                            onChange={(e) => this.setValue(e, 2)}
                                            prefix={<Icon type={"user"} style={{color: '#c0c0c0'}}/>}
                                        />
                                    </div>
                                    <div className={"flex-row"}>
                                        <Input
                                            className={"input"}
                                            size={"large"}
                                            value={address}
                                            placeholder={"请输入联系地址(选填)"}
                                            onChange={(e) => this.setValue(e, 3)}
                                            prefix={<Icon type={"home"} style={{color: '#c0c0c0'}}/>}
                                        />
                                    </div>
                                    <div className={"flex-row"}
                                         style={{height: "60px", justifyContent: 'flex-start', alignItems: 'center'}}>

                                        <RadioGroup onChange={(e) => this.setValue(e, 4)} value={sex}>
                                            <Radio value={0}>男 <Icon type="man"/></Radio>
                                            <Radio value={1}>女 <Icon type="woman"/></Radio>
                                        </RadioGroup>
                                    </div>
                                    <Button
                                        block
                                        onClick={() => this._signUp()}
                                        disabled={!this.allCorrect}
                                        type={"primary"}
                                        size={"large"}
                                        style={{marginTop: 30, borderRadius: 100}}
                                    >
                                        注册
                                    </Button>
                                </div>
                            </SwipeableViews>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }

    /**
     * 校验
     * @returns {boolean}
     * @private
     */
    _validateUser = () => {
        let user = true;
        const {username, password, nickname, sex} = this.user;
        const Items = [username, password, nickname];

        if ((Items[0] === null || !(/^1[34578]\d{9}$/.test(Items[0])))) {
            user = false;
        }

        if (Items[1] === null) {
            user = false;
        }
        if ((Items[2] === null || !(/^[\u4E00-\u9FA5]{1,6}$/).test(Items[2]))) {
            user = false;
        }


        this.allCorrect = user;

        return user;
    };

    /**
     * 注册
     * @private
     */
    _signUp = () => {
        let isSuccess = false;
        const url = "/apiB/user/signUp";

        const user = JSON.stringify(this.user);

        const formData = new FormData();

        formData.append("userInfo", user);

        fetch(url, {
            method: 'POST',
            body: formData,
        }).then((response) => {
            if (response.ok === true) {
                isSuccess = response.ok;
            }
            return response.json();
        }).then((json) => {
            if (isSuccess) {
                Toast.success('注册成功', 1);
                this.props.history.replace({
                    pathname: '/login',
                    state: {username: this.user.username, password: this.user.password}
                })
            } else {
                Toast.fail(json.message, 1.5);
            }
        })
            .catch((e) => {
                Toast.fail(e.message, 1.5);
            });
    };
}

const styles = {
    slide: {
        padding: 15,
        minHeight: 100,
        color: '#fff',
    },
    slide1: {
        background: '#FEA900',
    },
    slide2: {
        background: '#B3DC4A',
    },
    slide3: {
        background: '#6AC0FF',
    },
};

export default SignUp;