import React, { Component } from 'react';
import img404 from '../../imgs/404.png';
import {Button, Result} from "antd";

export default class NoMatch extends Component{
    render(){
        return(
            <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={
                    <Button
                        onClick={() => {this.props.history.replace("/")}}
                        type="primary"
                    >
                        Back Home
                    </Button>
                }
            />
        )
    }
}
